import {PayloadAction, createSlice, current} from '@reduxjs/toolkit'
import {
  _getParticipantById,
  _getParticipants,
  _createParticipant,
  _editParticipant,
  _deleteParticipant,
  _transferParticipant,
  _createParticipantLocation,
  _getActualCellDurations,
  _getAllocatedCellOutDuration,
  _getHeartRate,
  _getCellDoorHistory,
} from './participant.actions'

import {addAPICases} from '../api.helpers'
import {
  ApiPaginationRequestState,
  ApiRequestState,
  apiRequestPaginationStateDefault,
  apiRequestStateDefault,
} from '../api.types'
import {
  GetCellHistoryDurationResponse,
  GetParticipantLocationDurationResponse,
  Participant,
  ParticipantHeartrate,
  ParticipantLocation,
} from '../openapi'

type ParticipantState = {
  list: ApiPaginationRequestState<Participant[]>
  heartrateList: ApiPaginationRequestState<ParticipantHeartrate[]>
  locationTimes: ApiRequestState<ParticipantLocation[]>
  selected: ApiRequestState<Participant>
  created: ApiRequestState<Participant>
  updated: ApiRequestState<Participant>
  deleted: ApiRequestState<Participant>
  transferred: ApiRequestState<Participant>
  createLocationTime: ApiRequestState<Participant>
  allocatedCellTime: ApiRequestState<GetCellHistoryDurationResponse>
  cellTimeDurations: ApiRequestState<GetParticipantLocationDurationResponse>

  queryDateTimes: {start?: string | null; end?: string | null}
}

const initialParticipantState: ParticipantState = {
  list: {...apiRequestPaginationStateDefault},
  selected: {...apiRequestStateDefault},
  created: {...apiRequestStateDefault},
  updated: {...apiRequestStateDefault},
  deleted: {...apiRequestStateDefault},
  transferred: {...apiRequestStateDefault},
  heartrateList: {...apiRequestPaginationStateDefault},
  locationTimes: {...apiRequestStateDefault},
  createLocationTime: {...apiRequestStateDefault},
  allocatedCellTime: {...apiRequestStateDefault},
  cellTimeDurations: {...apiRequestStateDefault},
  queryDateTimes: {start: undefined, end: undefined},
}

const participant = createSlice({
  name: 'participant',
  initialState: initialParticipantState,
  reducers: {
    setQueryDateTimes: (
      state: ParticipantState,
      action: PayloadAction<{start?: string | null; end?: string | null}>
    ) => {
      state.queryDateTimes = {start: action.payload.start, end: action.payload.end}
    },
  },
  extraReducers: (builder) => {
    addAPICases(builder, _getParticipants, 'list')
    addAPICases(builder, _getParticipantById, 'selected')
    addAPICases(builder, _getAllocatedCellOutDuration, 'allocatedCellTime')
    addAPICases(builder, _getActualCellDurations, 'cellTimeDurations')
    addAPICases(builder, _getHeartRate, 'heartrateList')
    addAPICases(builder, _getCellDoorHistory, 'locationTimes')
    addAPICases(builder, _createParticipant, 'created', {
      onFulfilled: (state, action) => {
        state.list.data.results = [...current(state.list.data.results || []), action.payload.data]
      },
    })
    addAPICases(builder, _editParticipant, 'updated', {
      onFulfilled: (state, action) => {
        state.list.data.results = current(state.list.data.results || []).map((participant) => {
          if (participant.id === action.payload.data.id)
            return {...participant, ...action.payload.data}
          return participant
        })
      },
    })
    addAPICases(builder, _deleteParticipant, 'deleted', {
      onFulfilled: (state, action) => {
        state.list.data.results = current(state.list.data.results || []).filter(
          (participant) => participant.id !== action.meta.arg
        )
      },
    })
    addAPICases(builder, _createParticipantLocation, 'createLocationTime', {
      onFulfilled: (state, action) => {
        state.locationTimes.data = [...current(state.locationTimes.data || []), action.payload.data]
      },
    })
    addAPICases(builder, _transferParticipant, 'transferred', {
      onFulfilled: (state, action) => {
        state.list.data.results = current(state.list.data.results || []).map((participant) => {
          if (participant.id === action.payload.data.id)
            return {...participant, ...action.payload.data}
          return participant
        })
      },
    })
  },
})

export const participantActions = participant.actions
export const participantReducer = participant.reducer
