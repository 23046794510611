/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertEscalationProtocolCreateBody } from '../models/AlertEscalationProtocolCreateBody';
import type { AlertEscalationProtocolUpdateBody } from '../models/AlertEscalationProtocolUpdateBody';
import type { ApiResponseOfAlertEscalationProtocolCreateResponse } from '../models/ApiResponseOfAlertEscalationProtocolCreateResponse';
import type { ApiResponseOfAlertEscalationProtocolUpdateResponse } from '../models/ApiResponseOfAlertEscalationProtocolUpdateResponse';
import type { ApiResponseOfGetAlertEscalationProtocolResponse } from '../models/ApiResponseOfGetAlertEscalationProtocolResponse';
import type { ApiResponseOfUnknownSchema } from '../models/ApiResponseOfUnknownSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AlertEscalationProtocolService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetAlertEscalationProtocolResponse 
     * @throws ApiError
     */
    public getAlertEscalationProtocols(): CancelablePromise<ApiResponseOfGetAlertEscalationProtocolResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/alert-escalation-protocol/',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfAlertEscalationProtocolCreateResponse 
     * @throws ApiError
     */
    public createAlertEscalationProtocols(
requestBody: AlertEscalationProtocolCreateBody,
): CancelablePromise<ApiResponseOfAlertEscalationProtocolCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/alert-escalation-protocol/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param alertEscalationProtocolId 
     * @param requestBody 
     * @returns ApiResponseOfAlertEscalationProtocolUpdateResponse 
     * @throws ApiError
     */
    public updateAlertEscalationProtocol(
alertEscalationProtocolId: string,
requestBody: AlertEscalationProtocolUpdateBody,
): CancelablePromise<ApiResponseOfAlertEscalationProtocolUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/alert-escalation-protocol/{alertEscalationProtocolId}',
            path: {
                'alertEscalationProtocolId': alertEscalationProtocolId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param alertEscalationProtocolId 
     * @returns ApiResponseOfUnknownSchema 
     * @throws ApiError
     */
    public deleteAlertEscalationProtocol(
alertEscalationProtocolId: string,
): CancelablePromise<ApiResponseOfUnknownSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/alert-escalation-protocol/{alertEscalationProtocolId}',
            path: {
                'alertEscalationProtocolId': alertEscalationProtocolId,
            },
        });
    }

}
