import {useEffect, useRef, useState} from 'react'
import {getContrastColor} from '../../../../helpers/general'
import styles from '../styles.module.scss'
import {Participant} from '../../../../state/V2/openapi'
import {Coordinates, useMap2DState} from './Map2DContext'

const InmateMarker = ({
  participant,
  coordinates,
  displayCoordinates,
  onClick,
  showImei,
  showGhosts,
  svgBackgroundWhite,
}: {
  participant: Participant
  coordinates: Coordinates | null
  displayCoordinates: Coordinates | null
  onClick: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
  showImei: boolean
  showGhosts: boolean
  svgBackgroundWhite: boolean
}) => {
  const [{conversionScale}] = useMap2DState()
  const [participantColor, setParticipantColor] = useState<string>(
    getContrastColor(true) // Initialize with a default color value
  )
  const markerRef = useRef<SVGEllipseElement>(null)
  const textRef = useRef<SVGTextElement>(null)
  const borderRef = useRef<SVGEllipseElement>(null)
  const ghostRef = useRef<SVGEllipseElement>(null)
  const lineRef = useRef<SVGLineElement>(null)
  const linePointRef = useRef<{x: number; y: number}[]>([
    {x: 0, y: 0},
    {x: 0, y: 0},
  ])
  const [{useTransitions}] = useMap2DState()
  const circleRadius = 8
  const textOffset = 20

  const animationDataRef = useRef({
    time: 0,
    startTime: 0,
    startCoordinates: {x: 0, y: 0, z: 0, ...coordinates},
    startDisplayCoordinates: {x: 0, y: 0, z: 0, ...displayCoordinates},
    coordinates: {x: 0, y: 0, z: 0, ...coordinates},
    displayCoordinates: {x: 0, y: 0, z: 0, ...displayCoordinates},
  })

  useEffect(() => {
    // Update participantColor based on svgBackgroundWhite value
    const newColor = svgBackgroundWhite ? getContrastColor(true) : getContrastColor(false)
    setParticipantColor(newColor)
  }, [svgBackgroundWhite]) // Dependency array ensures the effect runs when svgBackgroundWhite changes

  useEffect(() => {
    let animationId: number
    const animationData = animationDataRef.current
    const duration = 500
    animationData.startTime = Date.now()
    animationData.startCoordinates = {...animationData.coordinates}
    animationData.startDisplayCoordinates = {...animationData.displayCoordinates}

    const animate = () => {
      const marker = markerRef.current
      const ghost = ghostRef.current
      const line = lineRef.current
      const text = textRef.current
      const border = borderRef.current
      if (!marker || !ghost || !line || !text || !border) return
      if (!coordinates || !displayCoordinates) return

      let mapCoordinates = {
        x: coordinates.x * conversionScale,
        y: coordinates.y * conversionScale,
      }
      let mapDisplayCoordinates = {
        x: displayCoordinates.x * conversionScale,
        y: displayCoordinates.y * conversionScale,
      }

      if (useTransitions) {
        //update animation data
        animationData.time = (Date.now() - animationData.startTime) / duration
        if (animationData.time >= 1) animationData.time = 1

        animationData.coordinates.x = ease(
          animationData.time,
          animationData.startCoordinates.x,
          coordinates.x || 0
        )
        animationData.coordinates.y = ease(
          animationData.time,
          animationData.startCoordinates.y,
          coordinates.y || 0
        )

        animationData.displayCoordinates.x = ease(
          animationData.time,
          animationData.startDisplayCoordinates.x,
          displayCoordinates.x
        )
        animationData.displayCoordinates.y = ease(
          animationData.time,
          animationData.startDisplayCoordinates.y,
          displayCoordinates.y
        )

        mapCoordinates = {
          x: animationData.coordinates.x * conversionScale,
          y: animationData.coordinates.y * conversionScale,
        }
        mapDisplayCoordinates = {
          x: animationData.displayCoordinates.x * conversionScale,
          y: animationData.displayCoordinates.y * conversionScale,
        }
      }

      //Set marker element's positions to animation's values for transition
      marker.setAttribute('cx', `${mapDisplayCoordinates.x}`)
      marker.setAttribute('cy', `${mapDisplayCoordinates.y}`)
      text.setAttribute('x', `${mapDisplayCoordinates.x}`)
      const textYPosition = mapDisplayCoordinates.y - textOffset
      text.setAttribute('y', `${textYPosition}`)
      border.setAttribute('cx', `${mapDisplayCoordinates.x}`)
      border.setAttribute('cy', `${mapDisplayCoordinates.y}`)
      ghost.setAttribute('cx', `${mapCoordinates.x}`)
      ghost.setAttribute('cy', `${mapCoordinates.y}`)

      //get line point positions based on coordinates and display coordinates
      const dx = mapCoordinates.x - mapDisplayCoordinates.x
      const dy = mapCoordinates.y - mapDisplayCoordinates.y
      const d = Math.sqrt(dx * dx + dy * dy)
      const dirX = dx / d
      const dirY = dy / d
      const r1 = circleRadius // radius of the first circle
      const r2 = circleRadius // radius of the second circle
      const offsetX1 = r1 * dirX
      const offsetY1 = r1 * dirY
      const offsetX2 = r2 * dirX
      const offsetY2 = r2 * dirY

      linePointRef.current = [
        {x: mapDisplayCoordinates.x + offsetX1, y: mapDisplayCoordinates.y + offsetY1},
        {x: mapCoordinates.x - offsetX2, y: mapCoordinates.y - offsetY2},
      ]

      const setAttrIfValid = (element: SVGLineElement, attr: string, value: number) => {
        if (!isNaN(value)) {
          element.setAttribute(attr, value.toString())
        }
      }

      //set line position
      setAttrIfValid(line, 'x1', mapDisplayCoordinates.x + offsetX1)
      setAttrIfValid(line, 'y1', mapDisplayCoordinates.y + offsetY1)
      setAttrIfValid(line, 'x2', mapCoordinates.x - offsetX2)
      setAttrIfValid(line, 'y2', mapCoordinates.y - offsetY2)

      if (useTransitions && animationData.time < 1) animationId = requestAnimationFrame(animate)
    }
    animate()
    return () => cancelAnimationFrame(animationId)
  }, [
    useTransitions,
    coordinates?.x,
    coordinates?.y,
    displayCoordinates?.x,
    displayCoordinates?.y,
    coordinates,
    displayCoordinates,
    circleRadius,
    conversionScale,
  ])

  // Calculate risk circle style
  const calculateRiskStyle = (risk: string) => {
    const riskStyles: Record<string, {color: string; stroke: string}> = {
      low: {color: '#fee12b', stroke: '1,0'},
      medium: {color: '#db7100', stroke: '3,3'},
      high: {color: '#e5484d', stroke: '8,8'},
    }

    return riskStyles[risk] || riskStyles['low']
  }

  const riskStyle = calculateRiskStyle(participant.risk?.toString() || '')
  const startMapDisplayCoordinates = {
    x: animationDataRef.current.displayCoordinates.x * conversionScale,
    y: animationDataRef.current.displayCoordinates.y * conversionScale,
  }
  const startMapCoordinates = {
    x: animationDataRef.current.coordinates.x * conversionScale,
    y: animationDataRef.current.coordinates.y * conversionScale,
  }
  const isDifferentCoordinates =
    displayCoordinates?.x !== coordinates?.x || displayCoordinates?.y !== coordinates?.y

  return (
    <svg key={participant.id}>
      {/** Ghost Marker */}
      <g style={{opacity: !showGhosts || !isDifferentCoordinates ? 0 : 0.6}}>
        <line
          ref={lineRef}
          x1={
            linePointRef.current && linePointRef.current[0] && !isNaN(linePointRef.current[0].x)
              ? linePointRef.current[0].x
              : 0
          }
          y1={
            linePointRef.current && linePointRef.current[0] && !isNaN(linePointRef.current[0].y)
              ? linePointRef.current[0].y
              : 0
          }
          x2={
            linePointRef.current && linePointRef.current[1] && !isNaN(linePointRef.current[1].x)
              ? linePointRef.current[1].x
              : 0
          }
          y2={
            linePointRef.current && linePointRef.current[1] && !isNaN(linePointRef.current[1].y)
              ? linePointRef.current[1].y
              : 0
          }
          style={{
            stroke: participantColor,
            strokeWidth: 1,
          }}
        />
        <ellipse
          ref={ghostRef}
          id={participant.id.toString()}
          style={{
            stroke: participantColor,
            strokeWidth: 1,
          }}
          cx={startMapCoordinates.x}
          cy={startMapCoordinates.y}
          rx={circleRadius}
          ry={circleRadius}
          r={circleRadius}
          fill='transparent'
          onClick={onClick}
        />
      </g>
      {/* Main Marker */}
      <ellipse
        ref={markerRef}
        id={participant.id.toString()}
        onClick={onClick}
        style={{
          pointerEvents: 'all',
          cursor: 'pointer',
          stroke: isDifferentCoordinates && showGhosts ? participantColor : 'transparent',
          strokeWidth: 2,
        }}
        cx={startMapDisplayCoordinates.x}
        cy={startMapDisplayCoordinates.y}
        rx={circleRadius}
        ry={circleRadius}
        r={circleRadius}
        fill='#143AA2'
        key={participant.id}
      />

      {/* Label */}
      {!showImei ? (
        <text
          ref={textRef}
          x={startMapDisplayCoordinates.x}
          y={startMapDisplayCoordinates.y - textOffset}
          textAnchor='middle'
          style={{
            transition: 'x 0.5s, y 0.5s',
            pointerEvents: 'all',
            cursor: 'pointer',
            fill: svgBackgroundWhite ? 'black' : 'white',
          }}
          dominantBaseline={'central'}
          className={styles.ellipseText}
          onClick={onClick}
        >
          {`${participant.firstName.charAt(0).toUpperCase() + '.'} ${participant.lastName}`}
        </text>
      ) : (
        <text
          ref={textRef}
          x={startMapDisplayCoordinates.x}
          y={startMapDisplayCoordinates.y - textOffset}
          textAnchor='middle'
          style={{
            transition: 'x 0.5s, y 0.5s',
            pointerEvents: 'all',
            cursor: 'pointer',
            fill: svgBackgroundWhite ? 'black' : 'white',
          }}
          dominantBaseline={'central'}
          className={styles.ellipseText}
          onClick={onClick}
        >
          {participant.participantDevice?.imei}
        </text>
      )}

      {/* Risk border */}
      <ellipse
        ref={borderRef}
        id={participant.id.toString()}
        cx={startMapDisplayCoordinates.x}
        cy={startMapDisplayCoordinates.y}
        rx={circleRadius}
        ry={circleRadius}
        r={circleRadius}
        fill='transparent'
        stroke={riskStyle.color}
        strokeWidth={2}
        strokeDasharray={riskStyle.stroke}
        style={{
          pointerEvents: 'all',
          cursor: 'pointer',
        }}
        onClick={(e) => onClick(e)}
      />
    </svg>
  )
}

const ease = (time: number, initial: number, final: number) => {
  const progress = time * time * (3.0 - 2.0 * time)
  return initial - progress * (initial - final)
}

export default InmateMarker
