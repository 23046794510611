/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Battery1BarOutlined} from '@mui/icons-material'
import {RootState} from '../../../../state/store'

type Props = {
  className: string
  mHeight: string
}

const Battery: React.FC<Props> = ({className, mHeight}) => {
  const participants = useSelector((state: RootState) => state.participant.list.data.results || [])
  const isLoading = useSelector((state: RootState) => state.participant.list.status === 'pending')

  const getBatteryColor = (battery: number) => {
    if (battery <= 10) {
      return 'red'
    } else return 'yellow'
  }

  return (
    <>
      {isLoading ? (
        <div> Loading... </div>
      ) : (
        <div className={`h-full w-full rounded bg-neutral-900 shadow-lg ${className}`}>
          {/* begin::Header */}
          <div className='px-5 py-7 pb-3'>
            <h3 className='text-base'>LOW BATTERY %</h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='overflow-auto px-5 pb-3'>
            {/* begin::Table container */}
            <div className='w-full' style={{minHeight: mHeight, maxHeight: mHeight}}>
              {/* begin::Table */}
              <table className='min-w-full'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='py-4 pr-3 text-gray-700'>Name</th>
                    <th className='px-3 py-4 text-gray-700'>Location</th>
                    <th className='px-3 py-4 text-gray-700 '>
                      <Battery1BarOutlined style={{color: '#ffffff'}} />
                    </th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {participants.length > 0 &&
                    participants
                      .filter(
                        (participant) =>
                          participant.participantBattery !== null &&
                          participant.participantDevice !== null &&
                          (participant.participantBattery?.batteryLevel || 0) < 20
                      )
                      .map((participant) => {
                        return (
                          <tr
                            className='border-t-1 border-dashed border-indigo-600'
                            key={participant.id}
                          >
                            <td className='py-4 pr-3'>
                              <div className='flex flex-row items-center '>
                                <div className='flex flex-col justify-start'>
                                  <Link
                                    to={`/profile/${participant.id}/overview`}
                                    state={{id: participant.id}}
                                    className='text-break text-sm capitalize text-white'
                                  >
                                    {participant.firstName} {participant.lastName}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className='px-3 py-4'>
                              <div className='flex flex-col w-full me-2'>
                                <div className='d-flex flex-stack'>
                                  <span className='me-2 text-white text-base'>
                                    {participant.participantLocationTime?.location}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className='px-3 py-4'>
                              <span
                                className='text-center text-base'
                                style={{
                                  color: `${getBatteryColor(
                                    participant.participantBattery?.batteryLevel || 0
                                  )}`,
                                }}
                              >
                                {participant.participantBattery?.batteryLevel
                                  ? `${participant.participantBattery?.batteryLevel} %`
                                  : 'N/A'}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  )
}

export default Battery
