/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfWorkersReceiverResponse } from '../models/ApiResponseOfWorkersReceiverResponse';
import type { WorkersReceiverAlertBody } from '../models/WorkersReceiverAlertBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkerReceiverService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfWorkersReceiverResponse 
     * @throws ApiError
     */
    public postWorkerReceiverAlert(
requestBody: WorkersReceiverAlertBody,
): CancelablePromise<ApiResponseOfWorkersReceiverResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/worker-receiver/alert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
