import {useIntl} from 'react-intl'
import {Map2D} from '../../live-track/map-2d/Map2DModule'
import Map2DProvider from '../../live-track/map-2d/Map2DContext'
import {BuildingSelection, FloorSelection} from '../../live-track/map-2d/navbar'

type Props = {
  className: string
}

const Map: React.FC<Props> = ({className}) => {
  const intl = useIntl()

  return (
    <div className={`h-full w-full rounded bg-[#141414] shadow-lg ${className}`}>
      <Map2DProvider>
        <div className='flex flex-col px-7 pt-7 pb-3'>
          <h3 className='text-base uppercase'>{intl.formatMessage({id: 'MENU.MAP'})}</h3>
          <div className='mt-2 flex space-x-2 self-end'>
            <BuildingSelection />
            <FloorSelection />
          </div>
        </div>
        <div className='relative h-80 w-full'>
          <Map2D />
        </div>
      </Map2DProvider>
    </div>
  )
}

export default Map
