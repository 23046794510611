/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetCellOpenTimesResponse } from '../models/ApiResponseOfGetCellOpenTimesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CellOpenTimesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param cellId 
     * @param startDatetime 
     * @param endDatetime 
     * @returns ApiResponseOfGetCellOpenTimesResponse 
     * @throws ApiError
     */
    public getCellOpenTimes(
cellId?: number | null,
startDatetime?: string | null,
endDatetime?: string | null,
): CancelablePromise<ApiResponseOfGetCellOpenTimesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell-open-time/',
            query: {
                'cellId': cellId,
                'startDatetime': startDatetime,
                'endDatetime': endDatetime,
            },
        });
    }

}
