// default configs
export const SHORT_DATETIME = 'MMM DD, HH:mm'
export const LONG_DATETIME = 'MM/dd/yyyy, hh:mm a'
export const DETAILED_LONG_DATETIME = "MMMM d, yyyy 'At' h:mm a"
export const RISK_LEVELS = [
  {label: 'Low', value: 1},
  {label: 'Medium', value: 2},
  {label: 'High', value: 3},
]
export const ALERTS_ACTION_OPTIONS = {
  highHeartRate: [
    'Exercising / Physical activity',
    'Inmate is not having medical emergency ',
    'Sent to Medical Observation',
  ],
  lowHeartRate: ['Inmate is not having medical emergency', 'Medical Emergency'],
  bandTamper: ['False Alert', 'Replace Band - Enter new band IMEI in the notes'],
  lowBattery: ['Replace Band - Enter new band IMEI in the notes', 'Deferred'],
  offline: ['Device has been replaced', 'Technical Assistance is needed', 'Reject Alert'],
  outOfCellTime: ['Checked on Inmate', 'Inmate on Lock-Down', 'Inmate Refused to Leave'],
}
export const UNKNOWN_LOCATION = 'Unknown Location'
export const REPORTING_RECURRENCE = [
  {label: 'daily', value: 0},
  {label: 'weekly', value: 20},
  {label: 'biweekly', value: 40},
  {label: 'monthly', value: 60},
  {label: 'quarterly', value: 80},
  {label: 'yearly', value: 100},
]
