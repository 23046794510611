// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UNKNOWN_LOCATION} from '../../../../helpers/config'
import {RootState} from '../../../../state/store'
import {getRiskString} from '../../../../helpers/api-data'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  mHeight: string
  filter: {
    location?: string
    risk?: number
  }
}

const TotalInmates: React.FC<Props> = ({className, filter, mHeight}) => {
  const participants = useSelector((state: RootState) => state.participant.list.data.results || [])
  const isLoading = useSelector((state: RootState) => state.participant.list.status === 'pending')

  const intl = useIntl()

  const filteredParticipants = useMemo(() => {
    if (!filter) return participants
    let finalFilter = [...participants] || []
    if (filter.risk !== undefined) {
      finalFilter = finalFilter.filter(({risk}) => risk === filter.risk)
    }
    if (filter.location !== undefined) {
      finalFilter = finalFilter.filter((participant) => {
        const locationCheck = participant.participantLocationTime?.location || 'UNKNOWN LOCATION'
        return (
          locationCheck.toLowerCase() === filter.location?.toLowerCase() &&
          participant.participantDevice !== null
        )
      })
    }

    return finalFilter
  }, [participants, filter])

  const tableLabel = useMemo(() => {
    let label = ''
    if (filter.risk !== undefined) {
      label = getRiskString(filter.risk)?.toUpperCase() + ' RISK'
    }
    if (filter.location !== undefined) label = filter.location + label
    return label
  }, [filter])

  return (
    <>
      {isLoading ? (
        <div> Loading... </div>
      ) : (
        <div className={`h-full w-full rounded bg-neutral-900 shadow-lg ${className}`}>
          {/* begin::Header */}
          <div className='px-5 pt-7 pb-3'>
            <h3 className='text-base'>
              <span className='text-base'>
                {' '}
                {tableLabel} {intl.formatMessage({id: 'DASHBOARD.INMATES'})}{' '}
                {`(${filteredParticipants.length})`}
              </span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='overflow-auto px-5 pb-3'>
            {/* begin::Table container */}
            <div className='w-full' style={{minHeight: mHeight, maxHeight: mHeight}}>
              {/* begin::Table */}
              <table className='min-w-full'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='py-4 pr-3 text-gray-700'>Name</th>
                    <th className='px-3 py-4 text-gray-700'>Pod - Cell</th>
                    <th className='px-3  py-4 text-gray-700'>Location</th>
                    <th className='px-3 py-4 text-gray-700'>Risk</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {participants &&
                    participants?.length > 0 &&
                    filteredParticipants.map((participant) => {
                      return (
                        <tr
                          className='border-t-1 border-dashed border-indigo-600'
                          key={participant.id}
                        >
                          <td className='py-4 pr-3'>
                            <div className='flex flex-row items-center '>
                              <div className='flex flex-col justify-start'>
                                <Link
                                  to={`/profile/${participant.id}/overview`}
                                  state={{id: participant.id, cellId: participant.cell?.id}}
                                  className='text-white text-break text-sm capitalize'
                                >
                                  {participant.firstName} {participant.lastName}
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='px-3 py-4'>
                              <div className='me-2 flex w-full flex-col'>
                                <span className='me-2 text-base text-white'>
                                  {participant?.cell?.pod?.podName} -{' '}
                                  {participant?.cell?.cellNumber}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='px-3 py-4'>
                              <div className='me-2 flex w-full flex-col'>
                                <span className='me-2 text-base text-white'>
                                  {participant.participantLocationTime?.location
                                    ? participant.participantLocationTime?.location
                                    : UNKNOWN_LOCATION}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='px-3 py-4'>
                              <div className='me-2 flex w-full flex-col'>
                                <span className='me-2 text-base text-white'>
                                  {getRiskString(participant.risk || 0)}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  )
}

export default TotalInmates
