/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../helpers'

const ForcedLogoutPage = () => {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid pb-lg-20 p-10'>
        {/* begin::Logo */}
        <a href='#' className='mb-15'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-20px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-450px custom-card-body p-lg-10 mx-auto rounded shadow-sm'>
          <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
              {/* begin::Message */}
              <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
                Account Logged In on Another Device.
              </h1>
              <div className='pb-4'>
                Your account has been logged in on a different device. If you did not log in on a
                different device recently, for security reasons please change your password.
              </div>
              {/* end::Message */}
              {/* begin::Link */}
              <Link to='/' className='btn btn-primary'>
                Return To Login
              </Link>
              {/* end::Link */}
            </div>
          </div>{' '}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}
export {ForcedLogoutPage}
