import {createSlice} from '@reduxjs/toolkit'
import {_getBuildingById, _getBuildings, _getFloors, _getPodInfo} from './facilityOps.actions'
import {
  ApiPaginationRequestState,
  ApiRequestState,
  apiRequestPaginationStateDefault,
  apiRequestStateDefault,
} from '../api.types'
import {addAPICases} from '../api.helpers'
import {
  BuildingFloor,
  BuildingPageItem,
  GetBuildingByIdResponse,
  GetPodByIdResponse,
} from '../openapi'

/*--------------------- Facility Ops ------------------------*/

const initialState: {
  buildings: ApiPaginationRequestState<BuildingPageItem[]>
  currentBuilding: ApiRequestState<GetBuildingByIdResponse>
  floors: ApiPaginationRequestState<BuildingFloor[]>
  podInfo: ApiRequestState<GetPodByIdResponse>
} = {
  buildings: {...apiRequestPaginationStateDefault},
  currentBuilding: {...apiRequestStateDefault},
  floors: {...apiRequestPaginationStateDefault},
  podInfo: {...apiRequestStateDefault},
}

export const facilityOps = createSlice({
  name: 'facilityOps',
  initialState,
  extraReducers: (builder) => {
    addAPICases(builder, _getBuildings, 'buildings')
    addAPICases(builder, _getBuildingById, 'currentBuilding')
    addAPICases(builder, _getFloors, 'floors')
    addAPICases(builder, _getPodInfo, 'podInfo')
  },
  reducers: {},
})

export const facilityOpsReducer = facilityOps.reducer
