/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfCellComplianceRuleCreateResponse } from '../models/ApiResponseOfCellComplianceRuleCreateResponse';
import type { ApiResponseOfCellComplianceRuleUpdateResponse } from '../models/ApiResponseOfCellComplianceRuleUpdateResponse';
import type { ApiResponseOfDeleteCellComplianceRuleResponse } from '../models/ApiResponseOfDeleteCellComplianceRuleResponse';
import type { ApiResponseOfGetCellComplianceRuleByIdResponse } from '../models/ApiResponseOfGetCellComplianceRuleByIdResponse';
import type { ApiResponseOfGetCellComplianceRulesResponse } from '../models/ApiResponseOfGetCellComplianceRulesResponse';
import type { CellComplianceRuleCreateBody } from '../models/CellComplianceRuleCreateBody';
import type { CellComplianceRuleUpdateBody } from '../models/CellComplianceRuleUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CellComplianceRulesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetCellComplianceRulesResponse 
     * @throws ApiError
     */
    public getCellComplianceRules(): CancelablePromise<ApiResponseOfGetCellComplianceRulesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell-compliance-rule/',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfCellComplianceRuleCreateResponse 
     * @throws ApiError
     */
    public createCellComplianceRule(
requestBody: CellComplianceRuleCreateBody,
): CancelablePromise<ApiResponseOfCellComplianceRuleCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/cell-compliance-rule/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param cellComplianceRuleId 
     * @returns ApiResponseOfGetCellComplianceRuleByIdResponse 
     * @throws ApiError
     */
    public getCellComplianceRule(
cellComplianceRuleId: string,
): CancelablePromise<ApiResponseOfGetCellComplianceRuleByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell-compliance-rule/{cellComplianceRuleId}',
            path: {
                'cellComplianceRuleId': cellComplianceRuleId,
            },
        });
    }

    /**
     * @param cellComplianceRuleId 
     * @param requestBody 
     * @returns ApiResponseOfCellComplianceRuleUpdateResponse 
     * @throws ApiError
     */
    public updateCellComplianceRule(
cellComplianceRuleId: string,
requestBody: CellComplianceRuleUpdateBody,
): CancelablePromise<ApiResponseOfCellComplianceRuleUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/cell-compliance-rule/{cellComplianceRuleId}',
            path: {
                'cellComplianceRuleId': cellComplianceRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param cellComplianceRuleId 
     * @returns ApiResponseOfDeleteCellComplianceRuleResponse 
     * @throws ApiError
     */
    public deleteCellComplianceRule(
cellComplianceRuleId: string,
): CancelablePromise<ApiResponseOfDeleteCellComplianceRuleResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/cell-compliance-rule/{cellComplianceRuleId}',
            path: {
                'cellComplianceRuleId': cellComplianceRuleId,
            },
        });
    }

}
