import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  _getAlerts,
  _getAlertById,
  _getUnreadCount,
  _updateAlerts,
  _getAlertsByParticipantId,
} from './alerts.actions'
import {addAPICases} from '../api.helpers'
import {
  ApiPaginationRequestState,
  apiRequestPaginationStateDefault,
  ApiRequestState,
  apiRequestStateDefault,
} from '../api.types'
import {Alert, GetPaginatedAlertsResponse} from '../openapi'

type AlertsState = {
  list: ApiPaginationRequestState<GetPaginatedAlertsResponse['results']>
  participant: ApiPaginationRequestState<Alert[]>
  selected: ApiRequestState<Alert>
  updated: ApiRequestState<Alert>
  unread: ApiRequestState<{count: number}>
  unreadSocket: number
}

const initialState: AlertsState = {
  list: {...apiRequestPaginationStateDefault},
  participant: {...apiRequestPaginationStateDefault},
  unread: {...apiRequestStateDefault},
  unreadSocket: 0,
  selected: {...apiRequestStateDefault},
  updated: {...apiRequestStateDefault},
}

const alerts = createSlice({
  name: 'alerts',
  initialState,
  extraReducers: (builder) => {
    addAPICases(builder, _getAlerts, 'list')
    addAPICases(builder, _getAlertsByParticipantId, 'participant')
    addAPICases(builder, _getAlertById, 'selected')
    addAPICases(builder, _getUnreadCount, 'unread')
    addAPICases(builder, _updateAlerts, 'updated', {
      onFulfilled: (state, action) => {
        const alertUpdate = action.payload.data
        if (alertUpdate.action !== null) {
          state.list.data.results =
            state.list.data.results?.filter((alert) => alert.id !== action.meta.arg.id) || null
        } else {
          state.list.data.results =
            state.list.data.results?.map((alert) => {
              return alert.id === action.meta.arg.id ? {...alert, ...alertUpdate} : alert
            }) || null
        }
      },
    })
  },
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.unreadSocket = action.payload
    },
    addAlert: (state, action: PayloadAction<Alert>) => {
      state.list.data.results?.unshift(action.payload)
    },
    updateAlert: (state, action: PayloadAction<Alert>) => {
      state.list.data.results
        ?.filter((a) => a.id === action.payload.id)
        .map((v) => (v = action.payload))
    },
    setUnreadCount: (state, action: PayloadAction<{count: number}>) => {
      state.unread.data = action.payload
    },
  },
})

export const alertReducer = alerts.reducer
export const alertActions = alerts.actions
