import {ReactNode} from 'react'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {Table} from '@tanstack/react-table'

const PaginationFooter = <T,>({
  table,
  totalItems,
  countLabel,
}: {
  table: Table<T>
  totalItems: number | undefined
  countLabel?: string
}) => {
  //creates the pagination page buttons based on the current index and total
  const generatePaginationPageButtons = () => {
    const totalPages = table.getPageCount()
    const {pageIndex} = table.getState().pagination

    //figure out what pages need to be shown as buttons
    const pagesToShow = []
    if (totalPages <= 6) {
      for (let i = 0; i < totalPages; i++) pagesToShow.push(i)
    } else {
      let pageCenter = pageIndex
      if (pageCenter < 3) pageCenter = 3
      else if (pageCenter > totalPages - 3) pageCenter = totalPages - 3
      for (let i = pageCenter - 3; i <= pageCenter + 2; i++) pagesToShow.push(i)
    }

    //if the first page is not in the list add it to the front, same for last page to the end.
    if (!pagesToShow.includes(0)) pagesToShow.unshift(-1)
    if (!pagesToShow.includes(totalPages - 1)) pagesToShow.push(-1)

    //generate the buttons based on the pagesToShow list
    const pageButtons = pagesToShow.reduce<ReactNode[]>((accumulator, currentValue, index) => {
      //add in a ... if the currentValue is -1
      if (currentValue === -1) {
        accumulator.push(
          <span key={`lastPagedEllipsis-${index}`} className='select-none'>
            ...
          </span>
        )
        return accumulator
      }
      const pageLabel = currentValue + 1
      accumulator.push(
        <PaginationButton
          key={pageLabel}
          value={pageLabel}
          active={pageIndex === currentValue}
          onClick={() => table.setPageIndex(currentValue)}
        />
      )
      return accumulator
    }, [])
    return pageButtons
  }

  const totalRows = totalItems ? totalItems : table.getFilteredRowModel().rows.length

  return (
    <div className='flex justify-between p-2 pb-16 text-sm'>
      <div className='flex items-center gap-2'>
        <div className='flex'>
          {[20, 40, 60, 80, 100].map((pageSize) => {
            return (
              <PaginationButton
                key={pageSize}
                active={table.getState().pagination.pageSize === pageSize}
                value={pageSize}
                onClick={() => table.setPageSize(pageSize)}
                disabled={!!(totalRows && totalRows < pageSize - 19)}
              />
            )
          })}
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <span>
          {totalRows} {countLabel || 'Items'}
        </span>

        <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          <FirstPageIcon className={!table.getCanPreviousPage() ? ' text-table-lightgray' : ''} />
        </button>

        <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          <NavigateBeforeIcon
            className={!table.getCanPreviousPage() ? ' text-table-lightgray' : ''}
          />
        </button>

        <div>{generatePaginationPageButtons()}</div>

        <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          <NavigateNextIcon className={!table.getCanNextPage() ? ' text-table-lightgray' : ''} />
        </button>

        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <LastPageIcon className={!table.getCanNextPage() ? ' text-table-lightgray' : ''} />
        </button>
      </div>
    </div>
  )
}

const PaginationButton = ({
  value,
  active,
  disabled,
  onClick,
}: {
  value?: ReactNode
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`px-3 py-2 ${
        active
          ? 'rounded-lg border-1 border-highlight-orange bg-table-gray text-highlight-orange '
          : ''
      }${disabled ? 'text-table-lightgray' : ''}`}
    >
      {value}
    </button>
  )
}
export default PaginationFooter
