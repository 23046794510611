/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfFacilityContactCreateResponse } from '../models/ApiResponseOfFacilityContactCreateResponse';
import type { ApiResponseOfFacilityContactUpdateResponse } from '../models/ApiResponseOfFacilityContactUpdateResponse';
import type { ApiResponseOfGetFacilityContactsResponse } from '../models/ApiResponseOfGetFacilityContactsResponse';
import type { ApiResponseOfUnknownSchema } from '../models/ApiResponseOfUnknownSchema';
import type { FacilityContactCreateBody } from '../models/FacilityContactCreateBody';
import type { FacilityContactUpdateBody } from '../models/FacilityContactUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FacilityContactService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetFacilityContactsResponse 
     * @throws ApiError
     */
    public getFacilityContacts(): CancelablePromise<ApiResponseOfGetFacilityContactsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/facility-contact/',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfFacilityContactCreateResponse 
     * @throws ApiError
     */
    public createFacilityContact(
requestBody: FacilityContactCreateBody,
): CancelablePromise<ApiResponseOfFacilityContactCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/facility-contact/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param facilityContactId 
     * @param requestBody 
     * @returns ApiResponseOfFacilityContactUpdateResponse 
     * @throws ApiError
     */
    public updateFacilityContact(
facilityContactId: string,
requestBody: FacilityContactUpdateBody,
): CancelablePromise<ApiResponseOfFacilityContactUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/facility-contact/{facilityContactId}',
            path: {
                'facilityContactId': facilityContactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param facilityContactId 
     * @returns ApiResponseOfUnknownSchema 
     * @throws ApiError
     */
    public deleteFacilityContact(
facilityContactId: string,
): CancelablePromise<ApiResponseOfUnknownSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/facility-contact/{facilityContactId}',
            path: {
                'facilityContactId': facilityContactId,
            },
        });
    }

}
