/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccessTypeService } from './services/AccessTypeService';
import { AlertEscalationProtocolService } from './services/AlertEscalationProtocolService';
import { AlertHistoryService } from './services/AlertHistoryService';
import { AlertsService } from './services/AlertsService';
import { AuthenticationService } from './services/AuthenticationService';
import { BuildingFloorsService } from './services/BuildingFloorsService';
import { BuildingsService } from './services/BuildingsService';
import { CellComplianceRulesService } from './services/CellComplianceRulesService';
import { CellHistoryService } from './services/CellHistoryService';
import { CellOpenTimesService } from './services/CellOpenTimesService';
import { CellsService } from './services/CellsService';
import { DeviceService } from './services/DeviceService';
import { DeviceHistoryService } from './services/DeviceHistoryService';
import { FacilityService } from './services/FacilityService';
import { FacilityConfigurationService } from './services/FacilityConfigurationService';
import { FacilityContactService } from './services/FacilityContactService';
import { FacilityContactGroupService } from './services/FacilityContactGroupService';
import { FileManagerService } from './services/FileManagerService';
import { HistoricalService } from './services/HistoricalService';
import { InmateAlertService } from './services/InmateAlertService';
import { LocationCoordinatesService } from './services/LocationCoordinatesService';
import { ParticipantService } from './services/ParticipantService';
import { ParticipantAlertService } from './services/ParticipantAlertService';
import { ParticipantLocationService } from './services/ParticipantLocationService';
import { PodsService } from './services/PodsService';
import { RoleTypeService } from './services/RoleTypeService';
import { SupervisorPodsService } from './services/SupervisorPodsService';
import { SupervisorsService } from './services/SupervisorsService';
import { SystemSettingsService } from './services/SystemSettingsService';
import { UserPodsService } from './services/UserPodsService';
import { UserRoleService } from './services/UserRoleService';
import { UsersService } from './services/UsersService';
import { WorkerReceiverService } from './services/WorkerReceiverService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class Api {

    public readonly accessType: AccessTypeService;
    public readonly alertEscalationProtocol: AlertEscalationProtocolService;
    public readonly alertHistory: AlertHistoryService;
    public readonly alerts: AlertsService;
    public readonly authentication: AuthenticationService;
    public readonly buildingFloors: BuildingFloorsService;
    public readonly buildings: BuildingsService;
    public readonly cellComplianceRules: CellComplianceRulesService;
    public readonly cellHistory: CellHistoryService;
    public readonly cellOpenTimes: CellOpenTimesService;
    public readonly cells: CellsService;
    public readonly device: DeviceService;
    public readonly deviceHistory: DeviceHistoryService;
    public readonly facility: FacilityService;
    public readonly facilityConfiguration: FacilityConfigurationService;
    public readonly facilityContact: FacilityContactService;
    public readonly facilityContactGroup: FacilityContactGroupService;
    public readonly fileManager: FileManagerService;
    public readonly historical: HistoricalService;
    public readonly inmateAlert: InmateAlertService;
    public readonly locationCoordinates: LocationCoordinatesService;
    public readonly participant: ParticipantService;
    public readonly participantAlert: ParticipantAlertService;
    public readonly participantLocation: ParticipantLocationService;
    public readonly pods: PodsService;
    public readonly roleType: RoleTypeService;
    public readonly supervisorPods: SupervisorPodsService;
    public readonly supervisors: SupervisorsService;
    public readonly systemSettings: SystemSettingsService;
    public readonly userPods: UserPodsService;
    public readonly userRole: UserRoleService;
    public readonly users: UsersService;
    public readonly workerReceiver: WorkerReceiverService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '2.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accessType = new AccessTypeService(this.request);
        this.alertEscalationProtocol = new AlertEscalationProtocolService(this.request);
        this.alertHistory = new AlertHistoryService(this.request);
        this.alerts = new AlertsService(this.request);
        this.authentication = new AuthenticationService(this.request);
        this.buildingFloors = new BuildingFloorsService(this.request);
        this.buildings = new BuildingsService(this.request);
        this.cellComplianceRules = new CellComplianceRulesService(this.request);
        this.cellHistory = new CellHistoryService(this.request);
        this.cellOpenTimes = new CellOpenTimesService(this.request);
        this.cells = new CellsService(this.request);
        this.device = new DeviceService(this.request);
        this.deviceHistory = new DeviceHistoryService(this.request);
        this.facility = new FacilityService(this.request);
        this.facilityConfiguration = new FacilityConfigurationService(this.request);
        this.facilityContact = new FacilityContactService(this.request);
        this.facilityContactGroup = new FacilityContactGroupService(this.request);
        this.fileManager = new FileManagerService(this.request);
        this.historical = new HistoricalService(this.request);
        this.inmateAlert = new InmateAlertService(this.request);
        this.locationCoordinates = new LocationCoordinatesService(this.request);
        this.participant = new ParticipantService(this.request);
        this.participantAlert = new ParticipantAlertService(this.request);
        this.participantLocation = new ParticipantLocationService(this.request);
        this.pods = new PodsService(this.request);
        this.roleType = new RoleTypeService(this.request);
        this.supervisorPods = new SupervisorPodsService(this.request);
        this.supervisors = new SupervisorsService(this.request);
        this.systemSettings = new SystemSettingsService(this.request);
        this.userPods = new UserPodsService(this.request);
        this.userRole = new UserRoleService(this.request);
        this.users = new UsersService(this.request);
        this.workerReceiver = new WorkerReceiverService(this.request);
    }
}
