import {createColumnHelper} from '@tanstack/react-table'
import {useMemo, useState, ChangeEvent, FormEvent} from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'
import {PageLink, PageTitle} from '../../layout/core'
import AddIcon from '@mui/icons-material/Add'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import {AppDispatch, RootState} from '../../../state/store'
import DataTable from '../../components/datatable/DataTable'
import {_getPodInfo, deleteCell} from '../../../state/V2/facility-ops/facilityOps.actions'
import {
  _getPods,
  createCell,
  updateCell,
  createPod,
} from '../../../state/V2/facility/facility.actions'
import {useParams} from 'react-router-dom'
import {countBy} from 'lodash'
import {useAuth} from '../../modules/auth'
import {toast} from 'react-toastify'
import {ApiError, PodCell} from '../../../state/V2/openapi'
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'
import {useIntl} from 'react-intl'
import ModeEditOutlineOutlined from '@mui/icons-material/ModeEditOutlineOutlined'
import {_getCellComplianceRules} from '../../../state/V2/facility-configuration/facilityConfig.actions'
import {useAppSelector} from '../../../state/hooks'

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const columnHelper = createColumnHelper<PodCell>()

export default function Cells() {
  const dispatch = useDispatch<AppDispatch>()
  const [currentTab, setCurrentTab] = useState(0)
  const {buildingId, floorId} = useParams()
  const [showAddCell, setShowAddCell] = useState(false)
  const [showAddPod, setShowAddPod] = useState(false)
  const writeAccess = useAuth().hasPermission('cell.create', 'cell.update')
  const {data: podInfo} = useSelector((state: RootState) => state.facilityOps.podInfo)
  const pods = useSelector((state: RootState) => state.pods.list.data?.results || [])
  const [breadCrumbs, setBreadCrumbs] = useState<PageLink[]>([])
  const [cellId, setCellId] = useState(-1)
  const [showDeleteReason, setShowDeleteReason] = useState(false)
  const [editData, setEditData] = useState<PodCell>()
  const [podId, setPodId] = useState(0)
  const complianceRules = useAppSelector((state) => state.facilityConfig.complianceRules.data)

  useEffect(() => {})

  useEffect(() => {
    //get data on initial load
    dispatch(
      _getPods({
        page: 1,
        // buildingId: parseInt(buildingId || ''),
        buildingFloorId: parseInt(floorId || ''),
      })
    )
    dispatch(_getCellComplianceRules())
  }, [buildingId, dispatch, floorId])

  useEffect(() => {
    const podId = pods[currentTab ? currentTab : 0]?.id
    if (podId) {
      setPodId(podId)
      dispatch(_getPodInfo(podId))
    }
  }, [currentTab, dispatch, pods])

  useEffect(() => {
    if (podInfo) {
      setBreadCrumbs([
        {
          title: 'Facility Ops',
          path: '/facility-ops',
          isSeparator: false,
          isActive: true,
        },
        {
          title: 'Floors',
          path: `/facility-ops/${podInfo.buildingId}`,
          isSeparator: false,
          isActive: true,
        },
        {
          title: podInfo.podName ?? '',
          path: `/facility-ops/${podInfo.buildingId}/cells/${podInfo.floorId}`,
          isSeparator: false,
          isActive: true,
        },
      ])
    }
  }, [podInfo])

  const handleAddCellClick = () => {
    setShowAddCell(true)
  }

  const handleAddPodClick = () => {
    setShowAddPod(true)
  }

  const handleClose = () => {
    setShowAddCell(false)
    setShowAddPod(false)
    setShowDeleteReason(false)
    setEditData(undefined)
    dispatch(
      _getPods({
        page: 1,
        buildingFloorId: parseInt(floorId || ''),
      })
    )
  }

  const handleDoorToggle = async (doorOpen: boolean, cellId: number) => {
    if (writeAccess) {
      await updateCell(cellId, {doorOpen})
      dispatch(
        _getPods({
          page: 1,
          buildingFloorId: parseInt(floorId || ''),
        })
      )
    }
  }

  //Data table columns
  const columns = useMemo(() => {
    const editFunction = (cell: PodCell) => {
      setEditData({...cell, podId})
      setShowAddCell(true)
    }

    const deleteFunction = (cell: PodCell) => {
      setCellId(cell.id)
      setShowDeleteReason(true)
    }

    const columns = [
      columnHelper.accessor('cellNumber', {
        header: () => 'Name',
        cell: (info) => info.renderValue(),
      }),
      columnHelper.display({
        id: 'type',
        header: () => 'Type',
        cell: () => (
          <Chip
            label='Cell'
            color='info'
            variant='outlined'
            size='small'
            sx={{fontSize: 14, padding: '15px 10px', diplay: 'inline-flex'}}
          />
        ),
      }),
      columnHelper.display({
        id: 'assigned',
        header: () => 'Assigned',
        cell: (info) =>
          info.row.original.participants?.length
            ? info.row.original.participants
                ?.map((participant) => `${participant.firstName} ${participant.lastName}`)
                .join(', ')
            : 'Not Assigned',
      }),
      columnHelper.accessor((pod) => (pod.doorOpen ? 'Open' : 'Closed'), {
        id: 'doorOpen',
        header: () => 'Cell Doors',
        cell: (info) => (
          <FormControlLabel
            control={
              <Switch
                checked={info.row.original.doorOpen}
                onChange={(e) => handleDoorToggle(e.target.checked, info.row.original.id)}
              />
            }
            label={info.row.original.doorOpen ? 'Open' : 'Closed'}
            labelPlacement='start'
          />
        ),
      }),
    ]
    if (writeAccess) {
      columns.push(
        columnHelper.display({
          id: 'edit',
          header: '',
          cell: (info) => (
            <ModeEditOutlineOutlined
              onClick={() => editFunction(info.row.original)}
              className=' cursor-pointer text-highlight-orange'
            />
          ),
          size: 50,
        })
      )
      columns.push(
        columnHelper.display({
          id: 'delete',
          header: '',
          cell: (info) => (
            <DeleteOutlineOutlined
              onClick={() => deleteFunction(info.row.original)}
              className=' cursor-pointer text-highlight-orange'
            />
          ),
          size: 50,
        })
      )
    }
    return columns
  }, [podId])

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const podId = pods[newValue]?.id
    setPodId(podId)
    setCurrentTab(newValue)
    dispatch(_getPodInfo(podId))
  }

  if (!podInfo?.cells)
    return (
      <div className='pt-18 flex h-full flex-col gap-6 p-4 pb-10'>
        <div className='h-28'>
          <Skeleton variant='rounded' height={'100%'} animation='wave' />
        </div>
        <div className='grow'>
          <Skeleton variant='rounded' height={'100%'} animation='wave' />
        </div>
        <div className='h-20'>
          <Skeleton variant='rounded' height={'100%'} animation='wave' />
        </div>
      </div>
    )

  return (
    <div className='h-full w-full'>
      <PageTitle breadcrumbs={breadCrumbs}>Cells</PageTitle>
      <AddCellModal show={showAddCell} handleClose={handleClose} data={editData} />
      <AddPodModal show={showAddPod} handleClose={handleClose} />
      <DeleteReasonModal show={showDeleteReason} cellId={cellId} handleClose={handleClose} />

      <Card sx={{my: 1}} className='flex flex-row'>
        <div className='px-5 py-2'>
          <LightTooltip title='Add Pod'>
            <IconButton color='default' aria-label='add pod' onClick={handleAddPodClick}>
              <AddIcon />
            </IconButton>
          </LightTooltip>
        </div>

        <Tabs value={currentTab} onChange={handleChange} aria-label='Pods List'>
          {pods.map((pod) => {
            return <Tab key={pod.id} label={pod.podName} />
          })}
        </Tabs>
      </Card>

      {/* </div> */}

      <Grid container spacing={1}>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div'>
                {podInfo?.participantsCount}
              </Typography>
              <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                Total Inmates
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div'>
                {podInfo?.cells?.length}
              </Typography>
              <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                Total Cells
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div'>
                {countBy(podInfo?.cells, ({participants}) => participants?.length === 0).true || 0}
              </Typography>
              <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                Empty Cells
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div'>
                {podInfo?.notAcceptedAlertsCount}
              </Typography>
              <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                Unmanaged Alerts
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div'>
                {countBy(podInfo?.cells, ({doorOpen}) => doorOpen === true).true || 0} /{' '}
                {podInfo?.cells?.length}
              </Typography>
              <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                Open Cells
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant='h5' component='div'>
                {podInfo.cellComplianceRuleId
                  ? complianceRules
                      ?.filter((c) => c.id === podInfo.cellComplianceRuleId)
                      .map((v) => {
                        return v.name
                      })
                  : 'Not Assigned'}
              </Typography>
              <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom>
                Cell Compliance Rule
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DataTable<PodCell>
        countLabel='Cells'
        topBarElements={
          <div className='flex cursor-pointer items-center gap-2'>
            {writeAccess && (
              <LightTooltip title='Add Cell'>
                <IconButton color='default' aria-label='add' onClick={handleAddCellClick}>
                  <AddIcon />
                </IconButton>
              </LightTooltip>
            )}
          </div>
        }
        options={{
          data: podInfo?.cells,
          columns,
        }}
      />
    </div>
  )
}

const AddCellModal = ({
  show,
  handleClose,
  data,
}: {
  show: boolean
  handleClose: () => void
  data: PodCell | undefined
}) => {
  const [inputs, setInputs] = useState<{podId: string; cellNumber: string}>({
    podId: '',
    cellNumber: '',
  })
  const [errorText, setErrorText] = useState('')
  const pods = useSelector((state: RootState) => state.pods.list.data?.results || [])

  useEffect(() => {
    setInputs({
      podId: data?.podId ? data.podId.toString() : '',
      cellNumber: data?.cellNumber || '',
    })
  }, [data])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    setErrorText('')
    setInputs((prevState) => ({...prevState, [e.target.name]: e.target.value}))
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setErrorText('')
    if (inputs.podId && inputs.cellNumber) {
      try {
        if (data?.id) {
          const response = await updateCell(data.id, {
            cellNumber: inputs.cellNumber,
          })
          if (response.data) {
            handleClose()
            setInputs({podId: '', cellNumber: ''})
            toast.success('Cell updated Successfully!')
          } else {
            toast.error('Error while updating cell!')
          }
        } else {
          const payload = {...inputs, podId: parseInt(inputs.podId)}
          const response = await createCell(payload)
          if (response.data) {
            handleClose()
            setInputs({podId: '', cellNumber: ''})
            toast.success('Cell Added Successfully!')
          }
        }
      } catch (e) {
        const {body} = e as ApiError
        toast.error(body.error)
      }
    } else {
      setErrorText('Cell number and Pod is required')
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={show}
      onClose={handleClose}
      fullWidth={true}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{data?.id ? 'Edit' : 'Add'} Cell</DialogTitle>
        <DialogContent>
          <FormControl
            sx={{background: '#1f1f1f', minWidth: '100%', marginBottom: '20px', marginTop: '10px'}}
          >
            <InputLabel id='podId'>Pod</InputLabel>
            <Select
              name='podId'
              labelId='podId'
              id='podId'
              value={inputs?.podId}
              label='Cell'
              fullWidth
              required
              variant='outlined'
              onChange={handleChange}
              readOnly={data?.id !== undefined}
            >
              {pods.map((pod: any) => (
                <MenuItem value={pod.id} key={pod.id}>
                  {pod.podName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name='cellNumber'
            margin='dense'
            label='Cell Number'
            type='text'
            fullWidth
            error={errorText ? true : false}
            helperText={errorText}
            variant='outlined'
            value={inputs.cellNumber}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button sx={{marginRight: '20px'}} variant='contained' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const DeleteReasonModal = ({
  show,
  cellId,
  handleClose,
}: {
  show: boolean
  cellId?: number
  handleClose: () => void
}) => {
  const intl = useIntl()

  const [reason, setReason] = useState('')
  const [reasonErrorText, setReasonErrorText] = useState('')

  const cellDeleteApiCall = async () => {
    if (reason && cellId) {
      const response = await deleteCell(cellId)
      if (response.data) {
        setReason('')
        setReasonErrorText('')
        handleClose()
        toast.success(intl.formatMessage({id: 'TOAST.DELETE.CELL'}))
      }
    } else {
      setReasonErrorText(intl.formatMessage({id: 'ERROR.REASON.REQUIRED'}))
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={show}
      onClose={handleClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogContent>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': {m: 1, width: '62.5ch'},
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            fullWidth
            label='Reason'
            variant='outlined'
            required
            value={reason}
            onChange={(e) => {
              setReason(e.target.value)
              setReasonErrorText('')
            }}
            error={reasonErrorText ? true : false}
            helperText={reasonErrorText}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button data-testid='cancel' onClick={handleClose}>
          Cancel
        </Button>
        <Button sx={{marginRight: '20px'}} variant='contained' onClick={cellDeleteApiCall}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddPodModal = ({show, handleClose}: {show: boolean; handleClose: () => void}) => {
  const facilityId = useSelector((state: RootState) => state.facility.current.data?.id)
  const {floorId} = useParams()

  const [inputs, setInputs] = useState<{
    podName: string
    facilityId: number
    floorId: number
    cellComplianceRuleId?: number
  }>({podName: '', facilityId: facilityId!, floorId: parseInt(floorId!)})
  const [errorText, setErrorText] = useState('')
  const complianceRules = useAppSelector((state) => state.facilityConfig.complianceRules.data)

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    setErrorText('')
    setInputs((prevState) => ({...prevState, [e.target.name]: e.target.value}))
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setErrorText('')

    if (inputs.podName) {
      try {
        const response = await createPod(inputs)
        if (response.data) {
          handleClose()
          setInputs({
            podName: '',
            facilityId: facilityId!,
            floorId: parseInt(floorId!),
            cellComplianceRuleId: undefined,
          })
          toast.success('Pod Added Successfully!')
        }
      } catch (e) {
        const {body} = e as ApiError
        toast.error(body.error)
      }
    } else {
      setErrorText('Pod name is required')
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={show}
      onClose={handleClose}
      fullWidth={true}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Pod</DialogTitle>
        {/* TODO: add in cell compliance drop down */}
        <DialogContent>
          <FormControl
            sx={{background: '#1f1f1f', minWidth: '100%', marginBottom: '20px', marginTop: '10px'}}
          >
            <InputLabel id='cell-compliance-select-label'>Cell Compliance Rule</InputLabel>
            <Select
              labelId='cell-compliance-select-label'
              id='cell-compliance-rule'
              name='cellComplianceRuleId'
              fullWidth
              variant='outlined'
              label='Cell Compliance Rule'
              onChange={handleChange}
            >
              {complianceRules &&
                complianceRules.map((v) => <MenuItem value={v.id}>{v.name}</MenuItem>)}
            </Select>
          </FormControl>
          <TextField
            name='podName'
            margin='dense'
            label='Pod Name'
            type='text'
            fullWidth
            error={errorText ? true : false}
            helperText={errorText}
            variant='outlined'
            value={inputs.podName}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button sx={{marginRight: '20px'}} variant='contained' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
