import {useEffect, useRef} from 'react'
import styles from './styles.module.scss'
import TransitionElement from '../../../../components/TransitionElement'
import {useIntl} from 'react-intl'
import {useMap2DState} from '../Map2DContext'


const LiveTrackFooter = () => {
  const intl = useIntl()
  const [{areaOccupancy, selectedInmateId}] = useMap2DState()
  const hasInmateSelected = !!selectedInmateId

  return (
    <div className={styles.footer}>
      <div className={styles.occupancy}>
        {`${intl.formatMessage({
          id: 'LIVETRACK.FOOTER.TOTAL_AREA_OCCUPANCY',
        })}: ${areaOccupancy} ${
          areaOccupancy !== 1
            ? intl.formatMessage({
                id: 'INMATE_PLURAL',
              })
            : intl.formatMessage({
                id: 'INMATE',
              })
        }`}{' '}
      </div>
      <div>
        <TimeDisplay />
      </div>
      <div className={styles.selectInmate}>
        <TransitionElement
          show={!hasInmateSelected}
          removeOnOut={false}
          className={styles.fadeBase}
          transitionIn={styles.fadeIn}
          transitionOut={styles.fadeOut}
        >{`${intl.formatMessage({
          id: 'LIVETRACK.FOOTER.SELECT_INMATE',
        })}`}</TransitionElement>
      </div>
    </div>
  )
}

const TimeDisplay = () => {
  const timeDisplayRef = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  useEffect(() => {
    const setCurrentTime = () => {
      const timeDisplay = timeDisplayRef.current
      if (!timeDisplay) return
      timeDisplay.innerHTML = `${intl.formatMessage({
        id: 'TIME',
      })}: ${new Date().toLocaleString()}`
    }
    const timeUpdateInterval = setInterval(setCurrentTime, 1000)
    setCurrentTime()

    return () => {
      clearInterval(timeUpdateInterval)
    }
  }, [intl])

  return <div className={styles.timeDisplay} ref={timeDisplayRef}></div>
}

export default LiveTrackFooter
