/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfBatteryCreateResponse } from '../models/ApiResponseOfBatteryCreateResponse';
import type { ApiResponseOfCoordinatesCreateResponse } from '../models/ApiResponseOfCoordinatesCreateResponse';
import type { ApiResponseOfHeartrateCreateResponse } from '../models/ApiResponseOfHeartrateCreateResponse';
import type { ApiResponseOfTamperCreateResponse } from '../models/ApiResponseOfTamperCreateResponse';
import type { BatteryCreateBody } from '../models/BatteryCreateBody';
import type { CoordinatesCreateBody } from '../models/CoordinatesCreateBody';
import type { HeartrateCreateBody } from '../models/HeartrateCreateBody';
import type { TamperCreateBody } from '../models/TamperCreateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InmateAlertService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfBatteryCreateResponse 
     * @throws ApiError
     */
    public createParticipantBatteryRecordV1(
requestBody: BatteryCreateBody,
): CancelablePromise<ApiResponseOfBatteryCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/inmate-alerts/battery',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfHeartrateCreateResponse 
     * @throws ApiError
     */
    public createParticipantHeartrateRecordV1(
requestBody: HeartrateCreateBody,
): CancelablePromise<ApiResponseOfHeartrateCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/inmate-alerts/heartrate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfCoordinatesCreateResponse 
     * @throws ApiError
     */
    public createParticipantCoordinatesRecordV1(
requestBody: CoordinatesCreateBody,
): CancelablePromise<ApiResponseOfCoordinatesCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/inmate-alerts/coordinates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfTamperCreateResponse 
     * @throws ApiError
     */
    public createParticipantTamperRecordV1(
requestBody: TamperCreateBody,
): CancelablePromise<ApiResponseOfTamperCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/inmate-alerts/tamper',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
