// actions.ts
import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {DeviceUpdateBody, GetPaginatedDevicesBody} from '../openapi'

/** FULL LIST OF DEVICES **/
export const _getDeviceList = createAsyncThunk('_getDevice', async (data: GetPaginatedDevicesBody) => {
  data = {page: 1, limit: 1000, ...data}
  return api.device.getPaginatedDevicesFiltered(data)
})

/** HISTORY OF A SINGLE DEVICE **/
export const _getDeviceWithHistory = createAsyncThunk(
  '_getDeviceWithHistory',
  async ({id}: {id: string}) => {
    return api.device.getDeviceById(id)
  }
)

/** INMATE'S DEVICE HISTORY **/
export const _getInmateDeviceHistory = createAsyncThunk(
  '_getInmateDeviceHistory',
  async (id: number) => {
    return api.deviceHistory.getPaginatedDeviceHistoryFiltered({participantId: id, limit: 1000})
  }
)

/** ASSIGN / UN-ASSIGN A DEVICE **/
export const _updateDevice = createAsyncThunk(
  'devices/update',
  async ({id, data}: {id: number; data: DeviceUpdateBody}) => {
    return api.device.updateDevice(id.toString(), data)
  }
)

export const deleteDevice = async (reason: string, id: number) => {
  return api.device.deleteDevice(id.toString(), {reason})
}

export const createDevice = async (facilityId: number, imei: string) => {
  return api.device.createDevice({facilityId, imei})
}
