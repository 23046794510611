import {createAsyncThunk} from '@reduxjs/toolkit'
import {
  HISTORICAL_TYPE,
  HistoricalStreamResponse,
  HistoricalStreamState,
  STREAM_STATUS,
} from './historical.types'
import {HistoricalState} from './historical.reducers'
import {getSocket} from '../../../helpers/socket'
import {DateTime} from 'luxon'
import {api} from '../api.helpers'
import {
  GetHistoricalByLocationIdsBody,
  GetHistoricalByParticipantIdsBody,
  GetHistoricalParticipantFollowBody,
} from '../openapi'

/**
 * Triggers the reset of the historical state and set the new requestId, startTime and endTime values
 */
export const _intiStream = createAsyncThunk(
  'historical/init',
  async (data: {requestId: string; startTime: Date | null; endTime: Date | null}, {getState}) => {
    return {
      requestId: data.requestId,
      startTime: data.startTime?.toISOString(),
      endTime: data.endTime?.toISOString(),
    }
  }
)

/**
 * Add stream block data from the incoming socket listener to the state.
 */
export const _addStreamedData = createAsyncThunk(
  'historical/addStreamData',
  async (data: HistoricalStreamResponse, {getState}) => {
    const {type, isEnd, block} = data
    const state = (getState() as any).historical as HistoricalState
    // const subStream = state[type as HistoricalType] as HistoricalStreamState
    const subStreamUpdate = {...state[type], data: [...state[type].data, ...block]}
    const update: Partial<HistoricalState> = {
      [type]: subStreamUpdate,
    }

    if (isEnd) {
      //If isEnd, set the progress to 1
      subStreamUpdate.progress = 1
    } else {
      //If not isEnd, get the progress for the stream based on last timestamp and the total duration

      const lastData = block[block.length - 1]
      if (!lastData.createdAt || !state.endTime || !state.startTime) return update

      const endTime = DateTime.fromISO(state.endTime)
      const startTime = DateTime.fromISO(state.startTime)
      const lastTime = DateTime.fromISO(lastData.createdAt)
      const totalDuration = endTime.diff(startTime)
      const progressDuration = lastTime.diff(startTime)
      const progress = progressDuration.milliseconds / totalDuration.milliseconds
      subStreamUpdate.progress = progress
    }
    //updates the overall progress to whichever individual progress is the smallest
    update.progress = 1
    Object.values(HISTORICAL_TYPE).forEach((subtype) => {
      const checkStream = update[subtype]
        ? update[subtype]
        : (state[subtype] as HistoricalStreamState)
      update.progress =
        (checkStream?.progress || 0) < (update.progress || 0)
          ? checkStream?.progress
          : update.progress
    }, 1)

    if (update.progress === 1) {
      update.status = STREAM_STATUS.COMPLETED
      if (state.requestId) stopSocketListener(state.requestId)
    }
    return update
  }
)

/**
 * Start Historical Data Stream based on participantIds array
 */
export const _getHistoricalByParticipantIds = createAsyncThunk(
  'historical/',
  async (data: GetHistoricalByParticipantIdsBody, {getState}) => {
    const state = (getState() as any).historical as HistoricalState
    return api.historical.getHistoricalByParticipantIds({...data, requestId: state.requestId || ''})
  }
)

/**
 * Start Historical Data Stream based on locationIds array
 */
export const _getHistoricalByLocationIds = createAsyncThunk(
  'historical/location',
  async (data: GetHistoricalByLocationIdsBody, {getState}) => {
    const state = (getState() as any).historical as HistoricalState
    return api.historical.getHistoricalByLocationIds({...data, requestId: state.requestId || ''})
  }
)

/**
 * Start Historical Data Stream based on following a participantId through locations
 */
export const _getHistoricalFollow = createAsyncThunk(
  'historical/follow',
  async (data: GetHistoricalParticipantFollowBody, {getState}) => {
    const state = (getState() as any).historical as HistoricalState
    return api.historical.getHistoricalParticipantFollow({
      ...data,
      requestId: state.requestId || '',
    })
  }
)

/**
 * Cancels the current Historical Stream request
 */
export const _cancelHistorical = createAsyncThunk('historical/cancel', async (_?, store?) => {
  const state = (store?.getState() as any).historical as HistoricalState
  return api.historical.cancelHistorical(state.requestId || '')
})

/**
 * stops the socket listening for updates for the stream by requestId
 */
export const stopSocketListener = (requestId: string) => {
  const socket = getSocket()
  const historicalKey = `historicalStream:${requestId}`
  socket.off(historicalKey)
}
