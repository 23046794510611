/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfFacilityCreateResponse } from '../models/ApiResponseOfFacilityCreateResponse';
import type { ApiResponseOfGetFacilitiesResponse } from '../models/ApiResponseOfGetFacilitiesResponse';
import type { ApiResponseOfGetFacilityResponse } from '../models/ApiResponseOfGetFacilityResponse';
import type { FacilityCreateBody } from '../models/FacilityCreateBody';
import type { FacilityUpdateBody } from '../models/FacilityUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FacilityService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetFacilitiesResponse 
     * @throws ApiError
     */
    public getFacilities(): CancelablePromise<ApiResponseOfGetFacilitiesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/facility/',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfFacilityCreateResponse 
     * @throws ApiError
     */
    public createFacility(
requestBody: FacilityCreateBody,
): CancelablePromise<ApiResponseOfFacilityCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/facility/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ApiResponseOfGetFacilityResponse 
     * @throws ApiError
     */
    public getCurrentFacility(): CancelablePromise<ApiResponseOfGetFacilityResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/facility/current',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetFacilityResponse 
     * @throws ApiError
     */
    public updateCurrentFacility(
requestBody: FacilityUpdateBody,
): CancelablePromise<ApiResponseOfGetFacilityResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/facility/current',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
