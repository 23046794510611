/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {ApiResponseOfDeleteParticipantResponse} from '../models/ApiResponseOfDeleteParticipantResponse'
import type {ApiResponseOfGetPaginatedParticipantsResponse} from '../models/ApiResponseOfGetPaginatedParticipantsResponse'
import type {ApiResponseOfGetParticipantByIdResponse} from '../models/ApiResponseOfGetParticipantByIdResponse'
import type {ApiResponseOfGetParticipantsResponse} from '../models/ApiResponseOfGetParticipantsResponse'
import type {ApiResponseOfParticipantCreateResponse} from '../models/ApiResponseOfParticipantCreateResponse'
import type {ApiResponseOfParticipantUpdateResponse} from '../models/ApiResponseOfParticipantUpdateResponse'
import type {GetPaginatedParticipantsBody} from '../models/GetPaginatedParticipantsBody'
import type {ParticipantCreateBody} from '../models/ParticipantCreateBody'
import type {ParticipantUpdateBody} from '../models/ParticipantUpdateBody'

import type {CancelablePromise} from '../core/CancelablePromise'
import type {BaseHttpRequest} from '../core/BaseHttpRequest'

export class ParticipantService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param facilityId
   * @returns ApiResponseOfGetParticipantsResponse
   * @throws ApiError
   */
  public getParticipants(
    facilityId?: number | null
  ): CancelablePromise<ApiResponseOfGetParticipantsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/participant/',
      query: {
        facilityId: facilityId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ApiResponseOfParticipantCreateResponse
   * @throws ApiError
   */
  public createParticipant(
    requestBody: ParticipantCreateBody
  ): CancelablePromise<ApiResponseOfParticipantCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/participant/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @deprecated
   * Deprecated in favor of getPaginatedParticipantsFiltered
   * @param page
   * @param limit
   * @param facilityId
   * @param sortBy
   * @param sortDir
   * @param filters
   * @returns ApiResponseOfGetPaginatedParticipantsResponse
   * @throws ApiError
   */
  public getPaginatedParticipants(
    page?: number | null,
    limit?: number | null,
    facilityId?: number | null,
    sortBy?:
      | 'active'
      | 'batteryLevel'
      | 'birthDate'
      | 'cellNumber'
      | 'createdByName'
      | 'entryDate'
      | 'exitDate'
      | 'firstName'
      | 'gender'
      | 'heartrate'
      | 'heartrateUpdatedAt'
      | 'height'
      | 'id'
      | 'locationName'
      | 'risk'
      | 'updatedAt'
      | 'updatedByName'
      | 'weight',
    sortDir?: 'asc' | 'desc',
    filters?:
      | {
          operation: 'eq' | 'neq' | 'contains' | 'not-contains' | 'starts-with' | 'ends-with'
          value: string | null
          field:
            | 'birthDate'
            | 'cellNumber'
            | 'createdByName'
            | 'firstName'
            | 'gender'
            | 'locationName'
            | 'updatedByName'
        }
      | {
          operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between'
          value: number | null
          valueTo?: number | null
          field: 'batteryLevel' | 'heartrate' | 'id' | 'risk' | 'weight' | 'deviceId'
        }
      | {
          operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between'
          value: string | null
          valueTo?: string | null
          field: 'entryDate' | 'exitDate' | 'heartrateUpdatedAt' | 'updatedAt'
        }
      | {
          operation: 'eq' | 'neq'
          value: boolean | string
          field: 'active'
        }
  ): CancelablePromise<ApiResponseOfGetPaginatedParticipantsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/participant/pagination',
      query: {
        page: page,
        limit: limit,
        facilityId: facilityId,
        sortBy: sortBy,
        sortDir: sortDir,
        filters: filters,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ApiResponseOfGetPaginatedParticipantsResponse
   * @throws ApiError
   */
  public getPaginatedParticipantsFiltered(
    requestBody: GetPaginatedParticipantsBody
  ): CancelablePromise<ApiResponseOfGetPaginatedParticipantsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/participant/paginated',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param participantId
   * @returns ApiResponseOfGetParticipantByIdResponse
   * @throws ApiError
   */
  public getParticipantById(
    participantId: string
  ): CancelablePromise<ApiResponseOfGetParticipantByIdResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/participant/{participantId}',
      path: {
        participantId: participantId,
      },
    })
  }

  /**
   * @param participantId
   * @returns ApiResponseOfGetParticipantByIdResponse
   * @throws ApiError
   */
  public transferParticipant(
    participantId: string
  ): CancelablePromise<ApiResponseOfGetParticipantByIdResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/participant/{participantId}/transfer/',
      path: {
        participantId: participantId,
      },
    })
  }

  /**
   * @param participantId
   * @param requestBody
   * @returns ApiResponseOfParticipantUpdateResponse
   * @throws ApiError
   */
  public updateParticipant(
    participantId: string,
    requestBody: ParticipantUpdateBody
  ): CancelablePromise<ApiResponseOfParticipantUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v2/participant/{participantId}',
      path: {
        participantId: participantId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param participantId
   * @returns ApiResponseOfDeleteParticipantResponse
   * @throws ApiError
   */
  public deleteParticipant(
    participantId: string
  ): CancelablePromise<ApiResponseOfDeleteParticipantResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/participant/{participantId}',
      path: {
        participantId: participantId,
      },
    })
  }
}
