export const capitalizeFirstLetters = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase())

export const getType = (type?: string | null) => {
  if (type) {
    type = type?.toLowerCase()
    switch (type) {
      case 'low_battery_level':
        return 'Low Battery Alert'
      case 'low_heart_rate':
        return 'Low Heart Rate Alert'
      case 'high_heart_rate':
        return 'High Heart Rate Alert'
      case 'band_tamper':
        return 'Band Tamper Alert'
      case 'heart_rate_offline':
        return 'Heart Rate offline Alert'
      case 'band_offline':
        return 'Band Offline Alert'
      case 'out_of_cell_time_compliancy_warning':
        return 'Out of Cell Time Alert'
      default:
        return type
    }
  }
}
const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getContrastColor = (light: boolean) => {
  let r, g, b

  if (light) {
    // Generate a dark color
    r = getRandomInt(0, 55)
    g = getRandomInt(0, 55)
    b = getRandomInt(0, 55)
  } else {
    // Generate a light color
    r = getRandomInt(170, 255)
    g = getRandomInt(170, 255)
    b = getRandomInt(170, 255)
  }

  const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`
  return hexColor
}

/**
 * Check if the contents of two arrays are the same. does not consider the order of the elements.
 */
export const matchArrays = (arrayA: any[], arrayB: any[]) => {
  if (arrayA.length !== arrayB.length) return false
  if (new Set([...arrayA, ...arrayB]).size !== arrayA.length) return false
  return true
}
