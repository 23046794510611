import {RefObject} from 'react'
import {ReactZoomPanPinchRef} from 'react-zoom-pan-pinch'

export const zoneSwitchSelect = (
  pod: string,
  reactZoomPanPinchRef: RefObject<ReactZoomPanPinchRef> | undefined,
  containerDimensions: {width: number; height: number},
  locationName: string[],
  locationsCamelCase: string[]
) => {
  const baseScreenWidth = 1862

  const currentScreenWidth = containerDimensions.width

  const getNewZoomFactor = (currentZoomFactor: number) => {
    const zoomFactor = currentZoomFactor * (currentScreenWidth / baseScreenWidth)
    return zoomFactor
  }

  const locationToIdMapping = locationName.reduce((map, location, index) => {
    map.set(location, locationsCamelCase[index])
    return map
  }, new Map())
  if (reactZoomPanPinchRef?.current) {
    const {resetTransform, zoomToElement} = reactZoomPanPinchRef.current

    if (pod === 'Reset') {
      resetTransform()
    }
    // If pod exists in the locationToCamelCaseMapping, call zoomToElement
    else if (locationToIdMapping.has(pod)) {
      zoomToElement(locationToIdMapping.get(pod), getNewZoomFactor(3), 300, 'linear')
    }
  }
}

export const getPolygonVisualCenter = (
  polygon: {x: number; y: number}[],
  precision = 1.0,
  debug = false
) => {
  // find the bounding box of the polygon
  let minX, minY, maxX, maxY
  for (let i = 0; i < polygon.length; i++) {
    const point = polygon[i]
    if (!minX || !i || point.x < minX) minX = point.x
    if (!minY || !i || point.y < minY) minY = point.y
    if (!maxX || !i || point.x > maxX) maxX = point.x
    if (!maxY || !i || point.y > maxY) maxY = point.y
  }

  if (!minX) minX = 0
  if (!minY) minY = 0
  if (!maxX) maxX = 0
  if (!maxY) maxY = 0

  const width = maxX - minX
  const height = maxY - minY
  const cellSize = Math.min(width, height)
  let h = cellSize / 2

  if (cellSize === 0) {
    // polygon has no shape
    return {x: minX, y: minY}
  }

  // a priority queue of cells in order of their "potential" (max distance to polygon)
  const cellQueue = []

  // cover polygon with initial cells
  for (let x = minX; x < maxX; x += cellSize) {
    for (let y = minY; y < maxY; y += cellSize) {
      cellQueue.push(createCell(x + h, y + h, h, polygon))
    }
  }
  cellQueue.sort(compareMax)

  // take centroid as the first best guess
  let bestCell = getCentroidCell(polygon)

  // second guess: bounding box centroid
  const bboxCell = createCell(minX + width / 2, minY + height / 2, 0, polygon)
  if (bboxCell.d > bestCell.d) bestCell = bboxCell

  var numProbes = cellQueue.length

  while (cellQueue.length) {
    // pick the most promising cell from the queue
    const cell = cellQueue.pop()
    if (!cell) continue

    // update the best cell if we found a better one
    if (cell.d > bestCell.d) {
      bestCell = cell
      if (debug) {
        console.info('found best %f after %d probes', Math.round(1e4 * cell.d) / 1e4, numProbes)
      }
    }

    // do not drill down further if there's no chance of a better solution
    if (cell.max - bestCell.d <= precision) continue

    // split the cell into four cells
    h = cell.h / 2
    cellQueue.push(createCell(cell.x - h, cell.y - h, h, polygon))
    cellQueue.push(createCell(cell.x + h, cell.y - h, h, polygon))
    cellQueue.push(createCell(cell.x - h, cell.y + h, h, polygon))
    cellQueue.push(createCell(cell.x + h, cell.y + h, h, polygon))
    cellQueue.sort(compareMax)
    numProbes += 4
  }

  if (debug) {
    console.info('num probes: ' + numProbes)
    console.info('best distance: ' + bestCell.d)
  }

  return {x: bestCell.x, y: bestCell.y}
}

const compareMax = (a: {max: number}, b: {max: number}) => {
  return b.max - a.max
}

type Cell = {
  x: number
  y: number
  h: number
  d: number
  max: number
}

const createCell = (x: number, y: number, h: number, polygon: {x: number; y: number}[]): Cell => {
  const d = pointToPolygonDist(x, y, polygon)
  return {
    x, // cell center x
    y, // cell center y
    h, // half the cell size
    d, // distance from cell center to polygon
    max: d + h * Math.SQRT2, // max distance to polygon within a cell
  }
}

// signed distance from point to polygon outline (negative if point is outside)
function pointToPolygonDist(x: number, y: number, polygon: {x: number; y: number}[]) {
  let inside = false
  let minDistSq = Infinity

  for (let i = 0, len = polygon.length, j = len - 1; i < len; j = i++) {
    var a = polygon[i]
    var b = polygon[j]

    // eslint-disable-next-line no-mixed-operators
    if (a.y > y !== b.y > y && x < ((b.x - a.x) * (y - a.y)) / (b.y - a.y) + a.x) inside = !inside

    minDistSq = Math.min(minDistSq, getSegDistSq(x, y, a, b))
  }

  return minDistSq === 0 ? 0 : (inside ? 1 : -1) * Math.sqrt(minDistSq)
}

// get polygon centroid
function getCentroidCell(polygon: {x: number; y: number}[]) {
  var area = 0
  var x = 0
  var y = 0

  for (var i = 0, len = polygon.length, j = len - 1; i < len; j = i++) {
    var a = polygon[i]
    var b = polygon[j]
    var f = a.x * b.y - b.x * a.y
    x += (a.x + b.x) * f
    y += (a.y + b.y) * f
    area += f * 3
  }
  if (area === 0) return createCell(polygon[0].x, polygon[0].y, 0, polygon)
  return createCell(x / area, y / area, 0, polygon)
}

// get squared distance from a point to a segment
function getSegDistSq(
  px: number,
  py: number,
  a: {x: number; y: number},
  b: {x: number; y: number}
) {
  var x = a.x
  var y = a.y
  var dx = b.x - x
  var dy = b.y - y

  if (dx !== 0 || dy !== 0) {
    var t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy)

    if (t > 1) {
      x = b.x
      y = b.y
    } else if (t > 0) {
      x += dx * t
      y += dy * t
    }
  }

  dx = px - x
  dy = py - y

  return dx * dx + dy * dy
}

export const getDistanceBetween = (
  point1: {x: number; y: number},
  point2: {x: number; y: number}
) => {
  const a = point2.x - point1.x
  const b = point2.y - point1.y

  const distance = Math.sqrt(a * a + b * b)
  return distance
}
