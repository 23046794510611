import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {Link} from 'react-router-dom'
import {AsideMenuItem} from './AsideMenuItem'
import {useIntl} from 'react-intl'

const AsideMenuSettings = () => {
  const intl = useIntl()
  return (
    <div className='flex flex-col gap-4 p-4'>
      <Link className='mb-6 text-[#cccccc]' to={'/dashboard'}>
        <div className='flex items-center gap-4'>
          <ChevronLeftIcon /> <span>{intl.formatMessage({id: 'MENU.SETTINGS'})}</span>
        </div>
      </Link>
      <div className='flex flex-col'>
        <div className=' text-sm text-table-lightgray'>
          {intl.formatMessage({id: 'MENU.SETTINGS.FACILITY'})}
        </div>
        <AsideMenuItem
          to={'/settings/facility/general'}
          title={intl.formatMessage({id: 'MENU.SETTINGS.FACILITY.GENERAL'})}
        />
        <AsideMenuItem
          to={'/settings/facility/alerts'}
          title={intl.formatMessage({id: 'MENU.SETTINGS.FACILITY.ALERTS'})}
        />
        <AsideMenuItem
          to={'/settings/facility/cellcompliance'}
          title={intl.formatMessage({id: 'MENU.SETTINGS.FACILITY.OUT_OF_CELL'})}
        />
        <AsideMenuItem
          to={'/settings/facility/contacts'}
          title={intl.formatMessage({id: 'MENU.SETTINGS.FACILITY.CONTACTS'})}
        />
        <AsideMenuItem
          to={'/settings/facility/escalation'}
          title={intl.formatMessage({id: 'MENU.SETTINGS.FACILITY.ESCALATION'})}
        />
      </div>
    </div>
  )
}

export default AsideMenuSettings
