/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {getSocket} from '../../../../helpers/socket'
import {useIntl} from 'react-intl'
import {playAudio} from '../../../components/AudioPlayer'
import {AlertTypes} from '../../../../state/V2/alerts/alerts.types'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../state/store'
import {countBy} from 'lodash'
import {DateTime} from 'luxon'
import {Alert} from '../../../../state/V2/openapi'

const Toolbar1 = () => {
  const {classes} = useLayout()
  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />
          <AlertBar />
        </div>
        <div id='toolbar-right'></div>
      </div>
    </>
  )
}

type AlertQueueItem = {
  id: number
  message: string
  color: string
  audio: string
}

const AlertBar = () => {
  const [alertQueue, setAlertQueue] = useState<AlertQueueItem[]>([])
  const [alert, setAlert] = useState<AlertQueueItem>()
  const socket = getSocket()
  const intl = useIntl()
  const dbAlerts = useSelector((state: RootState) => state.alerts.list.data.results)
  const lastUnhandledAlertTime = useRef<DateTime>()
  const unhandledAlertTimeout = useRef<NodeJS.Timeout>()
  const removeAlertTimeout = useRef<NodeJS.Timeout>()
  const alertsRef = useRef(dbAlerts)

  alertsRef.current = dbAlerts

  const triggerUnhandledAlerts = useCallback(() => {
    //clear any current timeouts for adding unhandled alerts
    if (unhandledAlertTimeout.current) clearTimeout(unhandledAlertTimeout.current)

    //Check if there are any unaccepted alerts in the alerts array, if not clear the queue of unhandled alerts
    const unacceptedCount = countBy(alertsRef.current, (alert) => !alert.accepted).true || 0
    if (unacceptedCount <= 0) {
      setAlertQueue((prev) => {
        return prev.filter((alert) => alert.id !== -1)
      })
      return
    }

    // function to add an unhandledAlert to the end of the queue (so it fires after any active alerts)
    const addUnhandledAlert = () => {
      setAlertQueue((prev) => {
        if (prev.find((alert) => alert.id === -1)) return prev
        const alertData = {
          id: -1,
          message: `${intl.formatMessage({
            id: `ALERT.UNHANDLED`,
          })} `,
          color: '#d87a16',
          audio: '/media/audio/itw-heartrate-alert-audio.aac',
        }
        prev.unshift(alertData)
        return [...prev]
      })
    }

    // check if it has been 10 seconds since last unhandled alert, otherwise wait
    const timeDelay = 4000
    const timeSinceLastUnhandledAlert = lastUnhandledAlertTime.current
      ? DateTime.now().diff(lastUnhandledAlertTime.current).toMillis()
      : timeDelay
    if (timeSinceLastUnhandledAlert >= timeDelay) {
      addUnhandledAlert()
    } else {
      unhandledAlertTimeout.current = setTimeout(
        addUnhandledAlert,
        timeDelay - timeSinceLastUnhandledAlert
      )
    }
  }, [intl])

  // triggers unhandled alert when alerts list is updated and unaccepted alerts exist
  useEffect(() => {
    triggerUnhandledAlerts()
  }, [intl, dbAlerts, triggerUnhandledAlerts])

  //Triggers the latest alert in the queue
  useEffect(() => {
    const newAlert = alertQueue[alertQueue.length - 1]
    if (alert?.id !== newAlert?.id) setAlert(newAlert)
    else return
    if (!newAlert) return

    //play alert audio
    if (newAlert.audio) playAudio(newAlert.audio)

    //remove alert from queue when time up
    const removeAlert = () => {
      if (newAlert.id === -1) {
        lastUnhandledAlertTime.current = DateTime.now()
        triggerUnhandledAlerts()
      }
      setAlertQueue((prev) => prev.filter(({id}) => id !== newAlert.id))
    }
    removeAlertTimeout.current = setTimeout(removeAlert, 6000)
  }, [alertQueue, alert?.id, triggerUnhandledAlerts])

  // gets socket alert notifications and adds the alert to the queue
  useEffect(() => {
    socket.on('notify', ({data: alert}: {data: Alert}) => {
      const alertData = {
        id: alert.id,
        message: `${intl.formatMessage({
          id: `ALERT.${alert.type}`,
        })}: `,
        color: '#1d1d1dff',
        audio: '/media/audio/itw-heartrate-alert-audio.aac',
      }

      switch (alert.type) {
        case AlertTypes.HIGH_HEART_RATE:
        case AlertTypes.LOW_HEART_RATE:
          alertData.color = '#2e2e6bff'
          alertData.message += `${alert.heartrate} BPM - ${alert.participant?.firstName} ${alert.participant?.lastName}`
          break

        case AlertTypes.HEART_RATE_OFFLINE:
          alertData.color = '#2e2e6bff'
          alertData.message += ` ${alert.participant?.firstName} ${alert.participant?.lastName}`
          break

        case AlertTypes.LOW_BATTERY_LEVEL:
          alertData.color = '#186358ff'
          alertData.message += `${alert.batteryLevel}% - ${alert.participant?.firstName} ${alert.participant?.lastName}`
          break

        case AlertTypes.BAND_OFFLINE:
          alertData.color = '#6d2028ff'
          alertData.message += ` ${alert.participant?.firstName} ${alert.participant?.lastName}`
          break

        case AlertTypes.OUT_OF_CELL_TIME_COMPLIANCY_WARNING:
          alertData.color = '#74467fff'
          alertData.message += ` ${alert.participant?.firstName} ${alert.participant?.lastName}`
          break

        case AlertTypes.BAND_TAMPER:
          alertData.color = '#93532cff'
          alertData.message += ` ${alert.participant?.firstName} ${alert.participant?.lastName}`
          break
        default:
          return //Return out and do nothing if it is not an alert we are handling
      }
      setAlertQueue((prev) => {
        prev.push(alertData)
        return [...prev]
      })
    })
  }, [intl, socket])

  if (!alert) return null

  return (
    <div
      key={alert.id}
      className='relative ml-2 flex grow animate-alert-fade items-center justify-center overflow-hidden rounded-md p-1'
    >
      <div
        className='absolute bottom-0 left-0 right-0 top-0 -z-10 animate-alert-pulse'
        style={{backgroundColor: alert.color}}
      ></div>
      <span className='text-base font-extrabold'>{alert.message}</span>
    </div>
  )
}

export {Toolbar1}
