import {createSlice} from '@reduxjs/toolkit'
import {_getRoles, _login} from './authentication.actions'
import {ApiRequestState, apiRequestStateDefault} from '../api.types'
import {addAPICases} from '../api.helpers'
import {UserClaims} from '../../../app/modules/auth'
import {UserRole} from '../openapi'

export type AuthenticationState = {
  login: ApiRequestState<UserClaims>
  roles: ApiRequestState<UserRole[]>
}

const initialState: AuthenticationState = {
  login: {...apiRequestStateDefault},
  roles: {...apiRequestStateDefault},
}

const authentication = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addAPICases(builder, _login, 'login')
    addAPICases(builder, _getRoles, 'roles')
  },
})

export const authenticationReducer = authentication.reducer
