import {DateTime} from 'luxon'
import {ApiResponse} from '../api.types'

export type HistoricalData = {
  id: number
  participantId: number | null
  deviceId?: number | null
  coordinates?: string | null
  batteryLevel?: number | null
  heartrate?: number | null
  confidenceLevel?: number | null
  createdAt: string | null
  type?: string
}

export const HISTORICAL_TYPE = Object.freeze({
  COORDINATES: 'coordinates',
  HEARTRATE: 'heartrate',
  BATTERY: 'battery',
  ALERT: 'alert',
  // TAMPER: 'tamper',
})
export type HistoricalType = (typeof HISTORICAL_TYPE)[keyof typeof HISTORICAL_TYPE]

export const HISTORICAL_REQUEST_TYPE = Object.freeze({
  PARTICIPANT_IDS: 'participantIds',
  LOCATION_IDS: 'locationIds',
  FOLLOW: 'follow',
})
export type HistoricalRequestType =
  (typeof HISTORICAL_REQUEST_TYPE)[keyof typeof HISTORICAL_REQUEST_TYPE]

export const STREAM_STATUS = Object.freeze({
  READY: 'ready',
  PENDING: 'pending',
  STREAMING: 'streaming',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
})
export type StreamStatus = (typeof STREAM_STATUS)[keyof typeof STREAM_STATUS]

export type HistoricalOptions = {
  requestType: HistoricalRequestType | null
  startTime: DateTime
  endTime: DateTime
  participantIds?: number[]
  participantId?: number
  locationIds?: number[]
}


export type HistoricalResponse = ApiResponse<{message: string}>

export type HistoricalStreamResponse = {
  type: HistoricalType
  isEnd: boolean
  block: HistoricalData[]
}

export type HistoricalStreamState = {
  progress: number
  data: HistoricalData[]
}
