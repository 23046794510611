import Box from '@mui/material/Box'
import {createColumnHelper} from '@tanstack/react-table'
import {ChangeEvent, FormEvent, useMemo, useRef, useState, useCallback} from 'react'
import {IconButton, Typography} from '@mui/material'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../layout/core'
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'
import {styled} from '@mui/material/styles'
import {Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import {toast} from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import {AppDispatch, RootState} from '../../../state/store'
import {Link, useNavigate, useParams} from 'react-router-dom'
import DataTable from '../../components/datatable/DataTable'
import {dataTableStateToPaginationRequest} from '../../../state/V2/api.helpers'
import {DataTableState} from '../../components/datatable/DataTable.types'
import {useAuth} from '../../modules/auth'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {alertsFilterTypes} from '../../../state/V2/alerts/alerts.types'
import {
  _getBuildingById,
  _getFloors,
  createFloor,
  deleteFloor,
  updateFloor,
} from '../../../state/V2/facility-ops/facilityOps.actions'
import {createPod} from '../../../state/V2/facility/facility.actions'
import {ApiError, ApiUnknownErrorResponse, BuildingFloorPageItem} from '../../../state/V2/openapi'
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlined from '@mui/icons-material/ModeEditOutlineOutlined'
import {useAppSelector} from '../../../state/hooks'

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const columnHelper = createColumnHelper<BuildingFloorPageItem>()

export default function Floors() {
  const navigate = useNavigate()
  const {buildingId} = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [showDeleteReason, setShowDeleteReason] = useState(false)
  const [showAddFloor, setShowAddFloor] = useState(false)
  const [showAddPod, setShowAddPod] = useState(false)
  const [floorId, setFloorId] = useState<number>()
  const writeAccess = useAuth().hasPermission('building-floor.create', 'building-floor.update')
  const [breadCrumbs, setBreadCrumbs] = useState<PageLink[]>([])
  const [editData, setEditData] = useState<BuildingFloorPageItem>()

  /**
   * API Pagination Logic
   **/
  const building = useAppSelector((state) => state.facilityOps.currentBuilding.data)
  const floors = building?.buildingFloors

  // const floors = useAppSelector((state) => state.facilityOps.floors.data.results)
  const dataTableStateRef = useRef<DataTableState>({
    pagination: {pageIndex: 0, pageSize: 20},
    sorting: [],
    columnFilters: [],
  })

  useEffect(() => {
    //get data on initial load
    if (buildingId) dispatch(_getBuildingById(parseInt(buildingId)))
  }, [dispatch, buildingId])

  useEffect(() => {
    setBreadCrumbs([
      {
        title: building?.name || '',
        path: '/facility-ops',
        isSeparator: false,
        isActive: true,
      },
    ])
  }, [building?.name])

  // Update table data when the state changes (pagination, sorting or filtering)
  const onDataTableStateChange = (dataTableState: DataTableState) => {
    dataTableStateRef.current = dataTableState
    if (buildingId) {
      dispatch(
        _getFloors({
          ...dataTableStateToPaginationRequest(dataTableState, alertsFilterTypes),
          buildingId: parseInt(buildingId),
        })
      )
    }
  }

  // Trigger refresh of the table data via database query
  const refreshDataTable = useCallback(() => {
    if (!dataTableStateRef.current) return
    if (buildingId) {
      dispatch(
        _getFloors({
          ...dataTableStateToPaginationRequest(dataTableStateRef.current, alertsFilterTypes),
          buildingId: parseInt(buildingId),
        })
      )
    }
  }, [dispatch, buildingId])

  //Data table columns
  const columns = useMemo(() => {
    const editFunction = (floor: BuildingFloorPageItem) => {
      setEditData(floor)
      setShowAddFloor(true)
    }

    const deleteFunction = (floor: BuildingFloorPageItem) => {
      setFloorId(floor.id)
      setShowDeleteReason(true)
    }

    const columns = [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (info) =>
          info.row.original.podsCount <= 0 ? (
            <div
              className='cursor-pointer font-bold text-highlight-orange'
              onClick={() => {
                setShowAddPod(true)
                setFloorId(info.row.original.id)
              }}
            >
              {' '}
              {info.getValue()}
            </div>
          ) : (
            <Link
              className='cursor-pointer font-bold text-highlight-orange'
              to={`/facility-ops/${buildingId}/cells/${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          ),
      }),
      columnHelper.accessor('podsCount', {
        header: () => 'Pods',
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('cellsCount', {
        header: () => 'Cells',
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('commonAreasCount', {
        header: () => 'Common Areas',
        cell: (info) => info.renderValue(),
      }),
    ]
    if (writeAccess) {
      columns.push(
        columnHelper.display({
          id: 'edit',
          header: '',
          cell: (info) => (
            <ModeEditOutlineOutlined
              onClick={() => editFunction(info.row.original)}
              className=' cursor-pointer text-highlight-orange'
            />
          ),
          size: 50,
        })
      )
      columns.push(
        columnHelper.display({
          id: 'delete',
          header: '',
          cell: (info) => (
            <DeleteOutlineOutlined
              onClick={() => deleteFunction(info.row.original)}
              className=' cursor-pointer text-highlight-orange'
            />
          ),
          size: 50,
        })
      )
    }
    return columns
  }, [buildingId, writeAccess])

  const onExporting = () => {
    const dataToExport = floors

    if (dataToExport) {
      const csvData = Papa.unparse({
        fields: ['Name', 'Pods', 'Cells', 'Common Areas'],
        data: dataToExport.map((floor) => [
          floor.name,
          floor.podsCount,
          floor.cellsCount,
          floor.commonAreasCount,
        ]),
      })

      const csvBlob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'})
      saveAs(csvBlob, 'Floors.csv')
    } else {
      console.error('Data to export is null.')
    }
  }

  const handleClose = () => {
    setShowAddFloor(false)
    setShowDeleteReason(false)
    setShowAddPod(false)
    setEditData(undefined)
    refreshDataTable()
    navigate(`/facility-ops/${buildingId}`)
  }

  const handleAddFloorClick = () => {
    setShowAddFloor(true)
  }

  if (!floors)
    return (
      <div className='pt-18 flex h-full flex-col gap-6 p-4 pb-10'>
        <div className='h-28'>
          <Skeleton variant='rounded' height={'100%'} animation='wave' />
        </div>
        <div className='grow'>
          <Skeleton variant='rounded' height={'100%'} animation='wave' />
        </div>
        <div className='h-20'>
          <Skeleton variant='rounded' height={'100%'} animation='wave' />
        </div>
      </div>
    )

  return (
    <div className='h-full w-full'>
      <PageTitle breadcrumbs={breadCrumbs}>Floors</PageTitle>
      <AddFloorModal show={showAddFloor} handleClose={handleClose} data={editData} />
      <AddPodModal show={showAddPod} floorId={floorId} handleClose={handleClose} />
      <DeleteReasonModal show={showDeleteReason} floorId={floorId} handleClose={handleClose} />

      <DataTable<BuildingFloorPageItem>
        countLabel='Floors'
        topBarElements={
          <div className='flex cursor-pointer items-center gap-2'>
            {writeAccess && (
              <LightTooltip title='Add'>
                <IconButton color='default' aria-label='add' onClick={handleAddFloorClick}>
                  <AddIcon />
                </IconButton>
              </LightTooltip>
            )}
            {/* <LightTooltip title='Export to CSV' placement='bottom-end'>
              <IconButton onClick={onExporting}>
                <DownloadIcon />
              </IconButton>
            </LightTooltip> */}
          </div>
        }
        options={{
          data: floors,
          columns,
        }}
        // apiPagination={pagination}
        onStateChange={onDataTableStateChange}
      />
    </div>
  )
}

const AddFloorModal = ({
  show,
  handleClose,
  data,
}: {
  show: boolean
  handleClose: () => void
  data: BuildingFloorPageItem | undefined
}) => {
  const [inputs, setInputs] = useState<{name?: string; label?: string}>({
    name: '',
    label: '',
  })
  const [errorText, setErrorText] = useState('')
  const params = useParams()
  const {buildingId} = params

  useEffect(() => {
    setInputs({
      name: data?.name,
      label: data?.label,
    })
  }, [data])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setErrorText('')
    setInputs((prevState) => ({...prevState, [e.target.name]: e.target.value}))
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setErrorText('')

    try {
      if (data?.id) {
        if (inputs.name) {
          const response = await updateFloor({
            id: data.id,
            name: inputs.name,
          })
          if (response.data) {
            handleClose()
            setInputs({name: '', label: ''})
            toast.success('Floor updated successfully!')
          } else {
            toast.error('Error while updating floor!')
          }
        } else {
          setErrorText('Floor name is required')
        }
      } else {
        if (inputs.name && inputs.label) {
          const response = await createFloor(parseInt(buildingId || ''), inputs.name, inputs.label)
          if (response.data) {
            handleClose()
            setInputs({name: '', label: ''})
            toast.success('Floor Added Successfully!')
          } else {
            toast.error('Floor already exists!')
          }
        } else {
          setErrorText('Floor name and label is required')
        }
      }
    } catch (e) {
      alert(e)
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={show}
      onClose={handleClose}
      fullWidth={true}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{data?.id ? 'Edit' : 'Add'} Floor</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            name='name'
            margin='dense'
            label='Name'
            type='text'
            fullWidth
            error={errorText ? true : false}
            helperText={errorText}
            variant='outlined'
            value={inputs.name}
            onChange={handleChange}
          />
          {data?.id ? null : (
            <TextField
              name='label'
              margin='dense'
              label='Label'
              type='text'
              fullWidth
              error={errorText ? true : false}
              helperText={errorText}
              variant='outlined'
              value={inputs.label}
              onChange={handleChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button sx={{marginRight: '20px'}} variant='contained' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const DeleteReasonModal = ({
  show,
  floorId,
  handleClose,
}: {
  show: boolean
  floorId?: number
  handleClose: () => void
}) => {
  const intl = useIntl()

  const [reason, setReason] = useState('')
  const [reasonErrorText, setReasonErrorText] = useState('')

  const floorDeleteApiCall = async () => {
    try {
      if (reason && floorId) {
        const response = await deleteFloor(floorId)

        if (response.data) {
          handleClose()
          setReason('')
          setReasonErrorText('')
          toast.success(intl.formatMessage({id: 'TOAST.DELETE.FLOOR'}))
        }
      } else {
        setReasonErrorText(intl.formatMessage({id: 'ERROR.REASON.REQUIRED'}))
      }
    } catch (e) {
      const errorMessage = (e as {body?: ApiUnknownErrorResponse}).body?.error
      if (errorMessage) setReasonErrorText(errorMessage)
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={show}
      onClose={handleClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogContent>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': {m: 1, width: '62.5ch'},
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            fullWidth
            label='Reason'
            variant='outlined'
            required
            value={reason}
            onChange={(e) => {
              setReason(e.target.value)
              setReasonErrorText('')
            }}
            error={reasonErrorText ? true : false}
            helperText={reasonErrorText}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button data-testid='cancel' onClick={handleClose}>
          Cancel
        </Button>
        <Button sx={{marginRight: '20px'}} variant='contained' onClick={floorDeleteApiCall}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddPodModal = ({
  show,
  floorId,
  handleClose,
}: {
  show: boolean
  floorId?: number
  handleClose: () => void
}) => {
  const [isAssign, setIsAssign] = useState(false)
  const facilityId = useSelector((state: RootState) => state.facility.current.data?.id)
  const [podName, setPodName] = useState('')

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      const response = await createPod({podName, facilityId: facilityId!, floorId: floorId!})
      if (response.data) {
        handleClose()
        setPodName('')
        setIsAssign(false)
        toast.success('Pod Added Successfully!')
      }
    } catch (e) {
      const {body} = e as ApiError
      toast.error(body.error)
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={show}
      onClose={() => {
        handleClose()
        setIsAssign(false)
        setPodName('')
      }}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle>{isAssign ? 'Add Pod' : 'Please Confirm'}</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': {width: '62.5ch'},
          }}
          noValidate
          autoComplete='off'
        >
          {isAssign ? (
            <TextField
              name='podName'
              margin='dense'
              label='Pod Name'
              type='text'
              fullWidth
              variant='outlined'
              value={podName}
              onChange={(e) => setPodName(e.target.value)}
            />
          ) : (
            <Typography sx={{fontSize: 13}} gutterBottom>
              This floor doesn't have pods assigned yet. Do you want to assign a pod?{' '}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
            setIsAssign(false)
            setPodName('')
          }}
        >
          {isAssign ? 'Cancel' : 'No'}
        </Button>
        <Button
          sx={{marginRight: '16px'}}
          variant='contained'
          disabled={isAssign && !podName}
          onClick={(e) => (!isAssign ? setIsAssign(true) : handleSubmit(e))}
        >
          {isAssign ? 'Save' : 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
