import {useEffect, useState} from 'react'
import styles from './styles.module.scss'
import {createPortal} from 'react-dom'
import PodAlerts from './pod-alerts/PodAlerts'
import InmateDetails from './inmate-details/InmateDetails'
import TransitionElement from '../../../../components/TransitionElement'
import {useMap2DState} from '../Map2DContext'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../state/store'

export default function Sidebar() {
  const [{selectedInmateId}, updateMap2DState] = useMap2DState()
  const [showAlerts, setShowAlerts] = useState(false)
  const [showInmateDetails, setShowInmateDetails] = useState(false)
  const podAssignment = useSelector((state: RootState) => state.users.podAssignment.data)

  const toolbarRight = document.querySelector('#toolbar-right')
  const showSidebar = showAlerts || showInmateDetails
  const bothOpen = showAlerts && showInmateDetails

  useEffect(() => {
    updateMap2DState({selectedInmateId: null})
    setShowInmateDetails(false)
    setShowAlerts(false)
  }, [podAssignment, updateMap2DState])

  useEffect(() => {
    if (selectedInmateId) setShowInmateDetails(true)
  }, [selectedInmateId])

  useEffect(() => {
    if (showInmateDetails) return
    const clearInmate = setTimeout(() => {
      updateMap2DState({selectedInmateId: null})
    }, 300)
    return () => {
      clearTimeout(clearInmate)
    }
  }, [showInmateDetails, updateMap2DState])

  return (
    <>
      <TransitionElement
        className={styles.sidebarBase}
        show={showSidebar}
        transitionIn={styles.sidebarIn}
        transitionOut={styles.sidebarOut}
      >
        <div className={styles.sidebar}>
          <TransitionElement
            className={styles.alertsBase}
            style={{position: 'relative', overflow: 'auto', maxHeight: '50vh'}}
            show={showAlerts}
            transitionIn={styles.alertsPartialIn}
            transitionOut={styles.alertsOut}
          >
            <PodAlerts onSelect={(value) => updateMap2DState({selectedInmateId: value})} />
          </TransitionElement>
          <TransitionElement
            style={{overflow: 'auto', maxHeight: '100vh'}}
            className={styles.inmateDetailsBase}
            show={showInmateDetails}
            transitionIn={styles.inmateDetailsIn}
            transitionOut={styles.inmateDetailsOut}
          >
            <InmateDetails
              bothOpen={bothOpen}
              selectedInmateId={selectedInmateId || 0}
              setSelectedInmateId={(id: number | undefined) => {
                if (id !== undefined) {
                  updateMap2DState({
                    selectedInmateId: id,
                  })
                  return
                }
                setShowInmateDetails(false)
              }}
            />
          </TransitionElement>
        </div>
      </TransitionElement>
      <TransitionElement
        className={styles.sidebarfillBase}
        show={showSidebar}
        transitionIn={styles.sidebarfillIn}
        transitionOut={styles.sidebarfillOut}
      >
        <div className={styles.sidebarfill} />
      </TransitionElement>

      {toolbarRight &&
        createPortal(
          <PodAlertToggle
            hasAlerts={true}
            open={showAlerts}
            onClick={() => setShowAlerts((prev) => !prev)}
          />,
          toolbarRight
        )}
    </>
  )
}

const PodAlertToggle = ({
  open,
  onClick,
  hasAlerts,
}: {
  open: boolean
  onClick: () => void
  hasAlerts?: boolean
}) => {
  return (
    <div className={styles.podalertToggle} onClick={onClick}>
      <div className={styles.label}>
        <div style={{position: 'relative'}}>
          <AlertIcon />
          {hasAlerts && <div className={styles.alertdot}></div>}
        </div>
        <div>Pod Alerts</div>
      </div>
      {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
    </div>
  )
}

const ChevronRightIcon = () => {
  return (
    <svg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.96405 5.60637L0.920313 0.104809C0.901908 0.0903191 0.879788 0.081314 0.856495 0.0788282C0.833203 0.0763424 0.809681 0.0804768 0.788632 0.0907565C0.767583 0.101036 0.749861 0.117045 0.7375 0.136943C0.725139 0.156841 0.71864 0.179822 0.718751 0.203247V1.41106C0.718751 1.48762 0.754689 1.56106 0.814064 1.60793L6.43905 6.00012L0.814064 10.3923C0.753126 10.4392 0.718751 10.5126 0.718751 10.5892V11.797C0.718751 11.9017 0.839063 11.9595 0.920313 11.8954L7.96405 6.39387C8.02391 6.34717 8.07234 6.28744 8.10565 6.21921C8.13896 6.15098 8.15628 6.07605 8.15628 6.00012C8.15628 5.92419 8.13896 5.84927 8.10565 5.78104C8.07234 5.71281 8.02391 5.65307 7.96405 5.60637Z'
        fill='#A1A09A'
      />
    </svg>
  )
}

const ChevronDownIcon = () => {
  return (
    <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.34442 6.46854L11.1583 0.305274C11.171 0.289169 11.1789 0.269815 11.181 0.249434C11.1832 0.229052 11.1796 0.208471 11.1706 0.190053C11.1616 0.171635 11.1476 0.156128 11.1302 0.145312C11.1128 0.134496 11.0927 0.12881 11.0722 0.128907L10.0153 0.128907C9.94833 0.128907 9.88407 0.160353 9.84306 0.212306L5.99989 5.13417L2.15673 0.212306C2.11571 0.158986 2.05145 0.128907 1.98446 0.128907L0.927627 0.128907C0.836024 0.128907 0.785439 0.234181 0.841494 0.305274L5.65536 6.46854C5.69622 6.52092 5.74849 6.5633 5.80819 6.59245C5.86789 6.62159 5.93346 6.63674 5.99989 6.63674C6.06633 6.63674 6.13189 6.62159 6.19159 6.59245C6.25129 6.5633 6.30356 6.52092 6.34442 6.46854Z'
        fill='#ACACAC'
      />
    </svg>
  )
}

const AlertIcon = () => {
  return (
    <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.4286 12.5711H11V6.49972C11 3.98008 9.1375 1.89794 6.71429 1.55151V0.856864C6.71429 0.462221 6.39464 0.142578 6 0.142578C5.60536 0.142578 5.28571 0.462221 5.28571 0.856864V1.55151C2.8625 1.89794 1 3.98008 1 6.49972V12.5711H0.571429C0.255357 12.5711 0 12.8265 0 13.1426V13.714C0 13.7926 0.0642857 13.8569 0.142857 13.8569H4C4 14.9604 4.89643 15.8569 6 15.8569C7.10357 15.8569 8 14.9604 8 13.8569H11.8571C11.9357 13.8569 12 13.7926 12 13.714V13.1426C12 12.8265 11.7446 12.5711 11.4286 12.5711ZM6 14.714C5.52679 14.714 5.14286 14.3301 5.14286 13.8569H6.85714C6.85714 14.3301 6.47321 14.714 6 14.714ZM2.28571 12.5711V6.49972C2.28571 5.50686 2.67143 4.57472 3.37321 3.87294C4.075 3.17115 5.00714 2.78544 6 2.78544C6.99286 2.78544 7.925 3.17115 8.62679 3.87294C9.32857 4.57472 9.71429 5.50686 9.71429 6.49972V12.5711H2.28571Z'
        fill='#ACACAC'
      />
    </svg>
  )
}
