import {GrowthBook, GrowthBookProvider, useGrowthBook} from '@growthbook/growthbook-react'
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../state/store'
import {PrecisionMode} from '../state/V2/system/system.types'

enum FeatureFlagKey {
  PACKAGES = 'packages',
  FACILITY_CONFIG = 'facility-config',
}

type FeaturePackage = {package: string; features: string[]}
type FeatureFacilityConfig = {precisionMode: string}

type GrowthBookFeatureTypes = {
  [FeatureFlagKey.PACKAGES]: FeaturePackage | null
  [FeatureFlagKey.FACILITY_CONFIG]: FeatureFacilityConfig | null
}

type GrowthBookClient = GrowthBook<GrowthBookFeatureTypes>

type FeatureFlagsContextType = {
  hasFeature: (feature: string) => boolean
  facilitySetting: (type: string) => string
  featurePackage: FeaturePackage | null
}

const getGrowthBookClient = (
  attributes: Record<string, string | number | undefined>
): GrowthBookClient | undefined => {
  if (process.env.REACT_APP_GROWTHBOOK_ACTIVE === 'true') {
    return new GrowthBook<GrowthBookFeatureTypes>({
      apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST!,
      clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY!,
      subscribeToChanges: true,
      enableDevMode: process.env.REACT_APP_GROWTHBOOK_USE_STAGING === 'true',
      attributes,
    })
  }
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  hasFeature: () => true,
  facilitySetting: () => '',
  featurePackage: null,
})

export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext)
}

export const FeatureFlagsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <GrowthBookProvider growthbook={getGrowthBookClient({})}>
      <FeatureFlagsContextProvider>{children}</FeatureFlagsContextProvider>
    </GrowthBookProvider>
  )
}

export const FeatureFlagsContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const growthbook = useGrowthBook<GrowthBookFeatureTypes>()

  const facility = useSelector((state: RootState) => state.facility.current.data)
  const [attributes, setAttributes] = useState<Record<string, string | number | undefined>>({})
  const [featurePackage, setFeaturePackage] = useState<FeaturePackage | null>(null)
  const [featureFacilityConfig, setFeatureFacilityConfig] = useState<FeatureFacilityConfig | null>(
    null
  )

  useEffect(() => {
    if (growthbook) {
      growthbook.setAttributes(attributes)
      growthbook.loadFeatures().then(() => {
        setFeaturePackage(growthbook.evalFeature(FeatureFlagKey.PACKAGES).value)
        setFeatureFacilityConfig(growthbook.evalFeature(FeatureFlagKey.FACILITY_CONFIG).value)
      })
    }
  }, [growthbook, attributes])

  useEffect(() => {
    const growthBookAttributes = {
      facilityId: facility?.id,
      facilitySlug: facility?.slug,
    }
    setAttributes(growthBookAttributes)
  }, [facility?.id, facility?.slug])

  const hasFeature = useCallback(
    (feature: string): boolean => {
      return !growthbook || !!featurePackage?.features.includes(feature)
    },
    [growthbook, featurePackage]
  )

  const facilitySetting = useCallback(
    (type: string): string => {
      switch (type) {
        case 'precisionMode':
          return (
            process.env.REACT_APP_PRECISION_MODE_OVERRIDE ??
            featureFacilityConfig?.precisionMode ??
            PrecisionMode.TRIANGULATION
          )
        default:
          return ''
      }
    },
    [featureFacilityConfig]
  )

  return (
    <FeatureFlagsContext.Provider value={{hasFeature, facilitySetting, featurePackage}}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
