/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertUpdateBody } from '../models/AlertUpdateBody';
import type { ApiResponseOfAlertUpdateResponse } from '../models/ApiResponseOfAlertUpdateResponse';
import type { ApiResponseOfGetAlertByIdResponse } from '../models/ApiResponseOfGetAlertByIdResponse';
import type { ApiResponseOfGetAlertsCountResponse } from '../models/ApiResponseOfGetAlertsCountResponse';
import type { ApiResponseOfGetPaginatedAlertsResponse } from '../models/ApiResponseOfGetPaginatedAlertsResponse';
import type { GetAlertsCountBody } from '../models/GetAlertsCountBody';
import type { GetPaginatedAlertsBody } from '../models/GetPaginatedAlertsBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AlertsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedAlertsResponse 
     * @throws ApiError
     */
    public getPaginatedAlertsFiltered(
requestBody: GetPaginatedAlertsBody,
): CancelablePromise<ApiResponseOfGetPaginatedAlertsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/alert/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param alertId 
     * @returns ApiResponseOfGetAlertByIdResponse 
     * @throws ApiError
     */
    public getAlertById(
alertId: string,
): CancelablePromise<ApiResponseOfGetAlertByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/alert/{alertId}',
            path: {
                'alertId': alertId,
            },
        });
    }

    /**
     * @param alertId 
     * @param requestBody 
     * @returns ApiResponseOfAlertUpdateResponse 
     * @throws ApiError
     */
    public updateAlert(
alertId: string,
requestBody: AlertUpdateBody,
): CancelablePromise<ApiResponseOfAlertUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/alert/{alertId}',
            path: {
                'alertId': alertId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedAlertsFiltered
     * @param filters 
     * @returns ApiResponseOfGetAlertsCountResponse 
     * @throws ApiError
     */
    public getAlertsCount(
filters?: ({
operation: 'eq' | 'neq' | 'contains' | 'not-contains' | 'starts-with' | 'ends-with';
value: string | null;
field: 'type' | 'participantName' | 'action' | 'acceptedByName' | 'workedByName' | 'note';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: number | null;
valueTo?: number | null;
field: 'id' | 'heartrate' | 'batteryLevel' | 'workedById';
} | {
operation: 'eq' | 'neq';
value: (boolean | string);
field: 'accepted' | 'active';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: string | null;
valueTo?: string | null;
field: 'createdAt' | 'updatedAt' | 'acceptedAt';
}),
): CancelablePromise<ApiResponseOfGetAlertsCountResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/alert/count',
            query: {
                'filters': filters,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetAlertsCountResponse 
     * @throws ApiError
     */
    public getAlertsCountFiltered(
requestBody: GetAlertsCountBody,
): CancelablePromise<ApiResponseOfGetAlertsCountResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/alert/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
