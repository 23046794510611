import React, {ReactNode, useState} from 'react'
import {
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import './styles.scss'
import {useIntl} from 'react-intl'

type Props = {
  menuItems: {id: number; label: ReactNode}[]
  leftIcon?: React.ReactNode
  value?: number[]
  title: string
  label: string
  style?: React.CSSProperties
  selectId?: string
  handleChange: (value: number[]) => void
}

function RoundSelect({
  menuItems,
  leftIcon,
  value,
  title,
  label,
  style,
  selectId,
  handleChange,
}: Props) {
  const intl = useIntl()
  const [displayValue, setDisplayValue] = useState<number[]>(value || [])

  const updateSelections = (selectedId: number) => {
    let update = [...displayValue]

    if (update.includes(selectedId)) update.splice(update.indexOf(selectedId), 1)
    else update.push(selectedId)
    handleChange(update)
    setDisplayValue(update)
  }

  React.useEffect(() => {
    setDisplayValue(value || [])
  }, [value])

  return (
    <FormControl className='round-select-form' style={{...style}}>
      <InputLabel
        id={`${selectId}_id`}
        shrink={false}
        className='round-select-label'
        sx={{overflow: 'visible'}}
      >
        {intl.formatMessage({id: `${label}`})}
      </InputLabel>
      <Select
        name={selectId}
        id={selectId}
        labelId={`${selectId}_id`}
        className='round-select'
        value={[]}
        multiple
        onChange={(e: SelectChangeEvent<number[]>) => {
          if (typeof e.target.value !== 'string') updateSelections(e.target.value[0])
        }}
        IconComponent={() => (
          <ArrowDropDownIcon
            htmlColor='#A1A09A'
            sx={{position: 'absolute', right: '8px', cursor: 'pointer', pointerEvents: 'none'}}
          />
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '300px', // Set this to your desired height
              overflow: 'auto',
            },
          },
        }}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment
                style={{borderRadius: '24px'}}
                position='start'
                sx={{pointerEvents: 'none'}}
              >
                {leftIcon}
              </InputAdornment>
            }
          />
        }
      >
        <span
          style={{
            color: '#A1A09A',
            fontSize: '12px',
            lineHeight: '24px',
            fontWeight: 400,
            padding: '4px 16px',
            letterSpacing: '0.07px',
          }}
        >
          {intl.formatMessage({id: `${title}`})}
        </span>
        {menuItems?.map(({id, label}) => (
          <MenuItem className='round-select-item' value={id} key={id}>
            <Checkbox
              checked={displayValue.includes(id)}
              sx={{'&.Mui-checked': {color: '#FFB224'}}}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              {label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RoundSelect
