import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {
  CellCreateBody,
  CellUpdateBody,
  FacilityUpdateBody,
  GetPaginatedCellsBody,
  GetPaginatedPodsBody,
  PodCreateBody,
  PodUpdateBody,
} from '../openapi'

/*--------------------- Facility ------------------------*/
export const _getFacilities = createAsyncThunk('facility/_list', async () => {
  return api.facility.getFacilities()
})

export const _getCurrentFacility = createAsyncThunk('facility/_getCurrentFacility', async () => {
  return api.facility.getCurrentFacility()
})

export const _updateCurrentFacility = createAsyncThunk(
  'facility/_updateFacility',
  async (update: FacilityUpdateBody) => {
    return api.facility.updateCurrentFacility(update)
  }
)

/*--------------------- Pods ------------------------*/
export const _getPods = createAsyncThunk('pod/_list', async (params: GetPaginatedPodsBody) => {
  const fullParams = {limit: 200, ...params}
  return api.pods.getPaginatedPodsFiltered(fullParams)
})
export const _getFacilityPods = createAsyncThunk(
  'pod/facilityList',
  async (params: GetPaginatedPodsBody & {facilityId: number} = {limit: 200, facilityId: 1}) => {
    return api.pods.getPaginatedPodsFiltered({limit: 200, ...params})
  }
)

export const createPod = async (data: PodCreateBody) => {
  return api.pods.createPod(data)
}

export const updatePod = async (podId: number, data: PodUpdateBody) => {
  return api.pods.updatePod(podId.toString(), data)
}

/*--------------------- Cells ------------------------*/
export const _getCells = createAsyncThunk('cell/_list', async (params: GetPaginatedCellsBody) => {
  return api.cells.getPaginatedCellsFiltered(params)
})
export const getCells = _getCells as any

export const createCell = async (data: CellCreateBody) => {
  return api.cells.createCell(data)
}

export const updateCell = async (cellId: number, data: CellUpdateBody) => {
  return api.cells.updateCell(cellId.toString(), data)
}

export const _updateCell = createAsyncThunk(
  '_updateCell',
  async ({id, data}: {id: number; data: CellUpdateBody}) => {
    return api.cells.updateCell(id.toString(), data)
  }
)

export const _createCell = createAsyncThunk('_createCell', async (data: CellCreateBody) => {
  return api.cells.createCell(data)
})

/*--------------------- Cell Open Times ------------------------*/
export const _getAllCellOpenTimes = createAsyncThunk(
  'cell/allCellOpenTimes',
  async ({startTime, endTime}: {startTime: Date; endTime: Date}) => {
    return api.cellOpenTimes.getCellOpenTimes(
      undefined,
      startTime.toISOString(),
      endTime.toISOString()
    )
    // const response: ApiResponse<CellOpenTime[]> = await request({
    //   url: 'v2/cell-open-time',
    //   method: 'get',
    //   params,
    // })
    // return response
  }
)

export const _getCellOpenTimesById = createAsyncThunk(
  'cell/cellOpenTimesById',
  async ({cellId, startTime, endTime}: {cellId: number; startTime: Date; endTime: Date}) => {
    return api.cellOpenTimes.getCellOpenTimes(
      cellId,
      startTime.toISOString(),
      endTime.toISOString()
    )
    // const response: ApiResponse<CellOpenTime[]> = await request({
    //   url: 'v2/cell-open-time',
    //   method: 'get',
    //   params,
    // })
    // return response
  }
)

/*--------------------- Locations ------------------------*/

export const _getLocations = createAsyncThunk('locations/', async () => {
  return api.locationCoordinates.getLocationCoordinates()
})

export const _getLocationCount = createAsyncThunk('locations/counts', async () => {
  return api.participantLocation.getParticipantLocationCount()
})
