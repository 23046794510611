/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfBuildingCreateResponse } from '../models/ApiResponseOfBuildingCreateResponse';
import type { ApiResponseOfBuildingUpdateResponse } from '../models/ApiResponseOfBuildingUpdateResponse';
import type { ApiResponseOfDeleteBuildingResponse } from '../models/ApiResponseOfDeleteBuildingResponse';
import type { ApiResponseOfGetBuildingByIdResponse } from '../models/ApiResponseOfGetBuildingByIdResponse';
import type { ApiResponseOfGetPaginatedBuildingsResponse } from '../models/ApiResponseOfGetPaginatedBuildingsResponse';
import type { BuildingCreateBody } from '../models/BuildingCreateBody';
import type { BuildingUpdateBody } from '../models/BuildingUpdateBody';
import type { GetPaginatedBuildingsBody } from '../models/GetPaginatedBuildingsBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BuildingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedBuildingsResponse 
     * @throws ApiError
     */
    public getPaginatedBuildingsFiltered(
requestBody: GetPaginatedBuildingsBody,
): CancelablePromise<ApiResponseOfGetPaginatedBuildingsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/building/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfBuildingCreateResponse 
     * @throws ApiError
     */
    public createBuilding(
requestBody: BuildingCreateBody,
): CancelablePromise<ApiResponseOfBuildingCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/building/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param buildingId 
     * @returns ApiResponseOfGetBuildingByIdResponse 
     * @throws ApiError
     */
    public getBuildingById(
buildingId: string,
): CancelablePromise<ApiResponseOfGetBuildingByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/building/{buildingId}',
            path: {
                'buildingId': buildingId,
            },
        });
    }

    /**
     * @param buildingId 
     * @param requestBody 
     * @returns ApiResponseOfBuildingUpdateResponse 
     * @throws ApiError
     */
    public updateBuilding(
buildingId: string,
requestBody: BuildingUpdateBody,
): CancelablePromise<ApiResponseOfBuildingUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/building/{buildingId}',
            path: {
                'buildingId': buildingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param buildingId 
     * @returns ApiResponseOfDeleteBuildingResponse 
     * @throws ApiError
     */
    public deleteBuilding(
buildingId: string,
): CancelablePromise<ApiResponseOfDeleteBuildingResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/building/{buildingId}',
            path: {
                'buildingId': buildingId,
            },
        });
    }

}
