/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetFileResponse } from '../models/ApiResponseOfGetFileResponse';
import type { ApiResponseOfUploadFileResponse } from '../models/ApiResponseOfUploadFileResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FileManagerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param folder 
     * @param fileName 
     * @returns ApiResponseOfGetFileResponse 
     * @throws ApiError
     */
    public getFile(
folder: string,
fileName: string,
): CancelablePromise<ApiResponseOfGetFileResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/file-manager/{folder}/{fileName}',
            path: {
                'folder': folder,
                'fileName': fileName,
            },
        });
    }

    /**
     * @param folder 
     * @returns ApiResponseOfUploadFileResponse 
     * @throws ApiError
     */
    public uploadFile(
folder: string,
): CancelablePromise<ApiResponseOfUploadFileResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/file-manager/upload/{folder}',
            path: {
                'folder': folder,
            },
        });
    }

}
