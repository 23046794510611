export const AlertTypes = {
  BAND_OFFLINE: 'BAND_OFFLINE',
  HEART_RATE_OFFLINE: 'HEART_RATE_OFFLINE',
  OUT_OF_CELL_TIME_COMPLIANCY_WARNING: 'OUT_OF_CELL_TIME_COMPLIANCY_WARNING',
  LOW_BATTERY_LEVEL: 'LOW_BATTERY_LEVEL',
  LOW_HEART_RATE: 'LOW_HEART_RATE',
  HIGH_HEART_RATE: 'HIGH_HEART_RATE',
  BAND_TAMPER: 'BAND_TAMPER',
  CELL_NOT_COMPLIANT: 'CELL_NOT_COMPLIANT',
  CELL_COMPLIANCE_RULE_IN_RISK: 'CELL_COMPLIANCE_RULE_IN_RISK',
} as const

export const alertsFilterTypes: {[key: string]: 'string' | 'number' | 'boolean' | 'date'} = {
  id: 'number',
  heartrate: 'number',
  batteryLevel: 'number',
  time: 'date',
  accepted: 'boolean',
}

export const alertTypeDataTableOptions = [
  {label: 'High Heart Rate Alert', value: AlertTypes.HIGH_HEART_RATE},
  {label: 'Low Heart Rate Alert', value: AlertTypes.LOW_HEART_RATE},
  {label: 'Low Battery Level Alert', value: AlertTypes.LOW_BATTERY_LEVEL},
  {label: 'Band Offline Alert', value: AlertTypes.BAND_OFFLINE},
  {label: 'Band Tamper Alert', value: AlertTypes.BAND_TAMPER},
  {
    label: 'Out of Cell Time Alert',
    value: AlertTypes.OUT_OF_CELL_TIME_COMPLIANCY_WARNING,
  },
]

export const HistoricalAlertTypes = {
  HEART_RATE: 'Heart Rate',
  HIGH_HEART_RATE: 'Heart Rate',
  LOW_HEART_RATE: 'Heart Rate',
  TAMPER: 'Tamper',
  BAND_TAMPER: 'Tamper',
  LOW_BATTERY_LEVEL: 'Battery',
  BAND_OFFLINE: 'Band Offline',
  HEART_RATE_OFFLINE: 'HR Offline',
  KEEP_SEPARATE: 'Keep Separate',
} as const

export type AlertIconKeysHistorical =
  (typeof HistoricalAlertTypes)[keyof typeof HistoricalAlertTypes]
