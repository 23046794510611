import {useEffect, useMemo} from 'react'
import {useAppDispatch} from '../../../../state/hooks'
import {_getLocationCount, _getLocations} from '../../../../state/V2/facility/facility.actions'
import {isApiError} from '../../../../state/V2/api.helpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../state/store'
import LocationInmateList from './LocationInmateList'
import {useMap2DState} from './Map2DContext'

const InmateListLayer = () => {
  const [{showPodIds, showRiskValues}] = useMap2DState()
  const locations = useSelector((state: RootState) => state.locations.list?.data)
  const participants = useSelector((state: RootState) => state.participant.list.data.results)
  const dispatch = useAppDispatch()

  //Get latest location data
  useEffect(() => {
    const response = dispatch(_getLocations())

    if (isApiError(response)) {
      console.error(response.error.message)
      return
    }
  }, [dispatch])

  //get latest location counts
  useEffect(() => {
    const response = dispatch(_getLocationCount())

    if (isApiError(response)) {
      console.error(response.error.message)
      return
    }
  }, [dispatch])

  // participants with a cell id and risk level
  const participantsFiltered = useMemo(() => {
    return participants?.filter((participant) => {
      return (
        showPodIds.includes(participant.cell?.pod?.id || -1) &&
        showRiskValues.includes(participant.risk || -1)
      )
    })
  }, [participants, showPodIds, showRiskValues])

  const locationsWithParticipants = useMemo(() => {
    if (!locations) return []

    return locations.map((location) => {
      // participants in this location
      const participantsHere = participantsFiltered?.filter(
        (participant) => participant?.participantLocationTime?.id === location.id
      )
      return {
        ...location,
        count: participantsHere?.length || 0,
        participants: participantsHere || [],
      }
    })
  }, [locations, participantsFiltered])

  return (
    <div className='pointer-events-none absolute bottom-0 left-0 right-0 top-0 overflow-hidden'>
      {locationsWithParticipants.map((location) => {
        return <LocationInmateList key={location.id} location={location} />
      })}
    </div>
  )
}
export default InmateListLayer
