import {FC, useEffect, useState} from 'react'
import './inmateDetailscss.scss'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../../../state/store'
import {capitalizeFirstLetters} from '../../../../../helpers/strings'
import {_getLocationCount} from '../../../../../state/V2/facility/facility.actions'
import {useIntl} from 'react-intl'
import Skeleton from '@mui/material/Skeleton'

interface IProps {
  setFilter: (filter: {location?: string; risk?: number}) => void
}

interface ResultItem {
  count: number
  location: string
}

const InmateDetails: FC<IProps> = ({setFilter}) => {
  const [zones, setZones] = useState<(string | number)[][]>([])
  const intl = useIntl()

  const dispatch = useDispatch<AppDispatch>()

  const podAssignments = useSelector((state: RootState) => state.users.podAssignment.data)
  const inmateLocationCounts = useSelector((state: RootState) => state.locations.counts.data)
  const isLoading = useSelector((state: RootState) => state.participant.list.status === 'pending')

  useEffect(() => {
    dispatch(_getLocationCount())
  }, [dispatch, podAssignments])

  useEffect(() => {
    if (!inmateLocationCounts?.locations) return

    const zonesArray = inmateLocationCounts.locations.map((item: ResultItem) => {
      return [item.location, item.count]
    })
    setZones(zonesArray)
  }, [inmateLocationCounts, podAssignments])

  return (
    <section className='inmateDetails my-2 flex flex-wrap items-center justify-around'>
      <div className='total w-full pl-2 pr-2 lg:w-1/5'>
        <h1 className='uppercase'>{intl.formatMessage({id: 'DASHBOARD.TOTAL_INMATES'})}</h1>
        <h2>
          {isLoading ? (
            <div className='pt-3'>
              <Skeleton variant='rounded' height={70} width={70} animation='wave' />
            </div>
          ) : (
            inmateLocationCounts?.total ?? ''
          )}
        </h2>
      </div>
      <div className='w-full pl-4 pr-4 lg:w-4/5'>
        <div className='flex flex-row flex-wrap items-start justify-start'>
          {zones?.map((zone: any, index: number) => {
            return (
              <div
                key={index}
                className='inmateDetails--pod relative m-2 max-w-full flex-1 flex-grow content-stretch rounded border border-white p-3 px-2 text-center'
                onClick={() => setFilter({location: zone[0].toUpperCase()})}
              >
                <p>{capitalizeFirstLetters(zone[0])}</p>
                <span id='inmateZone'>{zone[1]}</span>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default InmateDetails
