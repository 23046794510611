import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard'
import {getCSSVariableValue} from '../assets/ts/_utils'
import {WithChildren} from '../helpers'
import Floors from '../pages/facility-ops/floors'
import Cells from '../pages/facility-ops/cells'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UserProfilePage = lazy(() => import('../pages/user-profile'))
  const Devices = lazy(() => import('../pages/devices'))
  const Offenders = lazy(() => import('../pages/inmates/Inmates'))
  const Alerts = lazy(() => import('../pages/alerts/Alerts'))
  const Supervisors = lazy(() => import('../pages/supervisors/Supervisors'))
  const Facility = lazy(() => import('../pages/facility/Facility'))
  const FacilityOps = lazy(() => import('../pages/facility-ops'))
  const LiveTrack = lazy(() => import('../pages/live-track'))
  const HistoricalReplay = lazy(() => import('../pages/historical-replay'))

  //Settings Pages
  const FacilityConfigGeneral = lazy(() => import('../pages/settings/facility/general'))
  const FacilityConfigCellCompliance = lazy(
    () => import('../pages/settings/facility/cellcompliance')
  )
  const FacilityConfigAlerts = lazy(() => import('../pages/settings/facility/alerts'))
  const FacilityConfigEscalation = lazy(() => import('../pages/settings/facility/escalation'))
  const FacilityConfigContacts = lazy(() => import('../pages/settings/facility/contacts'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registrtion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='profile/:id/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-profile/:id'
          element={
            <SuspensedView>
              <UserProfilePage />
            </SuspensedView>
          }
        />

        {/* OFFENDER MANAGEMNT ROUTING STARTS */}

        <Route
          path='inmates/*'
          element={
            <SuspensedView>
              <Offenders />
            </SuspensedView>
          }
        />

        <Route
          path='devices/:id?'
          element={
            <SuspensedView>
              <Devices />
            </SuspensedView>
          }
        />

        <Route
          path='supervisors/:id?'
          element={
            <SuspensedView>
              <Supervisors />
            </SuspensedView>
          }
        />
        <Route
          path='alerts/:id?'
          element={
            <SuspensedView>
              <Alerts />
            </SuspensedView>
          }
        />
        <Route
          path='facility/*'
          element={
            <SuspensedView>
              <Facility />
            </SuspensedView>
          }
        />
        <Route
          path='facility-ops/:buildingId/cells/:floorId'
          element={
            <SuspensedView>
              <Cells />
            </SuspensedView>
          }
        />
        <Route
          path='facility-ops/:buildingId'
          element={
            <SuspensedView>
              <Floors />
            </SuspensedView>
          }
        />
        <Route
          path='facility-ops'
          element={
            <SuspensedView>
              <FacilityOps />
            </SuspensedView>
          }
        />
        <Route
          path='live-track/*'
          element={
            <SuspensedView>
              <LiveTrack />
            </SuspensedView>
          }
        />
        <Route
          path='historical-replay/*'
          element={
            <SuspensedView>
              <HistoricalReplay />
            </SuspensedView>
          }
        />
        {/* OFFENDER MANAGEMENT ROUTING ENDS */}

        {/* SETTINGS ROUTING */}
        <Route path='settings' element={<Navigate to='/settings/facility/general' />} />
        <Route path='settings/facility' element={<Navigate to='/settings/facility/general' />} />
        <Route
          path='settings/facility/general'
          element={
            <SuspensedView>
              <FacilityConfigGeneral />
            </SuspensedView>
          }
        />
        <Route
          path='settings/facility/alerts'
          element={
            <SuspensedView>
              <FacilityConfigAlerts />
            </SuspensedView>
          }
        />
        <Route
          path='settings/facility/cellcompliance'
          element={
            <SuspensedView>
              <FacilityConfigCellCompliance />
            </SuspensedView>
          }
        />
        <Route
          path='settings/facility/escalation'
          element={
            <SuspensedView>
              <FacilityConfigEscalation />
            </SuspensedView>
          }
        />
        <Route
          path='settings/facility/contacts'
          element={
            <SuspensedView>
              <FacilityConfigContacts />
            </SuspensedView>
          }
        />

        {/* OFFENDER MANAGEMENT ROUTING ENDS */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
