import {useAppSelector} from '../../../../state/hooks'

export function MenuInner() {
  const facilityName = useAppSelector((state) => state.facility.current.data?.facilityName)

  return (
    <>
      <section>
        <p className='mb-0 text-base'>{facilityName}</p>
      </section>
    </>
  )
}
