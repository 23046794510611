import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {setToCamelCasing} from '../../../../../helpers/strings'
import {zoneSwitchSelect} from '../../../../../helpers/live-track'
import {ReactComponent as PodIcon} from '../../assets/General/Pod.svg'
import {ReactComponent as Risk} from '../../assets/General/Risk.svg'
import RoundSelect from './RoundSelect'
import {ReactComponent as HighRisk} from '../../assets/General/High_Risk.svg'
import {ReactComponent as MediumRisk} from '../../assets/General/Medium_Risk.svg'
import {ReactComponent as LowRisk} from '../../assets/General/Low_Risk.svg'
import SwitchDropdown from './SwitchDropdown'

import './styles.scss'
import {useMap2DState} from '../Map2DContext'
import {useAppSelector} from '../../../../../state/hooks'

const NavBar = () => {
  const [
    {containerHeight, containerWidth, zone, showImei, showGhosts, darkMode, transformComponentRef},
    updateMap2DState,
  ] = useMap2DState()
  const locations = useAppSelector((state) =>
    [...(state.locations.list?.data || [])].sort((a, b) => a.location.localeCompare(b.location))
  )

  const handleZoneChange = (event: SelectChangeEvent) => {
    updateMap2DState({
      zone: event.target.value,
    })
    zoneSwitchSelect(
      event.target.value,
      transformComponentRef,
      {width: containerWidth, height: containerHeight},
      locationNames,
      locationsCamelCase
    )
  }

  const locationNames = locations
    ? ['Reset', ...locations.map((locationObj: any) => locationObj.location)]
    : ['Reset']

  const locationsCamelCase = locationNames.map((location: any) => setToCamelCasing(location))

  const handleMoveForward = () => {
    if (transformComponentRef?.current) {
      const {zoomToElement} = transformComponentRef.current
      const currentIndex = locationNames.indexOf(zone)
      const nextZone = (currentIndex + 1) % locationNames.length || 0

      zoomToElement(setToCamelCasing(locationNames[nextZone]))
      zoneSwitchSelect(
        locationNames[nextZone],
        transformComponentRef,
        {width: containerWidth, height: containerHeight},
        locationNames,
        locationsCamelCase
      )
      updateMap2DState({
        zone: locationNames[nextZone],
      })
    }
  }

  const handleMoveBackward = () => {
    if (transformComponentRef?.current) {
      const {zoomToElement} = transformComponentRef.current
      const currentIndex = locationNames.indexOf(zone)
      const lastIndex = locationNames.length - 1
      const nextZone = currentIndex === 0 ? lastIndex : currentIndex - 1

      zoomToElement(setToCamelCasing(locationNames[nextZone]))
      zoneSwitchSelect(
        locationNames[nextZone],
        transformComponentRef,
        {width: containerWidth, height: containerHeight},
        locationNames,
        locationsCamelCase
      )
      updateMap2DState({
        zone: locationNames[nextZone],
      })
    }
  }

  const arrowElement = (forward?: boolean) => {
    return (
      <IconButton
        color='default'
        aria-label='add'
        className="flex justify-items-center items-center rounded-none p-1"
        onClick={forward ? handleMoveForward : handleMoveBackward}
        // onClick={handleMoveForward}
        sx={{ borderRadius: 0, border: '1px solid #5b5b5b' }}
        disableRipple
      >
        {forward ? (
          // Flex is not centering the icons
          <ArrowForwardIosIcon sx={{ display: 'block' }} />
        ) : (
          <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
        )}
      </IconButton>
    )
  }

  return (
    <Paper
      elevation={0}
      square
      style={{
        width: '100%',
        padding: '8px',
        marginBottom: 'px',
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'space-between',
        zIndex: 1,
        position: 'relative',
      }}
    >
      <div className='flex flex-wrap items-center gap-2'>
        <div className='flex items-center'>
          {arrowElement()}
          <FormControl>
            <Select
              name='zone_id'
              id='simple-select'
              value={zone}
              variant='outlined'
              onChange={handleZoneChange}
              IconComponent={ExpandMoreIcon}
              style={{width: '256px', marginLeft: 8, marginRight: 8}}
              size='small'
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    overflow: 'auto',
                  },
                },
              }}
            >
              {locationNames?.map((data: any, index: any) => (
                <MenuItem value={data} key={index}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {arrowElement(true)}
        </div>
        <BuildingSelection />
        <FloorSelection />
      </div>
      <div className='d-flex justify-content-center align-items-center'>
        <SwitchDropdown
          style={{marginRight: '16px'}}
          switchConfigs={[
            {
              checked: showImei,
              onChange: () => updateMap2DState({showImei: !showImei}),
              intlString: 'LIVETRACK.NAV.VIEW_PODS',
            },
            {
              checked: showGhosts,
              onChange: () => updateMap2DState({showGhosts: !showGhosts}),
              intlString: 'LIVETRACK.NAV.GHOSTS_OFF',
            },
            {
              checked: !darkMode,
              onChange: () => updateMap2DState({darkMode: !darkMode}),
              intlString: 'LIVETRACK.NAV.LIGHT_MODE',
            },
          ]}
        />
        <RiskSelection />
        <PodSelection />
      </div>
    </Paper>
  )
}

export const BuildingSelection = () => {
  const buildings = useAppSelector((state) => state.facilityOps.buildings.data.results)
  const [{buildingId}, updateMap2DState] = useMap2DState()

  const onChange = (event: SelectChangeEvent) => {
    updateMap2DState({
      buildingId: parseInt(event.target.value),
      floorId: 0,
      floorLabel: undefined,
    })
  }

  if (!buildings || !buildingId || buildings.length <= 1) return null

  return (
    <FormControl>
      <InputLabel id='building-label'>Building</InputLabel>
      <Select
        label='Building'
        labelId='building-label'
        id='building'
        variant='outlined'
        value={buildingId.toString()}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        sx={{minWidth: 150}}
        size='small'
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '300px',

              overflow: 'auto',
            },
          },
        }}
      >
        {buildings?.map((data, index) => (
          <MenuItem value={data.id} key={index}>
            <div>{data.name}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const FloorSelection = () => {
  const floors = useAppSelector((state) => state.facilityOps.floors.data.results)
  const [{floorId}, updateMap2DState] = useMap2DState()

  const onChange = (event: SelectChangeEvent) => {
    if (!floors) return

    const newFloorId = parseInt(event.target.value)
    const floor = floors.find((floor) => floor.id === newFloorId)
    if (!floor) return

    updateMap2DState({
      floorId: newFloorId,
      floorLabel: floor.fullLabel,
    })
  }

  if (!floors || !floorId || floors.length <= 1 || floorId <= 0) return null

  return (
    <FormControl>
      <InputLabel id='floor-label'>Floor</InputLabel>
      <Select
        label='Floor'
        labelId='floor-label'
        id='floor'
        variant='outlined'
        value={floorId.toString()}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        sx={{minWidth: 150}}
        size='small'
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '300px',

              overflow: 'auto',
            },
          },
        }}
      >
        {floors?.map((data, index) => (
          <MenuItem value={data.id} key={index}>
            <div>{data.name}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const RiskSelection = () => {
  const [{showRiskValues}, updateMap2DState] = useMap2DState()

  const onChange = (value: number[]) => updateMap2DState({showRiskValues: value})
  const riskLevels = [
    {
      id: 3,
      label: (
        <>
          High Risk
          <HighRisk style={{marginLeft: '8px'}} />
        </>
      ),
    },
    {
      id: 2,
      label: (
        <>
          Medium Risk
          <MediumRisk style={{marginLeft: '8px'}} />
        </>
      ),
    },
    {
      id: 1,
      label: (
        <>
          Low Risk
          <LowRisk style={{marginLeft: '8px'}} />
        </>
      ),
    },
  ]

  return (
    <RoundSelect
      menuItems={riskLevels}
      leftIcon={<Risk />}
      value={showRiskValues}
      title='LIVETRACK.NAV.VIEW_RISK'
      label='LIVETRACK.NAV.RISK_LABEL'
      handleChange={onChange}
      selectId='select-main-risk'
    />
  )
}

const PodSelection = () => {
  const pods = useAppSelector((state) => state.pods.list?.data?.results || [])
  const [{showPodIds}, updateMap2DState] = useMap2DState()

  const onChange = (value: number[]) => updateMap2DState({showPodIds: value})
  const podOptions = pods?.map(({id, podName}) => ({id: id, label: podName}))

  return (
    <RoundSelect
      menuItems={podOptions}
      leftIcon={<PodIcon />}
      value={showPodIds}
      title='LIVETRACK.NAV.VIEW_PODS'
      label='LIVETRACK.NAV.PODS_LABEL'
      style={{marginLeft: '8px', marginRight: '8px'}}
      handleChange={onChange}
      selectId='select-main-pod'
    />
  )
}

export default NavBar
