/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfBuildingFloorCreateResponse } from '../models/ApiResponseOfBuildingFloorCreateResponse';
import type { ApiResponseOfBuildingFloorUpdateResponse } from '../models/ApiResponseOfBuildingFloorUpdateResponse';
import type { ApiResponseOfDeleteBuildingFloorResponse } from '../models/ApiResponseOfDeleteBuildingFloorResponse';
import type { ApiResponseOfGetBuildingFloorByIdResponse } from '../models/ApiResponseOfGetBuildingFloorByIdResponse';
import type { ApiResponseOfGetPaginatedBuildingFloorsResponse } from '../models/ApiResponseOfGetPaginatedBuildingFloorsResponse';
import type { BuildingFloorCreateBody } from '../models/BuildingFloorCreateBody';
import type { BuildingFloorUpdateBody } from '../models/BuildingFloorUpdateBody';
import type { GetPaginatedBuildingFloorsBody } from '../models/GetPaginatedBuildingFloorsBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BuildingFloorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedBuildingFloorsResponse 
     * @throws ApiError
     */
    public getPaginatedBuildingFloorsFiltered(
requestBody: GetPaginatedBuildingFloorsBody,
): CancelablePromise<ApiResponseOfGetPaginatedBuildingFloorsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/building-floor/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfBuildingFloorCreateResponse 
     * @throws ApiError
     */
    public createBuildingFloor(
requestBody: BuildingFloorCreateBody,
): CancelablePromise<ApiResponseOfBuildingFloorCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/building-floor/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param buildingFloorId 
     * @returns ApiResponseOfGetBuildingFloorByIdResponse 
     * @throws ApiError
     */
    public getBuildingFloorById(
buildingFloorId: string,
): CancelablePromise<ApiResponseOfGetBuildingFloorByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/building-floor/{buildingFloorId}',
            path: {
                'buildingFloorId': buildingFloorId,
            },
        });
    }

    /**
     * @param buildingFloorId 
     * @param requestBody 
     * @returns ApiResponseOfBuildingFloorUpdateResponse 
     * @throws ApiError
     */
    public updateBuildingFloor(
buildingFloorId: string,
requestBody: BuildingFloorUpdateBody,
): CancelablePromise<ApiResponseOfBuildingFloorUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/building-floor/{buildingFloorId}',
            path: {
                'buildingFloorId': buildingFloorId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param buildingFloorId 
     * @returns ApiResponseOfDeleteBuildingFloorResponse 
     * @throws ApiError
     */
    public deleteBuildingFloor(
buildingFloorId: string,
): CancelablePromise<ApiResponseOfDeleteBuildingFloorResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/building-floor/{buildingFloorId}',
            path: {
                'buildingFloorId': buildingFloorId,
            },
        });
    }

}
