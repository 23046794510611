/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetParticipantLocationCountResponse } from '../models/ApiResponseOfGetParticipantLocationCountResponse';
import type { ApiResponseOfGetParticipantLocationDurationResponse } from '../models/ApiResponseOfGetParticipantLocationDurationResponse';
import type { ApiResponseOfGetParticipantLocationsDoorHistoryResponse } from '../models/ApiResponseOfGetParticipantLocationsDoorHistoryResponse';
import type { ApiResponseOfLocationTimeCreateResponse } from '../models/ApiResponseOfLocationTimeCreateResponse';
import type { LocationTimeCreateBody } from '../models/LocationTimeCreateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ParticipantLocationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param participantId 
     * @param startTime 
     * @param endTime 
     * @returns ApiResponseOfGetParticipantLocationDurationResponse 
     * @throws ApiError
     */
    public getParticipantLocationDuration(
participantId: string,
startTime?: string | null,
endTime?: string | null,
): CancelablePromise<ApiResponseOfGetParticipantLocationDurationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/participant-location/duration/{participantId}',
            path: {
                'participantId': participantId,
            },
            query: {
                'startTime': startTime,
                'endTime': endTime,
            },
        });
    }

    /**
     * @param participantId 
     * @param startTime 
     * @param endTime 
     * @returns ApiResponseOfGetParticipantLocationsDoorHistoryResponse 
     * @throws ApiError
     */
    public getParticipantLocationsDoorHistory(
participantId: string,
startTime?: string | null,
endTime?: string | null,
): CancelablePromise<ApiResponseOfGetParticipantLocationsDoorHistoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/participant-location/location-with-doors/{participantId}',
            path: {
                'participantId': participantId,
            },
            query: {
                'startTime': startTime,
                'endTime': endTime,
            },
        });
    }

    /**
     * @returns ApiResponseOfGetParticipantLocationCountResponse 
     * @throws ApiError
     */
    public getParticipantLocationCount(): CancelablePromise<ApiResponseOfGetParticipantLocationCountResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/participant-location/count',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfLocationTimeCreateResponse 
     * @throws ApiError
     */
    public createLocationTime(
requestBody: LocationTimeCreateBody,
): CancelablePromise<ApiResponseOfLocationTimeCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/participant-location/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
