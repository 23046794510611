import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      <Link to='/dashboard' className='d-flex align-items-center text-orange text-sm'>
        {'Dashboard'}
      </Link>
      {/* end::Title */}

      {pageTitle && (
        <>
          {/* {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'></span>
            )} */}
          <span className='mx-4 text-gray-600'>/</span>
          <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
            {pageBreadcrumbs &&
              pageBreadcrumbs.length > 0 &&
              config.pageTitle &&
              config.pageTitle.breadCrumbs &&
              Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator && item.isActive && (
                    <>
                      <Link className='text-muted text-hover-primary' to={item.path}>
                        {item.title}
                      </Link>
                      <span className='h-20px text-muted mx-4'>/</span>
                    </>
                  )}
                </li>
              ))}
            <li className='breadcrumb-item text-dark fw-semibold'>{pageTitle}</li>
          </ul>
        </>
      )}
    </div>
  )
}

export {DefaultTitle}
