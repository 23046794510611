import {ActionReducerMapBuilder, AsyncThunk, SerializedError, createSlice} from '@reduxjs/toolkit'
import {
  HistoricalResponse,
  HistoricalStreamState,
  STREAM_STATUS,
  StreamStatus,
} from './historical.types'
import {
  _addStreamedData,
  _cancelHistorical,
  _getHistoricalByLocationIds,
  _getHistoricalByParticipantIds,
  _getHistoricalFollow,
  _intiStream,
  stopSocketListener,
} from './historical.actions'
import {DateTime} from 'luxon'

export type HistoricalState = {[key: string]: HistoricalStreamState | any} & {
  status: StreamStatus
  progress: number
  startTime?: string
  endTime?: string
  requestId?: string
  error?: SerializedError | null
  coordinates: HistoricalStreamState
  heartrate: HistoricalStreamState
  battery: HistoricalStreamState
  alert: HistoricalStreamState
}

export const historicalStreamStateDefault = {
  progress: 0,
  data: [],
}

// const testData = {

// }

const initialState: HistoricalState = {
  status: STREAM_STATUS.READY,
  progress: 0,
  coordinates: {...historicalStreamStateDefault},
  heartrate: {...historicalStreamStateDefault},
  alert: {...historicalStreamStateDefault},
  battery: {...historicalStreamStateDefault},
}

const historical = createSlice({
  name: 'historical',
  initialState,
  reducers: {
    resetHistorical: () => {
      const now = DateTime.now()
      return {
        ...initialState,
        endTime: now.toISOTime() || '',
        startTime: now.minus({minutes: 15}).toISOTime() || '',
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(_addStreamedData.fulfilled, (state, action) => {
      // updates the sent sub-stream data and the overall progress
      const update = {...action.payload}
      if (!update) return

      if (update.progress) state.progress = update.progress
      if (update.coordinates) state.coordinates = update.coordinates
      if (update.heartrate) state.heartrate = update.heartrate
      if (update.battery) state.battery = update.battery
      if (update.alert) state.alert = update.alert
      if (update.status) state.status = update.status
    })
    builder.addCase(_intiStream.fulfilled, (state, action) => {
      return {
        ...initialState,
        requestId: action.payload.requestId,
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
      }
    })
    addStreamStartCases(builder, _getHistoricalByParticipantIds)
    addStreamStartCases(builder, _getHistoricalByLocationIds)
    addStreamStartCases(builder, _getHistoricalFollow)
    builder.addCase(_cancelHistorical.fulfilled, (state, action) => {
      return initialState
    })
  },
})

// Add status and error updating based on the different stream start
export const addStreamStartCases = <T extends AsyncThunk<HistoricalResponse, any, any>>(
  builder: ActionReducerMapBuilder<HistoricalState>,
  asyncThunk: T
) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.status = STREAM_STATUS.PENDING
    })
    .addCase(asyncThunk.fulfilled, (state) => {
      if (state.status === STREAM_STATUS.PENDING) state.status = STREAM_STATUS.STREAMING
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.status = STREAM_STATUS.FAILED
      state.error = action.error as SerializedError
      if (state.requestId) stopSocketListener(state.requestId)
      state.requestId = undefined
    })
}
export const resetHistorical = historical.actions.resetHistorical
export const historicalReducer = historical.reducer
