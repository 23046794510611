// actions.ts
import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {AlertUpdateBody, GetPaginatedAlertsBody} from '../openapi'

/** GET ALERTS LIST **/
export const _getAlerts = createAsyncThunk('alerts/list', async (data: GetPaginatedAlertsBody) => {
  return api.alerts.getPaginatedAlertsFiltered(data)
})

/** GET ALERTS LIST **/
export const _getAlertsByParticipantId = createAsyncThunk(
  'alerts/selectedParticipant',
  async (data: GetPaginatedAlertsBody & {participantId: number}) => {
    return api.alerts.getPaginatedAlertsFiltered(data)
  }
)

/** ALERT DETAIL BY ID **/
export const _getAlertById = createAsyncThunk('alerts/selected', async (id: any) => {
  return api.alerts.getAlertById(id)
})

/** GET UNREAD ALERTS COUNT **/
export const _getUnreadCount = createAsyncThunk('alerts/unread', async () => {
  return api.alerts.getAlertsCountFiltered({})
})

/** UPDATE ALERTS **/
export const _updateAlerts = createAsyncThunk(
  'alerts/updated',
  async ({id, data}: {id: number; data: AlertUpdateBody}) => {
    return api.alerts.updateAlert(id.toString(), data)
  }
)
