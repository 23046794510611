import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {
  resetPasswordRequest,
  resetPassword,
  resetPasswordVerify,
} from '../../../../state/V2/authentication/authentication.actions'
import {z} from 'zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'

export function ForgotPassword() {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [email, setEmail] = useState<string>()
  const [token, setToken] = useState<string>()
  const navigate = useNavigate()

  return (
    <>
      {currentStep === 1 && (
        <PasswordResetRequestForm
          onComplete={(email) => {
            setCurrentStep(2)
            setEmail(email)
          }}
        />
      )}
      {currentStep === 2 && !!email && (
        <PasswordResetCodeVerificationForm
          email={email}
          onComplete={(token: string) => {
            setToken(token)
            setCurrentStep(3)
          }}
        />
      )}
      {currentStep === 3 && token && (
        <PasswordResetForm token={token} onComplete={() => navigate('/auth/login')} />
      )}
    </>
  )
}

/**
 * Form to enter in a user email and trigger the password reset email
 */
const PasswordResetRequestForm = ({onComplete}: {onComplete: (email: string) => void}) => {
  const formSchema = z.object({
    email: z
      .string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .refine((value) => value.toLowerCase() === value, {
        message: 'Email should be in lowercase',
      }),
  })
  type FormSchemaType = z.infer<typeof formSchema>

  const {
    register,
    handleSubmit,
    formState: {errors, isValid, isSubmitting},
  } = useForm<FormSchemaType>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(formSchema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    const response = await resetPasswordRequest(data.email)
    if (response.status === 200) {
      onComplete(data.email)
      return
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader
        title='Password Reset'
        subtitle="Enter your email address, which you use to login. We'll send the password reset code."
      />
      <TextField
        fullWidth
        placeholder='Email'
        variant='outlined'
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email && errors.email?.message}
      />
      <FormFooter isLoading={isSubmitting} allowSubmit={isValid} />
    </form>
  )
}

/**
 * Form to enter in the password Reset Code sent to the users email and verify it is valid
 */
const PasswordResetCodeVerificationForm = ({
  email,
  onComplete,
}: {
  email: string
  onComplete: (token: string) => void
}) => {
  const formSchema = z.object({
    resetCode: z.string().min(6, 'Minimum 6 digits').max(6, 'Maximum 6 digits'),
  })

  type FormSchemaType = z.infer<typeof formSchema>

  const {
    register,
    handleSubmit,
    formState: {errors, isValid, isSubmitting},
  } = useForm<FormSchemaType>({
    defaultValues: {resetCode: ''},
    resolver: zodResolver(formSchema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    const response = await resetPasswordVerify(email, data.resetCode)
    if (response.status === 200) {
      onComplete(response.data.token)
      return
    }
    toast.error('Invalid code!')
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader
        title='Password Reset Verification'
        subtitle='Use the code you received via email from us.'
      />
      <TextField
        fullWidth
        placeholder='Reset Code'
        variant='outlined'
        {...register('resetCode')}
        error={!!errors.resetCode}
        helperText={errors.resetCode && errors.resetCode?.message}
      />
      <FormFooter isLoading={isSubmitting} allowSubmit={isValid} />
    </form>
  )
}

/**
 * Form to enter in a new password and set it as the users password
 */
const PasswordResetForm = ({token, onComplete}: {token: string; onComplete: () => void}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const formSchema = z
    .object({
      password: z
        .string()
        .min(8, 'Password must have at least 8 characters')
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          'Password must contain at least one lowercase, one uppercase, one digit, and one special character'
        ),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: 'Passwords do not match',
      path: ['confirmPassword'],
    })

  type FormSchemaType = z.infer<typeof formSchema>

  const {
    register,
    handleSubmit,
    formState: {errors, isValid, isSubmitting},
  } = useForm<FormSchemaType>({
    defaultValues: {password: '', confirmPassword: ''},
    resolver: zodResolver(formSchema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    const response = await resetPassword(data.password, token)
    if (response.status === 200) {
      toast.success('Your password has been reset successfully')
      onComplete()
      return
    }
  }
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader title='Password Reset Verification' subtitle='Please enter your new password.' />

      <Grid container direction='column' spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder='Password'
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password && errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleTogglePassword} edge='end'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant='outlined'
            placeholder='Confirm Password'
            type={showConfirmPassword ? 'text' : 'password'}
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword && errors.confirmPassword?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleToggleConfirmPassword} edge='end'>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <FormFooter isLoading={isSubmitting} allowSubmit={isValid} />
    </form>
  )
}

/**
 * Header for the password reset forms
 */
const FormHeader = ({title, subtitle}: {title: string; subtitle: string}) => {
  return (
    <>
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>{title}</h1>
        <div className='fs-7 text-white'>{subtitle}</div>
      </div>
    </>
  )
}

/**
 * Footer for the password reset forms
 */
const FormFooter = ({isLoading, allowSubmit}: {isLoading: Boolean; allowSubmit: Boolean}) => {
  return (
    <Stack spacing={4} sx={{mt: 4}}>
      <Button
        type='submit'
        variant='contained'
        fullWidth
        size='large'
        // disabled={!isValid || isSubmitting}
      >
        {isLoading ? (
          <Typography textTransform={'none'} align='center'>
            {' '}
            Please Wait... <CircularProgress size={15} color='inherit' />
          </Typography>
        ) : (
          'Submit'
        )}
      </Button>
      <Link to='/auth/login' className='text-orange text-center'>
        Cancel
      </Link>
    </Stack>
  )
}
