/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetAlertHistoryByIdResponse } from '../models/ApiResponseOfGetAlertHistoryByIdResponse';
import type { ApiResponseOfGetPaginatedAlertHistoryResponse } from '../models/ApiResponseOfGetPaginatedAlertHistoryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AlertHistoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param page 
     * @param limit 
     * @returns ApiResponseOfGetPaginatedAlertHistoryResponse 
     * @throws ApiError
     */
    public getAlertHistory(
page?: number | null,
limit?: number | null,
): CancelablePromise<ApiResponseOfGetPaginatedAlertHistoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/alert-history/',
            query: {
                'page': page,
                'limit': limit,
            },
        });
    }

    /**
     * @param alertHistoryId 
     * @returns ApiResponseOfGetAlertHistoryByIdResponse 
     * @throws ApiError
     */
    public getAlertHistoryById(
alertHistoryId: string,
): CancelablePromise<ApiResponseOfGetAlertHistoryByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/alert-history/{alertHistoryId}',
            path: {
                'alertHistoryId': alertHistoryId,
            },
        });
    }

}
