import {Column, Table} from '@tanstack/react-table'
import DebouncedInput from '../DebouncedInput'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'

const SimpleColumnFilter = ({column, table}: {column: Column<any, unknown>; table: Table<any>}) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <div className='relative flex w-full items-center'>
      <div className='absolute left-2 flex h-full items-center'>
        <SearchIcon className=' text-table-lightgray' />
      </div>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder='Search...'
        className='w-full bg-transparent p-2 px-8 ml-1'
        list={column.id + 'list'}
      />
      {column.getFilterValue() !== undefined && column.getFilterValue() !== '' && (
        <div className='absolute right-2 flex h-full items-center'>
          <CancelIcon
            onClick={() => column.setFilterValue('')}
            className=' cursor-pointer text-table-lightgray'
          />
        </div>
      )}
    </div>
  )
}

export default SimpleColumnFilter
