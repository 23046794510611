import {DefaultEventsMap} from '@socket.io/component-emitter'
import {Socket, io} from 'socket.io-client'

const sockerUrl = process.env.REACT_APP_SOCKET_URL

let _socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined

export const getSocket = () => {
  if (_socket) return _socket
  if (!sockerUrl) {
    throw new Error('Socket url is not set')
  }
  const token = localStorage.getItem('tappToken')
    ? JSON.parse(localStorage.getItem('tappToken') || '')
    : undefined
  if (!token) throw new Error('User not logged in when socket connection attempted')
  _socket = io(sockerUrl, {
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    auth: {token: token.api_token},
  })

  return _socket
}

export const disconnectSocket = () => {
  _socket?.disconnect()
  _socket = undefined
}
