import {createSlice} from '@reduxjs/toolkit'
import {
  _getCurrentFacilityConfig,
  _getContactGroup,
  _updateCurrentFacilityConfig,
  _getCellComplianceRules,
  _deleteCellComplianceRules,
  _createCellComplianceRules,
  _updateCellComplianceRules,
  _updateContactGroup,
  _deleteContactGroup,
  _createContactGroup,
  _updateEscalationProtocol,
  _createEscalationProtocol,
  _deleteEscalationProtocol,
  _getEscalationProtocols,
  _getContacts,
  _updateContact,
  _deleteContact,
  _createContact,
} from './facilityConfig.actions'
import {addAPICases, addUpdateCases} from '../api.helpers'
import {ApiRequestState, apiRequestStateDefault} from '../api.types'
import {
  FacilityContactGroup,
  FacilityConfiguration,
  GetCellComplianceRulesResponse,
  GetAlertEscalationProtocolResponse,
  GetFacilityContactsResponse,
} from '../openapi'

/*--------------------- Facility Configuration ------------------------*/

type FacilityConfigType = {
  config: ApiRequestState<FacilityConfiguration>
  contactGroup: ApiRequestState<FacilityContactGroup[]>
  complianceRules: ApiRequestState<GetCellComplianceRulesResponse>
  escalationProtocols: ApiRequestState<GetAlertEscalationProtocolResponse>
  contacts: ApiRequestState<GetFacilityContactsResponse>
}

const initialState: FacilityConfigType = {
  config: {...apiRequestStateDefault},
  contactGroup: {...apiRequestStateDefault},
  complianceRules: {...apiRequestStateDefault},
  escalationProtocols: {...apiRequestStateDefault},
  contacts: {...apiRequestStateDefault},
}

export const facilityConfig = createSlice({
  name: 'facilityConfig',
  initialState,
  extraReducers: (builder) => {
    addAPICases(builder, _getCurrentFacilityConfig, 'config')
    addAPICases(builder, _updateCurrentFacilityConfig, 'config')

    addAPICases(builder, _getContactGroup, 'contactGroup')
    addUpdateCases(
      builder,
      _createContactGroup,
      _updateContactGroup,
      _deleteContactGroup,
      'contactGroup'
    )

    addAPICases(builder, _getCellComplianceRules, 'complianceRules')
    addUpdateCases(
      builder,
      _createCellComplianceRules,
      _updateCellComplianceRules,
      _deleteCellComplianceRules,
      'complianceRules'
    )

    addAPICases(builder, _getEscalationProtocols, 'escalationProtocols')
    addUpdateCases(
      builder,
      _createEscalationProtocol,
      _updateEscalationProtocol,
      _deleteEscalationProtocol,
      'escalationProtocols'
    )

    addAPICases(builder, _getContacts, 'contacts')
    addUpdateCases(builder, _createContact, _updateContact, _deleteContact, 'contacts')
  },
  reducers: {},
})

export const facilityConfigReducer = facilityConfig.reducer
