import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import {orderBy} from 'lodash'
import {useEffect, useState, MouseEvent} from 'react'
import {
  _getCurrentFacility,
  _getFacilityPods,
  _getPods,
} from '../../../state/V2/facility/facility.actions'
import {_saveUserPods} from '../../../state/V2/users/users.actions'
import {isApiError} from '../../../state/V2/api.helpers'
import {useAuth} from '../../modules/auth'
import {UserPodsUpdateBody} from '../../../state/V2/openapi'
import {_getBuildings} from '../../../state/V2/facility-ops/facilityOps.actions'
import {_getParticipants} from '../../../state/V2/participant/participant.actions'
import {useAppDispatch, useAppSelector} from '../../../state/hooks'
import {_getCurrentFacilityConfig} from '../../../state/V2/facility-configuration/facilityConfig.actions'

interface IPodSelection {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const PodSelection = ({isShown, setIsShown}: IPodSelection) => {
  const dispatch = useAppDispatch()
  const [errorText, setErrorText] = useState('')
  const [selectedPods, setSelectedPods] = useState<number[]>([])
  const [allPodsSelected, setAllPodsSelected] = useState<boolean>(false)
  const currentFacility = useAppSelector((state) => state.facility.current.data)
  const currentFacilityConfig = useAppSelector((state) => state.facilityConfig.config.data)
  const [selectedFacility, setSelectedFacility] = useState<number>()

  const facilities = useAppSelector((state) => state.facility.list.data)
  const pods = useAppSelector((state) => state.pods.facilityList.data?.results)
  const podAssignment = useAppSelector((state) => state.users.podAssignment.data)
  const {currentUser} = useAuth()

  // Get the current facility data if it has not already be retrieved
  useEffect(() => {
    if (!currentFacility) dispatch(_getCurrentFacility())
  }, [dispatch, currentFacility])

  // Get the current facility config data if it has not already be retrieved
  useEffect(() => {
    if (!currentFacilityConfig) dispatch(_getCurrentFacilityConfig())
  }, [dispatch, currentFacilityConfig])

  // Set the current pod assignments as the selected inputs
  useEffect(() => {
    if (!podAssignment) return
    if (!selectedFacility && podAssignment.facilityId) {
      setSelectedFacility(podAssignment.facilityId)
    }
    setSelectedPods([...podAssignment.podIds])
  }, [facilities, dispatch, podAssignment, selectedFacility])

  const handlePodChange = (event: SelectChangeEvent<number[]>) => {
    if (allPodsSelected) {
      setAllPodsSelected(false)
    }
    if (typeof event.target.value !== 'string') setSelectedPods(event.target.value)
  }

  const handleSelectAllPods = () => {
    if (pods) setSelectedPods(pods.map((pod) => pod.id))
  }

  const handleUnselectAllPods = () => {
    setSelectedPods([])
  }

  const handleClose = () => {
    setIsShown(false)
    setSelectedFacility(podAssignment?.facilityId!)
    dispatch(_getFacilityPods({facilityId: podAssignment?.facilityId!}))
    setSelectedPods([...podAssignment!.podIds])
  }

  const onSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const data: UserPodsUpdateBody = {
      podIds: selectedPods,
      facilityId: selectedFacility || 0,
    }

    const response = await dispatch(_saveUserPods(data))
    if (isApiError(response)) {
      setErrorText('Failed to save Pod Selection.')
      return
    }
    await dispatch(_getPods({})) // get the new facility pods list
    await dispatch(_getParticipants({})) // Refresh participants data with new facility & assignment
    await dispatch(_getBuildings({}))
    await dispatch(_getCurrentFacility())
    await dispatch(_getCurrentFacilityConfig())

    setIsShown(false)
  }

  const handleFacilityChange = async (e: SelectChangeEvent<number>) => {
    setSelectedFacility(e.target.value as number)
    await dispatch(_getFacilityPods({facilityId: e.target.value as number}))
    setSelectedPods([])
  }

  const isSuperuser = currentUser?.isSuper

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={isShown}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogTitle sx={{fontSize: 16}}>Pod Selection</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          sx={{'& .MuiTextField-root': {m: 1, width: '32.5ch'}}}
          noValidate
          autoComplete='off'
        >
          {isSuperuser ? (
            <FormControl error={errorText ? true : false} style={{width: '100%'}} className='my-4'>
              <InputLabel> Select Facility</InputLabel>
              <Select<number>
                labelId='facility-label'
                id='facility'
                variant='standard'
                label='Select Facility'
                input={<OutlinedInput label='Select Facility' />}
                MenuProps={MenuProps}
                onChange={handleFacilityChange}
                value={selectedFacility || ''}
              >
                {facilities
                  ? orderBy(facilities, ['facilityName'], ['asc']).map((data, i) => (
                      <MenuItem key={i} value={data.id}>
                        <ListItemText primary={data.facilityName} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
              <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
          ) : null}
          <FormControl error={errorText ? true : false} style={{width: '100%'}} className='my-4'>
            <InputLabel> Select Pods</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              variant='standard'
              multiple
              value={selectedPods}
              onChange={handlePodChange}
              input={<OutlinedInput label='Select Pod' />}
              renderValue={(selected) =>
                pods
                  ?.filter((pod) => selected.includes(pod.id))
                  .map((pod) => pod.podName)
                  .join(', ')
              }
              MenuProps={MenuProps}
              disabled={!selectedFacility && isSuperuser}
            >
              {pods
                ? orderBy(pods, ['podName'], ['asc']).map((data, i) => (
                    <MenuItem key={i} value={data.id}>
                      <Checkbox checked={selectedPods.includes(data.id)} />
                      <ListItemText primary={data.podName} />
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errorText}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions className='justify-content-between flex p-7'>
        {pods?.length === selectedPods.length ? (
          <Button variant='outlined' onClick={handleUnselectAllPods}>
            Unselect All
          </Button>
        ) : (
          <Button variant='outlined' onClick={handleSelectAllPods}>
            Select All
          </Button>
        )}

        <div>
          <Button onClick={handleClose} className='mr-2'>
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={
              (isSuperuser && pods?.length! > 0 && selectedPods.length === 0) ||
              (!isSuperuser && selectedPods.length === 0)
            }
            className='ml-10 block'
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
