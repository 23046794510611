import {combineReducers} from 'redux'
import {authenticationReducer} from './authentication/authentication.reducers'
import devicesReducer from './device/device.reducers'
import {participantReducer} from './participant/participant.reducer'
import {
  facilityReducer,
  podsReducer,
  cellsReducer,
  locationsReducer,
} from './facility/facility.reducers'
import usersReducers from './users/users.reducers'
import {alertReducer} from './alerts/alerts.reducers'
import {facilityOpsReducer} from './facility-ops/facilityOps.reducers'
import {historicalReducer} from './historical/historical.reducers'
import {facilityConfigReducer} from './facility-configuration/facilityConfig.reducers'

const reducers = combineReducers({
  authentication: authenticationReducer,
  participant: participantReducer,
  pods: podsReducer,
  devices: devicesReducer,
  locations: locationsReducer,
  users: usersReducers,
  cells: cellsReducer,
  alerts: alertReducer,
  facility: facilityReducer,
  facilityOps: facilityOpsReducer,
  historical: historicalReducer,
  facilityConfig: facilityConfigReducer,
})

const rootReducer = (state: any, action: {type: string}) => {
  if (action.type === 'user/_logout/fulfilled') {
    return reducers(undefined, action)
  }

  return reducers(state, action)
}

export default rootReducer
