import {createSlice, current} from '@reduxjs/toolkit'
import {
  _createUser,
  _getUserById,
  _getUserPods,
  _getUsers,
  _saveUserPods,
  _updateUser,
} from './users.actions'
import {
  ApiPaginationRequestState,
  ApiRequestState,
  apiRequestPaginationStateDefault,
  apiRequestStateDefault,
} from '../api.types'
import {User, UserPodAssignment} from '../openapi'
import {addAPICases, setApiState} from '../api.helpers'

type UsersState = {
  list: ApiPaginationRequestState<User[]>
  created: ApiRequestState<User>
  updated: ApiRequestState<User>
  selected: ApiRequestState<User>
  podAssignment: ApiRequestState<UserPodAssignment>
  // supervisorInfo?: SupervisorType
}
const initialState: UsersState = {
  list: {...apiRequestPaginationStateDefault},
  created: {...apiRequestStateDefault},
  updated: {...apiRequestStateDefault},
  selected: {...apiRequestStateDefault},
  podAssignment: {...apiRequestStateDefault},
}

const supervisorSlice = createSlice({
  name: 'supervisor',
  initialState,
  extraReducers: (builder) => {
    addAPICases(builder, _getUsers, 'list')
    addAPICases(builder, _getUserPods, 'podAssignment')
    addAPICases(builder, _saveUserPods, 'podAssignment')
    addAPICases(builder, _getUserById, 'selected')
    addAPICases(builder, _createUser, 'created', {
      onFulfilled: (state, action) => {
        if (action.payload.data !== undefined) {
          state.list.data.results = [...current(state.list.data.results || []), action.payload.data]
        }
      },
    })
    addAPICases(builder, _updateUser, 'updated', {
      onFulfilled: (state, action) => {
        if (action.payload.data !== undefined) {
          setApiState(state.updated, action)
          state.list.data.results = current(state.list.data.results || []).map((user) =>
            user.id === action.meta.arg.id ? {...user, ...action.payload.data} : user
          )
        }
      },
    })
  },
  reducers: {},
})

export default supervisorSlice.reducer
