import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {GetPaginatedUsersBody, UserCreateBody, UserPodsUpdateBody, UserUpdateBody} from '../openapi'

/*--------------------- Supervisor ------------------------*/
export interface SupervisorState {
  id?: number
  first_name?: string
  last_name?: string
  street?: string
  city?: string
  state?: string
  zip_code?: string
  email?: string
  password?: string
  phone?: string
  role_type?: string
  isEdit?: boolean
  facility_id?: number
}

export const _getUsers: any = createAsyncThunk(
  '_getSupervisors',
  async (params: GetPaginatedUsersBody) => {
    return api.users.getPaginatedUsersFiltered(params)
    // const response: SupervisorListResponse = await request({
    //   url: `v2/supervisor?include=updatedBy,createdBy`,
    //   method: 'get',
    //   params,
    // })
    // return response
  }
)

export const _createUser = createAsyncThunk('supervisor/_create', async (data: UserCreateBody) => {
  return api.users.createUser(data)
})

export const _updateUser = createAsyncThunk(
  'v2/supervisor/_update',
  async ({id, data}: {id: number; data: UserUpdateBody}) => {
    return api.users.updateUser(id.toString(), data)
  }
)

export const _getUserById: any = createAsyncThunk('_getSupervisorsById', async (id: number) => {
  return api.users.getUserById(id.toString())
})

/*--------------------- User Pods ------------------------*/

export const _getUserPods = createAsyncThunk('_getUserPods', async () => {
  return api.userPods.getUserPods()
})

export const _saveUserPods = createAsyncThunk('_saveUserPods', async (data: UserPodsUpdateBody) => {
  return api.userPods.updateUserPods(data)
})
