import {ReactNode, RefObject, createContext, useCallback, useContext, useState} from 'react'
import {PrecisionMode, PrecisionModeType} from '../../../../state/V2/system/system.types'
import {ReactZoomPanPinchRef} from 'react-zoom-pan-pinch'
import {Participant} from '../../../../state/V2/openapi'
import {isEmpty} from 'lodash'

export type Coordinates = {
  x: number
  y: number
  z: number
}

export type ParticipantMapData = {
  participant: Participant
  coordinates: Coordinates | null
  displayCoordinates: Coordinates | null
  visible: boolean
}

type Map2DState = {
  scaleUp: boolean
  conversionScale: number
  zoomFactor: number
  precisionMode: PrecisionModeType
  containerWidth: number
  containerHeight: number
  svgScale: number
  svgWidth: number
  svgHeight: number
  svgViewport: string
  areaOccupancy: number
  showRiskValues: number[]
  showPodIds: number[]
  showGhosts: boolean
  showImei: boolean
  zone: string
  darkMode: boolean
  selectedInmateId: number | null
  transformComponentRef?: RefObject<ReactZoomPanPinchRef>
  participants: ParticipantMapData[]
  useTransitions: boolean
  buildingId?: number
  floorId?: number
  floorLabel?: string
}

export const defaultMap2DState: Map2DState = {
  scaleUp: true,
  conversionScale: 50, // Pixel to unit conversion for maps 43.81471861866724 to 1 unit from ls1000
  zoomFactor: 8,
  svgScale: 1,
  precisionMode: PrecisionMode.TRIANGULATION,
  containerWidth: 0,
  containerHeight: 0,
  svgWidth: 1921.42,
  svgHeight: 841.48,
  svgViewport: '0 0 1921.42 841.48',
  areaOccupancy: 0,
  showRiskValues: [1, 2, 3],
  showPodIds: [],
  showImei: false,
  showGhosts: true,
  zone: 'Reset',
  darkMode: true,
  selectedInmateId: null,
  participants: [],
  useTransitions: true,
}

const Map2DContext = createContext<
  [Map2DState, (update: Partial<Map2DState> | ((state: Map2DState) => Partial<Map2DState>)) => void]
>([defaultMap2DState, () => {}])

const Map2DProvider = ({children}: {children?: ReactNode}) => {
  const [map2DState, setMap2DState] = useState(defaultMap2DState)

  const updateMap2DState = useCallback(
    (update: Partial<Map2DState> | ((state: Map2DState) => Partial<Map2DState>)) => {
      setMap2DState((prev) => {
        if (typeof update === 'function') update = update(prev)
        if (isEmpty(update)) return prev
        return {...prev, ...update}
      })
    },
    []
  )

  return (
    <Map2DContext.Provider value={[map2DState, updateMap2DState]}>{children}</Map2DContext.Provider>
  )
}

export const useMap2DState = () => useContext(Map2DContext)

export default Map2DProvider
