/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetLocationCoordinatesResponse } from '../models/ApiResponseOfGetLocationCoordinatesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LocationCoordinatesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetLocationCoordinatesResponse 
     * @throws ApiError
     */
    public getLocationCoordinates(): CancelablePromise<ApiResponseOfGetLocationCoordinatesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/location-coordinates/',
        });
    }

}
