/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfCellCreateResponse } from '../models/ApiResponseOfCellCreateResponse';
import type { ApiResponseOfCellUpdateResponse } from '../models/ApiResponseOfCellUpdateResponse';
import type { ApiResponseOfDeleteCellResponse } from '../models/ApiResponseOfDeleteCellResponse';
import type { ApiResponseOfGetCellByIdResponse } from '../models/ApiResponseOfGetCellByIdResponse';
import type { ApiResponseOfGetPaginatedCellsResponse } from '../models/ApiResponseOfGetPaginatedCellsResponse';
import type { CellCreateBody } from '../models/CellCreateBody';
import type { CellUpdateBody } from '../models/CellUpdateBody';
import type { GetPaginatedCellsBody } from '../models/GetPaginatedCellsBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CellsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedCellsFiltered
     * @param page 
     * @param limit 
     * @param podId 
     * @param sortBy 
     * @param sortDir 
     * @param filters 
     * @returns ApiResponseOfGetPaginatedCellsResponse 
     * @throws ApiError
     */
    public getPaginatedCells(
page?: number | null,
limit?: number | null,
podId?: number | null,
sortBy?: 'id' | 'cellNumber' | 'podName' | 'doorOpen',
sortDir?: 'asc' | 'desc',
filters?: ({
operation: 'eq' | 'neq' | 'contains' | 'not-contains' | 'starts-with' | 'ends-with';
value: string | null;
field: 'cellNumber' | 'podName';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: number | null;
valueTo?: number | null;
field: 'id';
} | {
operation: 'eq' | 'neq';
value: (boolean | string);
field: 'doorOpen';
}),
): CancelablePromise<ApiResponseOfGetPaginatedCellsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell/',
            query: {
                'page': page,
                'limit': limit,
                'podId': podId,
                'sortBy': sortBy,
                'sortDir': sortDir,
                'filters': filters,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfCellCreateResponse 
     * @throws ApiError
     */
    public createCell(
requestBody: CellCreateBody,
): CancelablePromise<ApiResponseOfCellCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/cell/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedCellsResponse 
     * @throws ApiError
     */
    public getPaginatedCellsFiltered(
requestBody: GetPaginatedCellsBody,
): CancelablePromise<ApiResponseOfGetPaginatedCellsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/cell/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param cellId 
     * @returns ApiResponseOfGetCellByIdResponse 
     * @throws ApiError
     */
    public getCellById(
cellId: string,
): CancelablePromise<ApiResponseOfGetCellByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell/{cellId}',
            path: {
                'cellId': cellId,
            },
        });
    }

    /**
     * @param cellId 
     * @param requestBody 
     * @returns ApiResponseOfCellUpdateResponse 
     * @throws ApiError
     */
    public updateCell(
cellId: string,
requestBody: CellUpdateBody,
): CancelablePromise<ApiResponseOfCellUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/cell/{cellId}',
            path: {
                'cellId': cellId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param cellId 
     * @returns ApiResponseOfDeleteCellResponse 
     * @throws ApiError
     */
    public deleteCell(
cellId: string,
): CancelablePromise<ApiResponseOfDeleteCellResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/cell/{cellId}',
            path: {
                'cellId': cellId,
            },
        });
    }

}
