import {ApiResponse} from '../api.types'

export const PrecisionMode = Object.freeze({
  PROXIMITY: 'PROXIMITY',
  TRIANGULATION: 'TRIANGULATION',
})

export type PrecisionModeType = (typeof PrecisionMode)[keyof typeof PrecisionMode]

export type SystemSettingsRequestResponse = ApiResponse<SystemSettingsType>

//User Login Auth Details
export type SystemSettingsType = {
  precisionMode: PrecisionModeType
}
