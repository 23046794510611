/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetAccessTypesResponse } from '../models/ApiResponseOfGetAccessTypesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccessTypeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * @returns ApiResponseOfGetAccessTypesResponse 
     * @throws ApiError
     */
    public getAccessTypes(): CancelablePromise<ApiResponseOfGetAccessTypesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/access-type/',
        });
    }

}
