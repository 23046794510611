import {createAsyncThunk} from '@reduxjs/toolkit'
import {api, publicApi} from '../api.helpers'
import {Api, GetAuthTokenBody} from '../openapi'


export const _login = createAsyncThunk('user/_login', async (data: GetAuthTokenBody) => {
  return publicApi.authentication.getAuthToken(data)
})

export const _logout = createAsyncThunk('user/_logout', async () => {
  return {}
})

export const _getRoles = createAsyncThunk('roles/_list', async () => {
  return api.userRole.getUserRoles()
})

export const resetPasswordRequest = async (email: string) => {
  return publicApi.authentication.passwordReset({email})
}

export const resetPasswordVerify = async (email: string, resetCode: string) => {
  return publicApi.authentication.passwordResetCodeCheck({email, resetCode})
}

export const resetPassword = async (password: string, token: string) => {
  const tempApi = new Api({
    BASE: process.env.REACT_APP_API_URL,
    HEADERS: {Authorization: `Bearer ${token}`},
  })
  return tempApi.authentication.updatePassword({password})
}
