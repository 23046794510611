/*--------------------- Facility ------------------------*/

import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {GetPaginatedBuildingFloorsBody, GetPaginatedBuildingsBody} from '../openapi'

export const _getBuildings = createAsyncThunk(
  'facilityOps/_buildings',
  async (data: GetPaginatedBuildingsBody) => {
    data = {page: 1, limit: 1000, ...data}
    return await api.buildings.getPaginatedBuildingsFiltered(data)
  }
)

export const createBuilding = async (facilityId: number, name: string, label: string) => {
  return await api.buildings.createBuilding({
    facilityId,
    name,
    label,
  })
}

export const updateBuilding = async (obj: {id: number; name: string; label: string}) => {
  const {id, ...data} = obj
  return await api.buildings.updateBuilding(id.toString(), data)
}

export const deleteBuilding = async (id: number) => {
  return await api.buildings.deleteBuilding(id.toString())
}

export const _getBuildingById = createAsyncThunk(
  'facilityOps/_currentBuilding',
  async (id: number) => {
    return await api.buildings.getBuildingById(id.toString())
  }
)

export const _getFloors = createAsyncThunk(
  'facilityOps/_floors',
  async (data: GetPaginatedBuildingFloorsBody) => {
    data = {page: 1, limit: 1000, ...data}
    return await api.buildingFloors.getPaginatedBuildingFloorsFiltered(data)
  }
)

export const createFloor = async (buildingId: number, name: string, label: string) => {
  return await api.buildingFloors.createBuildingFloor({buildingId, name, label})
}

export const updateFloor = async (obj: {id: number; name: string}) => {
  const {id, ...data} = obj
  return await api.buildingFloors.updateBuildingFloor(id.toString(), data)
}

export const deleteFloor = async (id: number) => {
  return await api.buildingFloors.deleteBuildingFloor(id.toString())
}

export const deleteCell = async (id: number) => {
  return await api.cells.deleteCell(id.toString())
}

export const _getPodInfo = createAsyncThunk('facilityOps/_podInfo', async (podId: number) => {
  return await api.pods.getPodById(podId.toString())
})
