/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetCellHistoryDurationResponse } from '../models/ApiResponseOfGetCellHistoryDurationResponse';
import type { ApiResponseOfGetPaginatedCellHistoryResponse } from '../models/ApiResponseOfGetPaginatedCellHistoryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CellHistoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param cellId 
     * @param page 
     * @param limit 
     * @param facilityId 
     * @param participantId 
     * @param startDatetime 
     * @param endDatetime 
     * @returns ApiResponseOfGetPaginatedCellHistoryResponse 
     * @throws ApiError
     */
    public getCellHistory(
cellId: string,
page?: number | null,
limit?: number | null,
facilityId?: number | null,
participantId?: number | null,
startDatetime?: string | null,
endDatetime?: string | null,
): CancelablePromise<ApiResponseOfGetPaginatedCellHistoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell-history/{cellId}',
            path: {
                'cellId': cellId,
            },
            query: {
                'page': page,
                'limit': limit,
                'facilityId': facilityId,
                'participantId': participantId,
                'startDatetime': startDatetime,
                'endDatetime': endDatetime,
            },
        });
    }

    /**
     * @param cellId 
     * @param startDatetime 
     * @param endDatetime 
     * @param participantId 
     * @returns ApiResponseOfGetCellHistoryDurationResponse 
     * @throws ApiError
     */
    public getCellHistoryDuration(
cellId: string,
startDatetime?: string | null,
endDatetime?: string | null,
participantId?: number | null,
): CancelablePromise<ApiResponseOfGetCellHistoryDurationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/cell-history/{cellId}/duration',
            path: {
                'cellId': cellId,
            },
            query: {
                'startDatetime': startDatetime,
                'endDatetime': endDatetime,
                'participantId': participantId,
            },
        });
    }

}
