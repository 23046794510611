/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfDeleteUserResponse } from '../models/ApiResponseOfDeleteUserResponse';
import type { ApiResponseOfGetPaginatedUsersResponse } from '../models/ApiResponseOfGetPaginatedUsersResponse';
import type { ApiResponseOfGetUserByIdResponse } from '../models/ApiResponseOfGetUserByIdResponse';
import type { ApiResponseOfUserCreateResponse } from '../models/ApiResponseOfUserCreateResponse';
import type { ApiResponseOfUserUpdateResponse } from '../models/ApiResponseOfUserUpdateResponse';
import type { GetPaginatedUsersBody } from '../models/GetPaginatedUsersBody';
import type { UserCreateBody } from '../models/UserCreateBody';
import type { UserUpdateBody } from '../models/UserUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param page 
     * @param limit 
     * @param facilityId 
     * @param sortBy 
     * @param sortDir 
     * @param filters 
     * @returns ApiResponseOfGetPaginatedUsersResponse 
     * @throws ApiError
     */
    public getPaginatedUsers(
page?: number | null,
limit?: number | null,
facilityId?: number | null,
sortBy?: 'id' | 'firstName' | 'lastName' | 'street' | 'zipCode' | 'email' | 'phone' | 'state' | 'city' | 'roleTypeId',
sortDir?: 'asc' | 'desc',
filters?: ({
operation: 'eq' | 'neq' | 'contains' | 'not-contains' | 'starts-with' | 'ends-with';
value: string | null;
field: 'firstName' | 'lastName' | 'street' | 'zipCode' | 'email' | 'phone' | 'state' | 'city' | 'roleType';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: number | null;
valueTo?: number | null;
field: 'id';
}),
): CancelablePromise<ApiResponseOfGetPaginatedUsersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/user/',
            query: {
                'page': page,
                'limit': limit,
                'facilityId': facilityId,
                'sortBy': sortBy,
                'sortDir': sortDir,
                'filters': filters,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfUserCreateResponse 
     * @throws ApiError
     */
    public createUser(
requestBody: UserCreateBody,
): CancelablePromise<ApiResponseOfUserCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/user/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedUsersResponse 
     * @throws ApiError
     */
    public getPaginatedUsersFiltered(
requestBody: GetPaginatedUsersBody,
): CancelablePromise<ApiResponseOfGetPaginatedUsersResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/user/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId 
     * @returns ApiResponseOfGetUserByIdResponse 
     * @throws ApiError
     */
    public getUserById(
userId: string,
): CancelablePromise<ApiResponseOfGetUserByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/user/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns ApiResponseOfUserUpdateResponse 
     * @throws ApiError
     */
    public updateUser(
userId: string,
requestBody: UserUpdateBody,
): CancelablePromise<ApiResponseOfUserUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/user/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId 
     * @returns ApiResponseOfDeleteUserResponse 
     * @throws ApiError
     */
    public deleteUser(
userId: string,
): CancelablePromise<ApiResponseOfDeleteUserResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/user/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

}
