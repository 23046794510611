import React, {useRef} from 'react'
import InmateMarker from './InmateMarker'
// import LocationOverlay from './LocationOverlay'
import {useMap2DState} from './Map2DContext'

export const InmateMarkerLayer = () => {
  const [{showGhosts, showImei, darkMode, participants, svgViewport}, updateMap2DState] =
    useMap2DState()

  const svgRef = useRef<any>(null)

  const handleClick = (e: React.MouseEvent<SVGElement>) => {
    const id = (e.target as SVGElement).getAttribute('id')
    if (!id) return
    updateMap2DState({selectedInmateId: parseInt(id)})
  }

  return (
    <div className='absolute bottom-0 left-0 right-0 top-0 z-[100]'>
      <svg
        ref={svgRef}
        width='100%'
        height='100%'
        // Match ls1000 in width and height
        // Max width and height of facility
        viewBox={svgViewport}
        onClick={(e) => {
          const id = (e.target as SVGElement).getAttribute('id')
          if (!id) return
          updateMap2DState({selectedInmateId: parseInt(id)})
        }}
      >
        {/* Only uncomment below for development */}
        {/* <LocationOverlay /> */}
        {participants?.map(({participant, coordinates, displayCoordinates, visible}) => {
          if (!visible) return null

          return (
            <InmateMarker
              key={participant.id}
              participant={participant}
              coordinates={coordinates}
              displayCoordinates={displayCoordinates || coordinates}
              onClick={handleClick}
              showImei={showImei}
              showGhosts={showGhosts}
              svgBackgroundWhite={!darkMode}
            />
          )
        })}
      </svg>
    </div>
  )
}
