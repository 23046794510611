/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfDeleteDeviceResponse } from '../models/ApiResponseOfDeleteDeviceResponse';
import type { ApiResponseOfDeviceCreateResponse } from '../models/ApiResponseOfDeviceCreateResponse';
import type { ApiResponseOfDeviceUpdateResponse } from '../models/ApiResponseOfDeviceUpdateResponse';
import type { ApiResponseOfGetDeviceByIdResponse } from '../models/ApiResponseOfGetDeviceByIdResponse';
import type { ApiResponseOfGetPaginatedDevicesResponse } from '../models/ApiResponseOfGetPaginatedDevicesResponse';
import type { DeviceCreateBody } from '../models/DeviceCreateBody';
import type { DeviceDeleteBody } from '../models/DeviceDeleteBody';
import type { DeviceUpdateBody } from '../models/DeviceUpdateBody';
import type { GetPaginatedDevicesBody } from '../models/GetPaginatedDevicesBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DeviceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedDevicesFiltered
     * @param page 
     * @param limit 
     * @param facilityId 
     * @param sortBy 
     * @param sortDir 
     * @param filters 
     * @returns ApiResponseOfGetPaginatedDevicesResponse 
     * @throws ApiError
     */
    public getPaginatedDevices(
page?: number | null,
limit?: number | null,
facilityId?: number | null,
sortBy?: 'id' | 'imei' | 'participantName' | 'facilityName' | 'active' | 'state' | 'installedDate' | 'uninstalledDate' | 'installedByName' | 'uninstalledByName',
sortDir?: 'asc' | 'desc',
filters?: ({
operation: 'eq' | 'neq' | 'contains' | 'not-contains' | 'starts-with' | 'ends-with';
value: string | null;
field: 'imei' | 'participantName' | 'facilityName' | 'installedByName' | 'uninstalledByName' | 'state';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: number | null;
valueTo?: number | null;
field: 'id';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: string | null;
valueTo?: string | null;
field: 'installedDate' | 'uninstalledDate';
} | {
operation: 'eq' | 'neq';
value: (boolean | string);
field: 'active';
}),
): CancelablePromise<ApiResponseOfGetPaginatedDevicesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/device/',
            query: {
                'page': page,
                'limit': limit,
                'facilityId': facilityId,
                'sortBy': sortBy,
                'sortDir': sortDir,
                'filters': filters,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfDeviceCreateResponse 
     * @throws ApiError
     */
    public createDevice(
requestBody: DeviceCreateBody,
): CancelablePromise<ApiResponseOfDeviceCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/device/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedDevicesResponse 
     * @throws ApiError
     */
    public getPaginatedDevicesFiltered(
requestBody: GetPaginatedDevicesBody,
): CancelablePromise<ApiResponseOfGetPaginatedDevicesResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/device/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param deviceId 
     * @returns ApiResponseOfGetDeviceByIdResponse 
     * @throws ApiError
     */
    public getDeviceById(
deviceId: string,
): CancelablePromise<ApiResponseOfGetDeviceByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
        });
    }

    /**
     * @param deviceId 
     * @param requestBody 
     * @returns ApiResponseOfDeviceUpdateResponse 
     * @throws ApiError
     */
    public updateDevice(
deviceId: string,
requestBody: DeviceUpdateBody,
): CancelablePromise<ApiResponseOfDeviceUpdateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param deviceId 
     * @param requestBody 
     * @returns ApiResponseOfDeleteDeviceResponse 
     * @throws ApiError
     */
    public deleteDevice(
deviceId: string,
requestBody: DeviceDeleteBody,
): CancelablePromise<ApiResponseOfDeleteDeviceResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/device/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
