import {
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React, {useState, FC, useEffect, useMemo} from 'react'
import TextField from '@mui/material/TextField'
import {useDispatch} from 'react-redux'
import {AppDispatch, RootState} from '../../../state/store'
import {getType} from '../../../helpers/general'
import {ALERTS_ACTION_OPTIONS, DETAILED_LONG_DATETIME, LONG_DATETIME} from '../../../helpers/config'
import {_getAlertById, _updateAlerts} from '../../../state/V2/alerts/alerts.actions'
import {createColumnHelper} from '@tanstack/react-table'
import DataTable from '../../components/datatable/DataTable'
import {useAuth} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {Alert, AlertNote} from '../../../state/V2/openapi'
import {DateTime} from 'luxon'

interface IProps {
  alert: Alert | null
  visible: boolean
  onClose: () => void
}

const columnHelper = createColumnHelper<AlertNote>()

export const AlertDetails: FC<IProps> = ({alert, visible, onClose}) => {
  const [note, setNote] = useState<string>(alert?.note ? alert?.note : '')
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [data, setData] = useState<any>([])
  const [riskOption, setRiskOption] = React.useState<string[]>([])
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const writeAccess = useAuth().hasPermission('alert.update')
  const selectedAlert = useSelector((state: RootState) => state.alerts.selected.data)
  const facilityConfig = useSelector((state: RootState) => state.facilityConfig.config.data)

  const handleAccept = async () => {
    const action = riskOption.join(', ')
    if (alert?.accepted) {
      await dispatch(
        _updateAlerts({
          id: alert?.id,
          data: {
            action: action,
            note: note,
          },
        })
      )
    } else {
      if (!alert?.id) return

      await dispatch(_updateAlerts({id: alert.id, data: {accepted: true}}))
      dispatch(_getAlertById(alert.id))
    }

    if (selectedAlert?.accepted) {
      handleClose()
    }
  }

  const handleAdditionalNotes = async () => {
    if (!alert?.id) return
    await dispatch(
      _updateAlerts({
        id: alert.id,
        data: {
          note: additionalNotes,
        },
      })
    )
    handleClose()
    setIsFormValid(false)
  }

  const handleClose = () => {
    onClose()
    setNote('')
    setIsFormValid(false)
    setRiskOption([])
  }

  useEffect(() => {
    const final = () => {
      if (alert && alert?.type?.toLowerCase() === 'high_heart_rate') {
        setData(ALERTS_ACTION_OPTIONS.highHeartRate)
      } else if (
        (alert && alert?.type?.toLowerCase() === 'low_heart_rate') ||
        (alert && alert?.type?.toLowerCase() === 'low heart rate')
      ) {
        setData(ALERTS_ACTION_OPTIONS.lowHeartRate)
      } else if (alert && alert?.type?.toLowerCase() === 'band_tamper') {
        setData(ALERTS_ACTION_OPTIONS.bandTamper)
      } else if (
        (alert && alert?.type?.toLowerCase() === 'low_battery_level') ||
        (alert && alert?.type?.toLowerCase() === 'low battery')
      ) {
        setData(ALERTS_ACTION_OPTIONS.lowBattery)
      } else if (alert && alert?.type?.toLowerCase() === 'heart_rate_offline') {
        setData(ALERTS_ACTION_OPTIONS.offline)
      } else if (alert && alert?.type?.toLowerCase() === 'band_offline') {
        setData(ALERTS_ACTION_OPTIONS.offline)
      } else if (alert && alert?.type?.toLowerCase() === 'out_of_cell_time_compliancy_warning') {
        setData(ALERTS_ACTION_OPTIONS.outOfCellTime)
      } else return []
    }
    final()
  }, [alert])

  useEffect(() => {
    if (alert?.id) {
      dispatch(_getAlertById(alert.id))
    }
  }, [alert?.id, dispatch])

  const handleChange = (event: SelectChangeEvent<typeof riskOption>) => {
    const {
      target: {value},
    } = event

    setRiskOption(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor('note', {
        header: () => 'Note',
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor(
        (data) => data?.createdByUser?.firstName + ' ' + data?.createdByUser?.lastName,
        {
          id: 'createdByUser',
          header: () => 'Updated By',
          cell: (info) => info.getValue(),
        }
      ),
      columnHelper.accessor((data) => DateTime.fromISO(data.createdAt!).toFormat(LONG_DATETIME), {
        id: 'createdAt',
        header: () => 'Time',
        cell: (info) => info.getValue(),
      }),
    ]
    return columns
  }, [])

  if (!alert?.id || alert.id !== selectedAlert?.id) return null

  return (
    <Drawer
      open={visible && alert !== null}
      onClose={handleClose}
      anchor={'right'}
      PaperProps={{
        sx: {width: '40%', height: '100%'},
      }}
    >
      <section className='flex h-full w-full flex-col bg-very-dark'>
        <p className='mb-0 border-b-1 border-slate-700 p-5 text-lg'>Alert Details</p>

        <section className='mt-0 bg-very-dark p-5'>
          {alert !== null && (
            <>
              <table>
                <tbody>
                  <tr className='border-b-1 border-b-gray-500'>
                    <td className='text-md p-3 text-gray-800'>Alert</td>
                    <td className='text-md p-3 text-gray-800'>{alert.id}</td>
                  </tr>
                  <tr className='border-b-1 border-b-gray-500'>
                    <td className='text-md p-3 text-gray-800'>Type</td>
                    <td className='text-md p-3 text-gray-800'>{getType(alert.type)}</td>
                  </tr>
                  <tr className='border-b-1 border-b-gray-500'>
                    <td className='text-md p-3 text-gray-800'>Inmate</td>
                    <td className='text-md p-3 text-gray-800'>
                      {alert.participant?.firstName || ''} {alert.participant?.lastName || ''}
                    </td>
                  </tr>
                  {!!alert.heartrate && (
                    <tr className='border-b-1 border-b-gray-500'>
                      <td className='text-md p-3 text-gray-800'>Heart Rate</td>
                      <td className='text-md p-3 text-gray-800'>{alert.heartrate} BPM</td>
                    </tr>
                  )}
                  {!!alert.lowerHeartrate && !!alert.upperHeartrate && (
                    <tr className='border-b-1 border-b-gray-500'>
                      <td className='text-md p-3 text-gray-800'>Usual Heart Rate Range</td>
                      <td className='text-md p-3 text-gray-800'>{`${
                        (alert.lowerHeartrate || 0) - (facilityConfig?.lowHeartrateBuffer || 0)
                      } BPM - ${
                        (alert.upperHeartrate || 0) + (facilityConfig?.highHeartrateBuffer || 0)
                      } BPM`}</td>
                    </tr>
                  )}
                  {!!alert.batteryLevel && (
                    <tr className='border-b-1 border-b-gray-500'>
                      <td className='text-md p-3 text-gray-800'>Battery Level</td>
                      <td className='text-md p-3 text-gray-800'>{alert.batteryLevel} %</td>
                    </tr>
                  )}
                  <tr className='border-b-1 border-b-gray-500'>
                    <td className='text-md p-3 text-gray-800'>Time</td>
                    <td className='text-md p-3 text-gray-800'>
                      {DateTime.fromISO(alert.createdAt!).toFormat(DETAILED_LONG_DATETIME)}
                    </td>
                  </tr>
                  {!!alert.participantPod && (
                    <tr className='border-b-1 border-b-gray-500'>
                      <td className='text-md p-3 text-gray-800'>Participant Pod</td>
                      <td className='text-md p-3 text-gray-800'>{alert.participantPod.podName}</td>
                    </tr>
                  )}
                  {!!alert.participantCell && (
                    <tr className='border-b-1 border-b-gray-500'>
                      <td className='text-md p-3 text-gray-800'>Participant Cell</td>
                      <td className='text-md p-3 text-gray-800'>
                        {alert.participantCell.cellNumber}
                      </td>
                    </tr>
                  )}
                  <tr className='border-b-1 border-b-gray-500'>
                    <td className='text-md p-3 text-gray-800'>Alert Location</td>
                    <td className='text-md p-3 text-gray-800'>
                      {alert.locationName || 'Unknown Location'}
                    </td>
                  </tr>
                  <tr className='border-b-1 border-b-gray-500'>
                    <td className='text-md p-3 text-gray-800'>Participant Current Location</td>
                    <td className='text-md p-3 text-gray-800'>
                      {alert.participantLocation?.locationName || 'Unknown Location'}
                    </td>
                  </tr>
                  {!!selectedAlert.accepted && (
                    <tr className='border-b-1 border-b-gray-500'>
                      <td className='text-md p-3 text-gray-800'>Accepted By</td>
                      <td className='text-md p-3 text-gray-800'>
                        {alert.acceptedBy?.firstName} {alert.acceptedBy?.lastName} on{' '}
                        {DateTime.fromISO(alert.acceptedAt!).toFormat(DETAILED_LONG_DATETIME)}
                      </td>
                    </tr>
                  )}
                  {!!selectedAlert.note && (
                    <>
                      <tr className='border-b-1 border-b-gray-500'>
                        <td className='text-md p-3 text-gray-800'>Action</td>
                        <td className='text-md p-3 text-gray-800'>{selectedAlert.note}</td>
                      </tr>
                      <tr className='border-b-1 border-b-gray-500'>
                        <td className='text-md p-3 text-gray-800'>Action Taken By</td>
                        <td className='text-md p-3 text-gray-800'>
                          {alert.workedBy?.firstName} {alert.workedBy?.lastName} on{' '}
                          {DateTime.fromISO(alert.notedAt!).toFormat(DETAILED_LONG_DATETIME)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              {!!selectedAlert.accepted && (
                <>
                  {selectedAlert.note ? (
                    <div>
                      <p className='pt-5 text-lg'>Notes History</p>
                      <DataTable<AlertNote>
                        countLabel='Alerts'
                        options={{
                          data: selectedAlert.notes,
                          columns,
                        }}
                      />
                    </div>
                  ) : (
                    writeAccess &&
                    selectedAlert.accepted && (
                      <>
                        <FormControl sx={{m: 1}} fullWidth>
                          <InputLabel id='demo-multiple-checkbox-label'>Actions</InputLabel>
                          <Select
                            required
                            labelId='demo-multiple-checkbox-label'
                            id='demo-multiple-checkbox'
                            value={riskOption}
                            onChange={handleChange}
                            input={<OutlinedInput label='Actions' />}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            <MenuItem value='' disabled>
                              Choose Action
                            </MenuItem>
                            {data.map((name: any) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox checked={riskOption.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                          {riskOption.length === 0 && (
                            <FormHelperText>You must select at least one action</FormHelperText>
                          )}
                        </FormControl>

                        <TextField
                          required
                          sx={{m: 1}}
                          fullWidth
                          id='outlined-basic'
                          label='Notes'
                          variant='outlined'
                          onChange={(e) => {
                            setNote(e.target.value)
                            setIsFormValid(e.target.value !== '' && riskOption.length > 0)
                          }}
                        />
                      </>
                    )
                  )}
                </>
              )}
              {selectedAlert.accepted && selectedAlert.note && writeAccess && (
                <TextField
                  required
                  sx={{m: 1}}
                  fullWidth
                  id='outlined-basic'
                  label='Additional Notes'
                  variant='outlined'
                  onChange={(e) => {
                    setAdditionalNotes(e.target.value)
                    setIsFormValid(e.target.value !== '')
                  }}
                />
              )}
              {writeAccess && (
                <div className='mt-5'>
                  <Button style={{marginRight: 20}} onClick={handleClose}>
                    Cancel
                  </Button>
                  {selectedAlert.note ? (
                    <>
                      <Button
                        disabled={!isFormValid}
                        variant='contained'
                        style={{alignSelf: 'right'}}
                        onClick={handleAdditionalNotes}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      disabled={selectedAlert.accepted! && !isFormValid}
                      variant='contained'
                      style={{alignSelf: 'right'}}
                      onClick={handleAccept}
                    >
                      {selectedAlert.accepted ? 'Save' : 'Accept'}
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </Drawer>
  )
}

export default AlertDetails
