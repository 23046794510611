import {
  ColumnFiltersState,
  FilterFn,
  PaginationState,
  RowData,
  SortingFn,
  SortingState,
  TableOptions,
  Updater,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from '@tanstack/react-table'
import {RankingInfo, compareItems, rankItem} from '@tanstack/match-sorter-utils'
import {ReactNode, useCallback, useEffect, useState} from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PaginationFooter from './PaginationFooter'
import GlobalSearch from './GlobalSearch'
import ColumnFilter from './ColumnFilter'
import {PaginationType} from '../../../state/V2/types'
import SimpleColumnFilter from './SimpleColumnFilter'
import {DataTableState} from './DataTable.types'
import {matchArrays} from '../../../helpers/general'
import ColumnFilterOptions from './ColumnFilterOptions'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    options?: {label?: string; value: any}[]
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    )
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

const DataTable = <T,>({
  options,
  topBarElements,
  countLabel,
  showGlobalSearch = true,
  showColumnSearch = true,
  apiPagination,
  onStateChange,
}: {
  options: Partial<TableOptions<T>>
  topBarElements?: ReactNode
  countLabel?: string
  showGlobalSearch?: boolean
  showColumnSearch?: boolean
  apiPagination?: PaginationType
  onStateChange?: (state: DataTableState) => void
}) => {
  const [dataTableState, setDataTableState] = useState<DataTableState>({
    pagination: {
      pageIndex: 0,
      pageSize: 20,
    },
    sorting: [],
    columnFilters: [],
    globalFilter: '',
    columnVisibility: options.initialState?.columnVisibility
      ? options.initialState?.columnVisibility
      : {},
  })

  //Update pagination from apiPagination values
  useEffect(() => {
    setDataTableState((prev) => ({
      ...prev,
      pagination: {
        pageIndex: apiPagination?.page ? apiPagination?.page - 1 : 0,
        pageSize: prev.pagination.pageSize,
      },
    }))
  }, [apiPagination?.page])

  //ShowGlobalSearch only an option if not apiPagination mode
  showGlobalSearch = !apiPagination ? showGlobalSearch : false

  const getApiPaginationSettings = useCallback(() => {
    if (!apiPagination) return {}
    return {
      manualPagination: true,
      manualSortBy: true,
      pageCount: apiPagination ? Math.ceil(apiPagination.count / apiPagination.limit) : undefined,
      onPaginationChange: (paginationUpdater: Updater<PaginationState>) => {
        setDataTableState((prevState) => {
          if (typeof paginationUpdater !== 'function') return prevState
          const newState = {...prevState, pagination: paginationUpdater(prevState.pagination)}
          if (onStateChange) onStateChange(newState)
          return newState
        })
      },
      onSortingChange: (sortingUpdater: Updater<SortingState>) => {
        setDataTableState((prevState) => {
          if (typeof sortingUpdater !== 'function') return prevState
          const newState = {...prevState, sorting: sortingUpdater(prevState.sorting)}
          if (onStateChange) onStateChange(newState)
          return newState
        })
      },
      onColumnFiltersChange: (columnFiltersUpdater: Updater<ColumnFiltersState>) => {
        setDataTableState((prevState) => {
          if (typeof columnFiltersUpdater !== 'function') return prevState
          const newFilters = columnFiltersUpdater(prevState.columnFilters)
          if (matchArrays(newFilters, prevState.columnFilters)) return prevState

          const newState = {
            ...prevState,
            columnFilters: newFilters,
            pagination: {...prevState.pagination, pageIndex: 0},
          }
          if (newState) if (onStateChange) onStateChange(newState)
          return newState
        })
      },
      getPaginationRowModel: undefined,
      getSortedRowModel: undefined,
      getFilteredRowModel: undefined,
    }
  }, [apiPagination, onStateChange])

  const table = useReactTable({
    state: dataTableState,
    onGlobalFilterChange: (updater) => {
      setDataTableState((prev) => {
        if (typeof updater !== 'function') return prev
        return {...prev, globalFilter: updater(prev.globalFilter)}
      })
    },
    onColumnFiltersChange: (updater) =>
      setDataTableState((prev) => {
        if (typeof updater !== 'function') return prev
        return {...prev, columnFilters: updater(prev.columnFilters)}
      }),
    onSortingChange: (updater) => {
      setDataTableState((prev) => {
        if (typeof updater !== 'function') return prev
        return {...prev, sorting: updater(prev.sorting)}
      })
    },
    onPaginationChange: (updater) => {
      setDataTableState((prev) => {
        if (typeof updater !== 'function') return prev
        return {...prev, pagination: updater(prev.pagination)}
      })
    },
    onColumnVisibilityChange: (updater) => {
      setDataTableState((prev) => {
        if (typeof updater !== 'function') return prev
        return {...prev, columnVisibility: updater(prev.columnVisibility)}
      })
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    defaultColumn: {size: 'auto'},
    globalFilterFn: fuzzyFilter,
    filterFns: {fuzzy: fuzzyFilter},
    ...getApiPaginationSettings(),
    ...options,
  } as TableOptions<T>)

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  }

  return (
    <div className='mt-2 flex h-full w-full flex-col'>
      <div className='my-2 flex items-center justify-between'>
        <FormControl sx={{m: 1, width: 200}} size='small'>
          <InputLabel id='column-chooser-label'>Column Chooser</InputLabel>
          <Select
            id='column-chooser'
            labelId='column-chooser-label'
            multiple
            sx={{color: '#cdcdde'}}
            input={<OutlinedInput label='Column Chooser' />}
            value={table.getVisibleLeafColumns().map((column, i) =>
              typeof column.columnDef.header === 'function'
                ? column.columnDef.header({
                    table,
                    column,
                    header: table.getLeafHeaders()[i],
                  })
                : column.columnDef.header
                ? column.columnDef.header
                : column.id
            )}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {table.getAllLeafColumns().map((column, i) => {
              const columnName =
                typeof column.columnDef.header === 'function'
                  ? column.columnDef.header({
                      table,
                      column,
                      header: table.getLeafHeaders()[i],
                    })
                  : column.columnDef.header
                  ? column.columnDef.header
                  : column.id
              return (
                <MenuItem
                  key={column.id}
                  value={columnName}
                  onClick={column.getToggleVisibilityHandler()}
                >
                  <Checkbox checked={column.getIsVisible()} />
                  <ListItemText primary={columnName} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <div className='flex justify-between'>
          {topBarElements}
          {showGlobalSearch && (
            <GlobalSearch
              globalFilter={dataTableState.globalFilter}
              setGlobalFilter={(globalFilter) =>
                setDataTableState((prev) => ({...prev, globalFilter}))
              }
            />
          )}
        </div>
      </div>
      <header className='bg-table-gray'>
        {table.getHeaderGroups().map((headerGroup) => (
          <div className='flex' key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              let filterType = 'search'
              const isPagination = apiPagination

              if (header.column.columnDef.meta?.options) {
                filterType = 'options'
              }
              return (
                <div
                  className='flex w-full flex-col justify-start'
                  style={{
                    maxWidth: isNaN(header.column.getSize()) ? 'auto' : header.column.getSize(),
                  }}
                  key={header.id}
                >
                  <div
                    className={`select-none flex-col border-b-1 border-table-lightgray p-2 px-2 py-4 text-sm font-semibold tracking-wide ${
                      header.column.getCanSort() ? 'cursor-pointer' : ''
                    }`}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder || header.column.columnDef.header === '' ? (
                      <span className='select-none opacity-0'>O</span>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                    {{
                      asc: <ArrowDropUpIcon className='absolute' />,
                      desc: <ArrowDropDownIcon className='absolute' />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>

                  <div className='py-1'>
                    {header.column.getCanFilter() ? (
                      <>
                        {/** Filter input for open text search */}
                        {filterType === 'search' && (
                          <>
                            {!isPagination ? (
                              <ColumnFilter column={header.column} table={table} />
                            ) : (
                              <SimpleColumnFilter column={header.column} table={table} />
                            )}
                          </>
                        )}
                        {/** Filter input for dropdown options */}
                        {filterType === 'options' && (
                          <>
                            {!isPagination ? (
                              <ColumnFilter column={header.column} table={table} />
                            ) : (
                              <ColumnFilterOptions
                                column={header.column}
                                table={table}
                                options={header.column.columnDef.meta?.options || []}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        ))}
      </header>
      <div className='min-h-0 grow overflow-y-scroll '>
        <div className='max-h-20 '>
          {table.getRowModel().rows.map((row) => (
            <div
              className='flex h-16 min-h-full items-center border-b-1 border-table-lightgray '
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <div
                  className={`flex w-full flex-col justify-between px-2 py-4 text-sm`}
                  style={{maxWidth: isNaN(cell.column.getSize()) ? 'auto' : cell.column.getSize()}}
                  key={cell.id}
                >
                  <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className=''>
        {table.getFooterGroups().map((footerGroup) => (
          <div key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <div key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.footer, header.getContext())}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className='h-2' />
      <PaginationFooter<T>
        table={table}
        totalItems={apiPagination?.count}
        countLabel={countLabel}
      />
    </div>
  )
}
export default DataTable
