import {Column, Table} from '@tanstack/react-table'
import DebouncedInput from '../DebouncedInput'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import {useEffect, useState} from 'react'
import {rankItem} from '@tanstack/match-sorter-utils'

const ColumnFilterOptions = ({
  column,
  table,
  options,
}: {
  column: Column<any, unknown>
  table: Table<any>
  options: {label?: string; value: any}[]
}) => {
  const columnFilterValue = column.getFilterValue()
  const [inputValue, setInputValue] = useState('')

  //Sets input value by searching for filterValue in options and using matching options label
  useEffect(() => {
    setInputValue(options.find(({value}) => columnFilterValue === value)?.label || '')
  }, [columnFilterValue, options])

  //uses typed input to find option that matches closest to the label, then sets that value for the columnFilter.
  const handleChange = (value: string | number) => {
    if (!value || value === '') {
      column.setFilterValue(undefined)
      return
    }

    const optionValues = [...options]
    const optionsRank = optionValues
      .map((option) => {
        const rankInfo = rankItem(option.label, value as string)
        return {...option, rankInfo}
      })
      .filter(({rankInfo}) => rankInfo.passed)
      .sort((a, b) => a.rankInfo.rank - b.rankInfo.rank)
    if (optionsRank[0]) column.setFilterValue(optionsRank[0].value)
  }

  //clears out the columnFilter and input value on cancel
  const handleCancel = () => {
    column.setFilterValue('')
    setInputValue('')
  }

  return (
    <div className='relative flex w-full items-center'>
      <datalist id={column.id + 'list'}>
        {options.map(({label, value}) => (
          <option value={label} key={value} />
        ))}
      </datalist>
      <div className='absolute left-2 flex h-full items-center'>
        <SearchIcon className=' text-table-lightgray' />
      </div>
      <DebouncedInput
        type='text'
        value={inputValue}
        onChange={handleChange}
        placeholder={`Search... (${options.length})`}
        className='w-full bg-transparent p-2 px-8 ml-1'
        list={column.id + 'list'}
      />
      {column.getFilterValue() !== undefined && column.getFilterValue() !== '' && (
        <div className='absolute right-2 flex h-full items-center'>
          <CancelIcon onClick={handleCancel} className=' cursor-pointer text-table-lightgray' />
        </div>
      )}
    </div>
  )
}
export default ColumnFilterOptions
