import moment from 'moment'
import {IntlShape} from 'react-intl'

export const minutesToHoursAndMinutes = (time: any, short?: boolean) => {
  if (time === null || time === undefined) {
    return 'N/A'
  }
  if (time === 0) {
    return 'None'
  }
  let duration = moment.duration(time, 'minutes')
  let hours = duration.hours()
  let minutes = duration.minutes()
  let formattedhours = hours > 1 ? `${hours} Hours` : hours === 1 ? `${hours} Hour` : ''
  let formattedminutes =
    minutes > 1 ? `${minutes} Minutes` : minutes === 1 ? `${minutes} Minute` : ''

  if (short) {
    if (formattedhours.length > 0) {
      return formattedhours
    } else {
      return formattedminutes
    }
  }

  if (hours > 0 && minutes > 0) {
    return `${formattedhours}, ${formattedminutes}`
  } else {
    return `${formattedhours}, ${formattedminutes}`
  }
}

export function getTimeDifferenceLabel(timeDifferenceMs: number, intl: IntlShape) {
  if (timeDifferenceMs < 60 * 60 * 1000) {
    const alertMins = Math.round(timeDifferenceMs / (1000 * 60))
    return intl.formatMessage({
      id: alertMins !== 1 ? 'MIN_PLURAL_AGO' : 'MIN_AGO',
    })
  } else {
    const alertHrs = Math.round(timeDifferenceMs / (1000 * 60 * 60))
    return intl.formatMessage({
      id: alertHrs !== 1 ? 'HOUR_PLURAL_AGO' : 'HOUR_AGO',
    })
  }
}
