/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetFacilityConfigurationResponse } from '../models/ApiResponseOfGetFacilityConfigurationResponse';
import type { FacilityConfigurationUpdateBody } from '../models/FacilityConfigurationUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FacilityConfigurationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetFacilityConfigurationResponse 
     * @throws ApiError
     */
    public getFacilityConfiguration(): CancelablePromise<ApiResponseOfGetFacilityConfigurationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/facility-configuration/',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetFacilityConfigurationResponse 
     * @throws ApiError
     */
    public updateFacilityConfiguration(
requestBody: FacilityConfigurationUpdateBody,
): CancelablePromise<ApiResponseOfGetFacilityConfigurationResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/facility-configuration/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
