import clsx from 'clsx'
import * as d3 from 'd3'
import {
  forwardRef,
  useEffect,
  useRef,
  ForwardedRef,
  useImperativeHandle,
  CSSProperties,
  useState,
} from 'react'

interface CircleData {
  cx: number
  cy: number
  radius: number
  color: string
  letter?: string
  path: {x: number; y: number}[] // add the path for each circle
}

export const moveCircle = (
  selection: d3.Selection<SVGCircleElement, CircleData, null, undefined>,
  t: number,
  scale?: number
) => {
  selection
    .attr('cx', (d) => {
      let segmentIndex = Math.floor(t * d.path.length)
      segmentIndex = t === 1 ? 0 : segmentIndex

      const interpolate = d3.interpolate(
        d.path[segmentIndex]?.x,
        d.path[(segmentIndex + 1) % d.path.length]?.x
      )

      return interpolate(t * d.path.length - segmentIndex)
    })
    .attr('cy', (d) => {
      let segmentIndex = Math.floor(t * d.path.length)
      segmentIndex = t === 1 ? 0 : segmentIndex

      const interpolate = d3.interpolate(
        d.path[segmentIndex]?.y,
        d.path[(segmentIndex + 1) % d.path.length]?.y
      )

      return interpolate(t * d.path.length - segmentIndex)
    })
}

export const moveText = (
  selection: d3.Selection<SVGTextElement, CircleData, null, undefined>,
  t: number,
  scale?: number
) => {
  selection
    .attr('x', (d) => {
      let segmentIndex = Math.floor(t * d.path.length)
      segmentIndex = t === 1 ? 0 : segmentIndex

      const interpolate = d3.interpolate(
        d.path[segmentIndex]?.x,
        d.path[(segmentIndex + 1) % d.path.length]?.x
      )

      return interpolate(t * d.path.length - segmentIndex)
    })
    .attr('y', (d) => {
      let segmentIndex = Math.floor(t * d.path.length)
      segmentIndex = t === 1 ? 0 : segmentIndex

      const interpolate = d3.interpolate(
        d.path[segmentIndex]?.y,
        d.path[(segmentIndex + 1) % d.path.length]?.y
      )

      return interpolate(t * d.path.length - segmentIndex)
    })
}

/**
 * loads the external svg element from the url and returns it as a SvgElement
 */
export const loadExternalSvg = async (url: string) => {
  const svgResponse = await fetch(url)
  const svgString = await svgResponse.text()
  if (!svgString) return

  const parser = new DOMParser()
  const svgElement = parser.parseFromString(svgString, 'image/svg+xml')
  return svgElement.getElementsByTagName('svg')[0]
}

/**
 * Component wrapper to display externally loaded svg files
 */
export const ExternalSvg = forwardRef(
  (
    {
      url,
      style,
      hideElementIds = [],
      onLoaded,
    }: {url: string; style?: CSSProperties; hideElementIds: string[]; onLoaded?: () => void},
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    const wrapRef = useRef<HTMLDivElement>(null)
    const prevHideElementIds = useRef(hideElementIds)
    const urlRef = useRef('')

    const [status, setStatus] = useState('ready')

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
      ref,
      () => wrapRef.current,
      []
    )

    //load and add the svgElement to the component
    useEffect(() => {
      const wrap = wrapRef.current
      if (!wrap || urlRef.current === url) return
      urlRef.current = url
      wrap.innerHTML = ''
      setStatus('pending')

      let frameRequest: number
      const getSvgElement = async () => {
        const loadedSvg = await loadExternalSvg(url)
        if (url !== urlRef.current) return

        if (loadedSvg) wrap.appendChild(loadedSvg)
        if (wrap.clientWidth === 0) return
        setStatus('complete')
      }
      getSvgElement()
      return () => {
        cancelAnimationFrame(frameRequest)
      }
    }, [url])

    //Trigger onLoaded once svg is added to the dom
    useEffect(() => {
      const wrap = wrapRef.current
      if (!wrap || !onLoaded) return
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          requestAnimationFrame(onLoaded)
        })
      })

      var config = {
        attributes: true,
      }

      observer.observe(wrap, config)

      return () => {
        observer.disconnect()
      }
    }, [onLoaded])

    //hide elements in the hideElementIds array
    useEffect(() => {
      const wrap = wrapRef.current
      if (!wrap) return

      prevHideElementIds.current.forEach((id) => {
        if (hideElementIds.includes(id)) return
        const target = wrap.querySelector(`#${id}`) as HTMLElement
        if (target) target.style.display = 'inline'
      })
      hideElementIds.forEach((id) => {
        const target = wrap.querySelector(`#${id}`) as HTMLElement
        if (target) target.style.display = 'none'
      })
      prevHideElementIds.current = hideElementIds
    }, [hideElementIds])

    return (
      <div ref={wrapRef} style={style} className={clsx(status !== 'complete' && 'opacity-0')}></div>
    )
  }
)
