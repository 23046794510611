import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {
  AlertEscalationProtocolCreateBody,
  AlertEscalationProtocolUpdateBody,
  CellComplianceRuleCreateBody,
  CellComplianceRuleUpdateBody,
  FacilityConfigurationUpdateBody,
  FacilityContactCreateBody,
  FacilityContactGroupCreateBody,
  FacilityContactGroupUpdateBody,
  FacilityContactUpdateBody,
} from '../openapi'

// Facility Configuration //

export const _getCurrentFacilityConfig = createAsyncThunk('facilityConfig/_config', async () => {
  return await api.facilityConfiguration.getFacilityConfiguration()
})

export const _updateCurrentFacilityConfig = createAsyncThunk(
  'facilityConfig/_updateConfig',
  async (data: FacilityConfigurationUpdateBody) => {
    return await api.facilityConfiguration.updateFacilityConfiguration(
      data as FacilityConfigurationUpdateBody
    )
  }
)

// Contact Groups //

export const _getContactGroup = createAsyncThunk('facilityConfig/_contactGroup', async () => {
  return await api.facilityContactGroup.getFacilityContactGroups()
})

export const _updateContactGroup = createAsyncThunk(
  'facilityConfig/_updateContactGroup',
  async ({id, update}: {id: number; update: Omit<FacilityContactGroupUpdateBody, 'id'>}) => {
    return await api.facilityContactGroup.updateFacilityContactGroups(id.toString(), update)
  }
)

export const _createContactGroup = createAsyncThunk(
  'facilityConfig/_createContactGroup',
  async (contactGroup: FacilityContactGroupCreateBody) => {
    return await api.facilityContactGroup.createFacilityContactGroups(contactGroup)
  }
)

export const _deleteContactGroup = createAsyncThunk(
  'facilityConfig/_deleteContactGroup',
  async (id: number) => {
    return await api.facilityContactGroup.deleteFacilityContactGroups(id.toString())
  }
)

export const _getContacts = createAsyncThunk('facilityConfig/_getContacts', async () => {
  return await api.facilityContact.getFacilityContacts()
})
export const _createContact = createAsyncThunk(
  'facilityConfig/_createContact',
  async (newItem: FacilityContactCreateBody) => {
    return await api.facilityContact.createFacilityContact(newItem)
  }
)
export const _updateContact = createAsyncThunk(
  'facilityConfig/_updateContact',
  async ({id, update}: {id: number; update: FacilityContactUpdateBody}) => {
    return await api.facilityContact.updateFacilityContact(id.toString(), update)
  }
)
export const _deleteContact = createAsyncThunk(
  'facilityConfig/_deleteContact',
  async (id: number) => {
    return await api.facilityContact.deleteFacilityContact(id.toString())
  }
)

// Escalation //
export const _getEscalationProtocols = createAsyncThunk(
  'facilityConfig/_escalationProtocol',
  async () => {
    return await api.alertEscalationProtocol.getAlertEscalationProtocols()
  }
)
export const _updateEscalationProtocol = createAsyncThunk(
  'facilityConfig/_updateEscalationProtocol',
  async ({id, update}: {id: number; update: AlertEscalationProtocolUpdateBody}) => {
    return await api.alertEscalationProtocol.updateAlertEscalationProtocol(id.toString(), update)
  }
)

export const _createEscalationProtocol = createAsyncThunk(
  'facilityConfig/_createEscalationProtocol',
  async (newItem: AlertEscalationProtocolCreateBody) => {
    return await api.alertEscalationProtocol.createAlertEscalationProtocols(newItem)
  }
)

export const _deleteEscalationProtocol = createAsyncThunk(
  'facilityConfig/_deleteEscalationProtocol',
  async (id: number) => {
    return await api.alertEscalationProtocol.deleteAlertEscalationProtocol(id.toString())
  }
)

// export const _updateEscalationProtocolStep = createAsyncThunk(
//   'facilityConfig/_updateEscalationProtocolStep',
//   async ({id, update}: {id: number; update: AlertE}) => {
//     return await api.alertEscalationProtocol.updateAlertEscalationProtocol(id.toString(), update)
//   }
// )

// export const _createEscalationProtocolStep = createAsyncThunk(
//   'facilityConfig/_createEscalationProtocolStep',
//   async (newItem: AlertEscalationProtocolCreateBody) => {
//     return await api.alertEscalationProtocol.createAlertEscalationProtocols(newItem)
//   }
// )

// export const _deleteEscalationProtocolStep = createAsyncThunk(
//   'facilityConfig/_deleteEscalationProtocolStep',
//   async (id: number) => {
//     return await api.alertEscalationProtocol.deleteAlertEscalationProtocol(id.toString())
//   }
// )

// Cell Compliance Rules //
export const _getCellComplianceRules = createAsyncThunk(
  'facilityConfig/_cellComplianceRules',
  async () => {
    return await api.cellComplianceRules.getCellComplianceRules()
  }
)
export const _createCellComplianceRules = createAsyncThunk(
  'facilityConfig/_createComplianceRules',
  async (newRule: CellComplianceRuleCreateBody) => {
    return await api.cellComplianceRules.createCellComplianceRule(newRule)
  }
)
export const _updateCellComplianceRules = createAsyncThunk(
  'facilityConfig/_updateComplianceRules',
  async ({id, update}: {id: number; update: CellComplianceRuleUpdateBody}) => {
    return await api.cellComplianceRules.updateCellComplianceRule(id.toString(), update)
  }
)
export const _deleteCellComplianceRules = createAsyncThunk(
  'facilityConfig/_deleteComplianceRules',
  async (id: number) => {
    return await api.cellComplianceRules.deleteCellComplianceRule(id.toString())
  }
)

