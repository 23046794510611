import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {
  _getAllCellOpenTimes,
  _getCellOpenTimesById,
  _getCells,
  _getCurrentFacility,
  _getFacilities,
  _getFacilityPods,
  _getLocationCount,
  _getLocations,
  _getPods,
  _updateCurrentFacility,
} from './facility.actions'
import {addAPICases} from '../api.helpers'
import {
  Facility,
  GetCellOpenTimesResponse,
  GetFacilitiesResponse,
  GetLocationCoordinatesResponse,
  GetPaginatedCellsResponse,
  GetPaginatedPodsResponse,
  GetParticipantLocationCountResponse,
} from '../openapi'
import {ApiRequestState, apiRequestStateDefault} from '../api.types'

/*--------------------- Facility ------------------------*/

export type FacilityState = {
  list: ApiRequestState<GetFacilitiesResponse>
  current: ApiRequestState<Facility>
}

const initialFacilityState: FacilityState = {
  list: {...apiRequestStateDefault},
  current: {...apiRequestStateDefault},
}

const facility = createSlice({
  name: 'facility',
  initialState: initialFacilityState,
  reducers: {},
  extraReducers: (builder) => {
    addAPICases(builder, _getFacilities, 'list')
    addAPICases(builder, _getCurrentFacility, 'current')
    addAPICases(builder, _updateCurrentFacility, 'current')
  },
})

/*--------------------- Pods ------------------------*/

export type PodState = {
  list: ApiRequestState<GetPaginatedPodsResponse>
  facilityList: ApiRequestState<GetPaginatedPodsResponse>
}

const initialPodState: PodState = {
  list: {...apiRequestStateDefault},
  facilityList: {...apiRequestStateDefault},
}

const pods = createSlice({
  name: 'pods',
  initialState: initialPodState,
  reducers: {},
  extraReducers: (builder) => {
    addAPICases(builder, _getPods, 'list')
    addAPICases(builder, _getFacilityPods, 'facilityList')
  },
})

/*--------------------- Cells ------------------------*/

const initialCellsState: {
  list: ApiRequestState<GetPaginatedCellsResponse>
  allOpenTimes: ApiRequestState<GetCellOpenTimesResponse>
  selectedOpenTimes: ApiRequestState<GetCellOpenTimesResponse>
} = {
  list: {...apiRequestStateDefault},
  allOpenTimes: {...apiRequestStateDefault},
  selectedOpenTimes: {...apiRequestStateDefault},
}

const cells = createSlice({
  name: 'cells',
  initialState: initialCellsState,
  reducers: {},
  extraReducers: (builder) => {
    addAPICases(builder, _getCells, 'list')
    addAPICases(builder, _getAllCellOpenTimes, 'allOpenTimes')
    addAPICases(builder, _getCellOpenTimesById, 'selectedOpenTimes')
  },
})

/*--------------------- Location ------------------------*/

export type LocationsState = {
  list?: ApiRequestState<GetLocationCoordinatesResponse>
  counts: ApiRequestState<GetParticipantLocationCountResponse>
}

const locationsInitialState: LocationsState = {
  list: {...apiRequestStateDefault},
  counts: {...apiRequestStateDefault},
}

const locations = createSlice({
  name: 'locations',
  initialState: locationsInitialState,
  reducers: {
    updateLocationData: (state, action: PayloadAction<{location: string; value: string}[]>) => {
      action.payload.forEach(({location, value}) => {
        if (!state.counts.data) state.counts.data = {total: 0, locations: []}
        const index = state.counts.data.locations.findIndex((res) => res.location === location)
        const change = Number(value)
        if (isNaN(change)) return

        if (index === -1) {
          if (change > 0) state.counts.data.locations.push({id: -1, location, count: change})
          return
        }

        state.counts.data.locations[index].count += change
      })
    },
  },
  extraReducers: (builder) => {
    addAPICases(builder, _getLocations, 'list')
    addAPICases(builder, _getLocationCount, 'counts')
  },
})

export const {updateLocationData} = locations.actions

export const facilityReducer = facility.reducer
export const podsReducer = pods.reducer
export const cellsReducer = cells.reducer
export const locationsReducer = locations.reducer
