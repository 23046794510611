/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfBatteryCreateResponse } from '../models/ApiResponseOfBatteryCreateResponse';
import type { ApiResponseOfCoordinatesCreateResponse } from '../models/ApiResponseOfCoordinatesCreateResponse';
import type { ApiResponseOfGetPaginatedParticipantHeartratesResponse } from '../models/ApiResponseOfGetPaginatedParticipantHeartratesResponse';
import type { ApiResponseOfGetParticipantHeartratesResponse } from '../models/ApiResponseOfGetParticipantHeartratesResponse';
import type { ApiResponseOfHeartrateCreateResponse } from '../models/ApiResponseOfHeartrateCreateResponse';
import type { ApiResponseOfTamperCreateResponse } from '../models/ApiResponseOfTamperCreateResponse';
import type { BatteryCreateBody } from '../models/BatteryCreateBody';
import type { CoordinatesCreateBody } from '../models/CoordinatesCreateBody';
import type { GetPaginatedParticipantHeartratesBody } from '../models/GetPaginatedParticipantHeartratesBody';
import type { HeartrateCreateBody } from '../models/HeartrateCreateBody';
import type { TamperCreateBody } from '../models/TamperCreateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ParticipantAlertService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfBatteryCreateResponse 
     * @throws ApiError
     */
    public createParticipantBatteryRecord(
requestBody: BatteryCreateBody,
): CancelablePromise<ApiResponseOfBatteryCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/inmate-alert/battery',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfHeartrateCreateResponse 
     * @throws ApiError
     */
    public createParticipantHeartrateRecord(
requestBody: HeartrateCreateBody,
): CancelablePromise<ApiResponseOfHeartrateCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/inmate-alert/heartrate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedParticipantHeartrates
     * @param participantId 
     * @param startTime 
     * @param endTime 
     * @param limit 
     * @returns ApiResponseOfGetParticipantHeartratesResponse 
     * @throws ApiError
     */
    public getParticipantHeartrate(
participantId: string,
startTime?: string | null,
endTime?: string | null,
limit?: number | null,
): CancelablePromise<ApiResponseOfGetParticipantHeartratesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/inmate-alert/heartrate/{participantId}',
            path: {
                'participantId': participantId,
            },
            query: {
                'startTime': startTime,
                'endTime': endTime,
                'limit': limit,
            },
        });
    }

    /**
     * @param participantId 
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedParticipantHeartratesResponse 
     * @throws ApiError
     */
    public getPaginatedParticipantHeartrates(
participantId: string,
requestBody: GetPaginatedParticipantHeartratesBody,
): CancelablePromise<ApiResponseOfGetPaginatedParticipantHeartratesResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/inmate-alert/heartrate/{participantId}/paginated',
            path: {
                'participantId': participantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfCoordinatesCreateResponse 
     * @throws ApiError
     */
    public createParticipantCoordinatesRecord(
requestBody: CoordinatesCreateBody,
): CancelablePromise<ApiResponseOfCoordinatesCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/inmate-alert/coordinates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfTamperCreateResponse 
     * @throws ApiError
     */
    public createParticipantTamperRecord(
requestBody: TamperCreateBody,
): CancelablePromise<ApiResponseOfTamperCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/inmate-alert/tamper',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfBatteryCreateResponse 
     * @throws ApiError
     */
    public createParticipantBatteryRecord1(
requestBody: BatteryCreateBody,
): CancelablePromise<ApiResponseOfBatteryCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/participant-alert/battery',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfHeartrateCreateResponse 
     * @throws ApiError
     */
    public createParticipantHeartrateRecord1(
requestBody: HeartrateCreateBody,
): CancelablePromise<ApiResponseOfHeartrateCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/participant-alert/heartrate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedParticipantHeartrates
     * @param participantId 
     * @param startTime 
     * @param endTime 
     * @param limit 
     * @returns ApiResponseOfGetParticipantHeartratesResponse 
     * @throws ApiError
     */
    public getParticipantHeartrate1(
participantId: string,
startTime?: string | null,
endTime?: string | null,
limit?: number | null,
): CancelablePromise<ApiResponseOfGetParticipantHeartratesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/participant-alert/heartrate/{participantId}',
            path: {
                'participantId': participantId,
            },
            query: {
                'startTime': startTime,
                'endTime': endTime,
                'limit': limit,
            },
        });
    }

    /**
     * @param participantId 
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedParticipantHeartratesResponse 
     * @throws ApiError
     */
    public getPaginatedParticipantHeartrates1(
participantId: string,
requestBody: GetPaginatedParticipantHeartratesBody,
): CancelablePromise<ApiResponseOfGetPaginatedParticipantHeartratesResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/participant-alert/heartrate/{participantId}/paginated',
            path: {
                'participantId': participantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfCoordinatesCreateResponse 
     * @throws ApiError
     */
    public createParticipantCoordinatesRecord1(
requestBody: CoordinatesCreateBody,
): CancelablePromise<ApiResponseOfCoordinatesCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/participant-alert/coordinates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfTamperCreateResponse 
     * @throws ApiError
     */
    public createParticipantTamperRecord1(
requestBody: TamperCreateBody,
): CancelablePromise<ApiResponseOfTamperCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/participant-alert/tamper',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
