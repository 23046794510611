/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfDeletePodResponse } from '../models/ApiResponseOfDeletePodResponse';
import type { ApiResponseOfGetPaginatedPodsResponse } from '../models/ApiResponseOfGetPaginatedPodsResponse';
import type { ApiResponseOfGetPodByIdResponse } from '../models/ApiResponseOfGetPodByIdResponse';
import type { ApiResponseOfPodCreateResponse } from '../models/ApiResponseOfPodCreateResponse';
import type { ApiResponseOfPodUpdateResponse } from '../models/ApiResponseOfPodUpdateResponse';
import type { GetPaginatedPodsBody } from '../models/GetPaginatedPodsBody';
import type { PodCreateBody } from '../models/PodCreateBody';
import type { PodUpdateBody } from '../models/PodUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PodsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedPodsFiltered
     * @param page 
     * @param limit 
     * @param facilityId 
     * @param buildingFloorId 
     * @param sortBy 
     * @param sortDir 
     * @param filters 
     * @returns ApiResponseOfGetPaginatedPodsResponse 
     * @throws ApiError
     */
    public getPaginatedPods(
page?: number | null,
limit?: number | null,
facilityId?: number | null,
buildingFloorId?: number | null,
sortBy?: 'id' | 'podName' | 'doorOpen' | 'participantCount',
sortDir?: 'asc' | 'desc',
filters?: ({
operation: 'eq' | 'neq' | 'contains' | 'not-contains' | 'starts-with' | 'ends-with';
value: string | null;
field: 'podName';
} | {
operation: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'between';
value: number | null;
valueTo?: number | null;
field: 'id' | 'participantCount';
} | {
operation: 'eq' | 'neq';
value: (boolean | string);
field: 'doorOpen';
}),
): CancelablePromise<ApiResponseOfGetPaginatedPodsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/pod/',
            query: {
                'page': page,
                'limit': limit,
                'facilityId': facilityId,
                'buildingFloorId': buildingFloorId,
                'sortBy': sortBy,
                'sortDir': sortDir,
                'filters': filters,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfPodCreateResponse 
     * @throws ApiError
     */
    public createPod(
requestBody: PodCreateBody,
): CancelablePromise<ApiResponseOfPodCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pod/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedPodsResponse 
     * @throws ApiError
     */
    public getPaginatedPodsFiltered(
requestBody: GetPaginatedPodsBody,
): CancelablePromise<ApiResponseOfGetPaginatedPodsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pod/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param podId 
     * @returns ApiResponseOfGetPodByIdResponse 
     * @throws ApiError
     */
    public getPodById(
podId: string,
): CancelablePromise<ApiResponseOfGetPodByIdResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/pod/{podId}',
            path: {
                'podId': podId,
            },
        });
    }

    /**
     * @param podId 
     * @param requestBody 
     * @returns ApiResponseOfPodUpdateResponse 
     * @throws ApiError
     */
    public updatePod(
podId: string,
requestBody: PodUpdateBody,
): CancelablePromise<ApiResponseOfPodUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/pod/{podId}',
            path: {
                'podId': podId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param podId 
     * @returns ApiResponseOfDeletePodResponse 
     * @throws ApiError
     */
    public deletePod(
podId: string,
): CancelablePromise<ApiResponseOfDeletePodResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/pod/{podId}',
            path: {
                'podId': podId,
            },
        });
    }

}
