/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfCancelHistoricalResponse } from '../models/ApiResponseOfCancelHistoricalResponse';
import type { ApiResponseOfGetHistoricalResponse } from '../models/ApiResponseOfGetHistoricalResponse';
import type { GetHistoricalByLocationIdsBody } from '../models/GetHistoricalByLocationIdsBody';
import type { GetHistoricalByParticipantIdsBody } from '../models/GetHistoricalByParticipantIdsBody';
import type { GetHistoricalParticipantFollowBody } from '../models/GetHistoricalParticipantFollowBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HistoricalService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetHistoricalResponse 
     * @throws ApiError
     */
    public getHistoricalByParticipantIds(
requestBody: GetHistoricalByParticipantIdsBody,
): CancelablePromise<ApiResponseOfGetHistoricalResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/historical/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetHistoricalResponse 
     * @throws ApiError
     */
    public getHistoricalByLocationIds(
requestBody: GetHistoricalByLocationIdsBody,
): CancelablePromise<ApiResponseOfGetHistoricalResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/historical/location',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetHistoricalResponse 
     * @throws ApiError
     */
    public getHistoricalParticipantFollow(
requestBody: GetHistoricalParticipantFollowBody,
): CancelablePromise<ApiResponseOfGetHistoricalResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/historical/follow',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestId 
     * @returns ApiResponseOfCancelHistoricalResponse 
     * @throws ApiError
     */
    public cancelHistorical(
requestId: string,
): CancelablePromise<ApiResponseOfCancelHistoricalResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/historical/{requestId}',
            path: {
                'requestId': requestId,
            },
        });
    }

}
