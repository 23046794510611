import {ReactNode, useEffect, useState} from 'react'

const TransitionElement = ({
  className,
  transitionIn,
  transitionOut,
  show = true,
  removeOnOut = true,
  style,
  children,
}: {
  className?: string
  transitionIn?: string
  transitionOut?: string
  show?: boolean
  removeOnOut?: boolean
  style?: React.CSSProperties
  children: ReactNode
}) => {
  const [trueShow, setTrueShow] = useState(show)
  const [transitionState, setTransitionState] = useState(false)

  useEffect(() => {
    if (show) {
      setTrueShow(true)
      const showdelay = setTimeout(() => {
        setTransitionState(true)
      }, 33)
      return () => {
        clearTimeout(showdelay)
      }
    } else {
      setTransitionState(false)
    }
  }, [show])

  if (!trueShow && removeOnOut) return null

  return (
    <div
      className={`${className} ${transitionState ? transitionIn : transitionOut}`}
      style={{...style}}
      onTransitionEnd={() => setTrueShow(show)}
    >
      {children}
    </div>
  )
}
export default TransitionElement
