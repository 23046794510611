/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, ReactNode, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../layout/core'
import {AlertList} from './modules/alerts-list/AlertsList'
import Battery from './modules/Battery'
import RiskChart from './modules/RiskChart'
import InmateDetails from './modules/inmate-details/InmateDetails'
import TotalInmates from './modules/TotalInmates'
import './dashboardcss.scss'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../state/store'
import {_getParticipants} from '../../../state/V2/participant/participant.actions'
import DashboardFilter from './components/DashboardFilter'
import {useFeatureFlags} from '../../../helpers/growthbook.utils'
import {sum} from 'lodash'
import Map from './modules/Map'
interface IProps {
  setFilter: (filter: {location?: string; risk?: number}) => void
  filter: {
    location?: string
    risk?: number
  }
  setOrientation: (orientation: number[]) => void
  orientation: number[]
  orientationType: string
  setOrientationType: (type: string) => void
}
interface ComponentList {
  key: string
  label: string
  component: ReactNode | any
  props: any
}

const DashboardPage: FC<IProps> = ({
  setFilter,
  filter,
  setOrientation,
  orientation,
  orientationType,
  setOrientationType,
}) => {
  const [mHeight, setMHeight] = useState<string>('50vh')
  const {hasFeature} = useFeatureFlags()
  const [orderedComponents, setOrderedComponents] = useState<ComponentList[]>([
    {
      key: '1',
      label: 'Alerts',
      component: AlertList,
      props: {className: '', orientationType, mHeight},
    },
    {key: '2', label: 'Risk Chart', component: RiskChart, props: {setFilter}},
    {key: '3', label: 'Battery %', component: Battery, props: {className: ''}},
    {key: '4', label: 'Risk Inmates', component: TotalInmates, props: {className: '', filter}},
  ])

  useEffect(() => {
    if (hasFeature('live-track')) {
      setOrderedComponents((features) => [
        ...features,
        {key: '5', label: 'Map', component: Map, props: {className: ''}},
      ])
    }
  }, [])
  useEffect(() => {
    setOrderedComponents((prevComponents) =>
      prevComponents.map((component) => {
        if (component.label === 'Alerts') {
          return {...component, props: {...component.props, orientationType, mHeight}}
        }
        if (component.label === 'Risk Inmates') {
          return {...component, props: {...component.props, filter, mHeight}}
        }
        if (component.label === 'Battery %') {
          return {...component, props: {...component.props, mHeight}}
        }
        return component
      })
    )
  }, [orientationType, filter, mHeight])

  const renderDynamicLayout = () => {
    const totalComponents = sum(orientation)
    const modifiedOrien = orientation
      .flatMap((n) => (n === 2 ? [2, 2] : n))
      .filter((item) => item !== 0)
    const horizontalLeft: any[] = []
    const horizontalRight: any[] = []
    const content = orderedComponents.slice(0, totalComponents).map((item, index) => {
      const gridSize = 12 / modifiedOrien[index]
      const classArr: {[key: number]: string} = {12: 'w-full', 6: 'w-1/2'}
      const className = classArr[gridSize]

      const ComponentToRender = item.component

      if (orientationType === 'H') {
        const result = (
          <div key={index} className='w-full'>
            <ComponentToRender {...item.props} />
          </div>
        )
        if (orientation[0] < index + 1) {
          horizontalRight.push(result)
        } else {
          horizontalLeft.push(result)
        }
      }

      return (
        <div key={index} className={`${className}`}>
          <ComponentToRender {...item.props} />
        </div>
      )
    })

    let localMHeight = mHeight

    const applyFullHeightStyle = (arr: any) => {
      if (arr.length > 1) {
        return React.createElement(
          'div',
          {
            className: 'flex flex-wrap gap-y-6',
            style: {height: '100%'},
          },
          arr
        )
      } else {
        localMHeight = '100vh'
        if (localMHeight !== mHeight) {
          setMHeight(localMHeight)
        }
        return React.cloneElement(arr[0], {
          style: {flexGrow: 1, height: '100%'},
        })
      }
    }

    return (
      <div className='flex w-full flex-wrap'>
        {orientationType === 'H' ? (
          <>
            <div className='first-mb flex w-1/2 flex-col'>
              {applyFullHeightStyle(horizontalLeft)}
            </div>
            <div className='first-mb flex w-1/2 flex-col'>
              {applyFullHeightStyle(horizontalRight)}
            </div>
          </>
        ) : (
          content
        )}
      </div>
    )
  }

  return (
    <div className='dashboard flex flex-wrap overflow-hidden'>
      <div className='w-full'>
        <InmateDetails setFilter={setFilter} />
      </div>
      <DashboardFilter
        setOrientation={setOrientation}
        setOrientationType={setOrientationType}
        orderedComponents={orderedComponents}
        setOrderedComponents={setOrderedComponents}
      />

      {renderDynamicLayout()}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch<AppDispatch>()
  const [filter, setFilter] = useState<{
    location?: string
    risk?: number
  }>({risk: 3})
  const podAssignment = useSelector((state: RootState) => state.users.podAssignment.data)
  const [orientation, setOrientation] = useState([2, 2])
  const [orientationType, setOrientationType] = useState('V')

  useEffect(() => {
    dispatch(_getParticipants({}))
  }, [dispatch, podAssignment])

  return (
    <>
      <PageTitle />
      <DashboardPage
        setFilter={setFilter}
        filter={filter}
        setOrientation={setOrientation}
        orientation={orientation}
        orientationType={orientationType}
        setOrientationType={setOrientationType}
      />
    </>
  )
}

export {DashboardWrapper}
