import {useEffect, useMemo, useRef, useState, MouseEvent} from 'react'
import {getPolygonVisualCenter} from '../../../../helpers/live-track'
import {Menu, MenuItem} from '@mui/material'
import {useMap2DState} from './Map2DContext'
import {LocationCoordinates, Participant} from '../../../../state/V2/openapi'

const LocationInmateList = ({
  location,
}: {
  location: LocationCoordinates & {count: number; participants: Participant[]}
}) => {
  const [{darkMode, conversionScale, transformComponentRef}, updateMap2DState] = useMap2DState()
  const {id, count, location: name} = location
  let locationCount = count || 0
  const listLocationRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isMenuOpen = !!anchorEl

  //gets the correct map positioning for the labels & dropdowns
  const positioning = useMemo(() => {
    const points = location.coords
      .replace(/[()]/g, '')
      .trim()
      .split(',')
      .map((pointdata) => {
        const values = pointdata.trim().split(' ').map(parseFloat)
        return {x: values[0], y: values[1]}
      })
    const polyVisualCenter = getPolygonVisualCenter(points)
    const position = {
      x: polyVisualCenter.x * conversionScale,
      y: polyVisualCenter.y * conversionScale,
    }
    return position
  }, [location.coords, conversionScale])

  //updates the position of the labels and inmate list based on map scale and position.
  useEffect(() => {
    let frameUpdate: number
    const updateTransform = () => {
      const listLocation = listLocationRef.current
      const transformState = transformComponentRef?.current?.instance?.getContext()?.state
      if (listLocation && transformState) {
        listLocation.style.top = `${
          positioning.y * transformState.scale + transformState.positionY
        }px`
        listLocation.style.left = `${
          positioning.x * transformState.scale + transformState.positionX
        }px`
      }
      frameUpdate = requestAnimationFrame(updateTransform)
    }
    updateTransform()

    return () => {
      cancelAnimationFrame(frameUpdate)
    }
  }, [positioning.x, positioning.y, transformComponentRef])

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      key={id}
      ref={listLocationRef}
      className={`absolute flex h-0 w-0 flex-col items-center justify-center overflow-visible  ${
        darkMode ? 'text-white' : 'text-black'
      }`}
    >
      <div className={`flex w-36 justify-center`}>
        <button
          disabled={locationCount <= 0}
          className={`relative select-none rounded-sm  bg-opacity-80 p-1 text-center text-xs font-bold ${
            darkMode ? 'bg-table-gray' : 'bg-white'
          } ${count <= 0 ? '' : 'pointer-events-auto'}`}
          onClick={count > 0 ? handleMenuClick : undefined}
        >
          {name.toUpperCase()}
          {locationCount > 0 && (
            <>
              <div className='absolute right-0 top-0 w-0'>
                <div
                  className={`ml-1 flex h-6 w-6 select-none items-center justify-center rounded-full bg-red-700 p-2 py-1 text-xs text-white ${
                    darkMode ? '' : 'border-1 border-white'
                  }`}
                >
                  {locationCount}
                </div>
              </div>
            </>
          )}
        </button>
        <Menu
          id='inmate-select'
          className='round-select'
          open={isMenuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: '300px',
              overflow: 'auto',
            },
          }}
        >
          {location.participants?.map(({id, firstName, lastName, risk}) => {
            let riskStyle = ''
            switch (risk) {
              case 1:
                riskStyle = 'border-yellow-500'
                break
              case 2:
                riskStyle = 'border-orange-600 border-dotted'
                break
              case 3:
                riskStyle = 'border-red-300 border-dashed'
                break
            }

            return (
              <MenuItem
                onClick={() => {
                  updateMap2DState({selectedInmateId: id})
                  setAnchorEl(null)
                }}
                value={id}
                key={id}
              >
                <div className={`mr-2 h-5 w-5 rounded-full border-2 ${riskStyle}`}>
                  <div className='h-full w-full rounded-full bg-blue-800'></div>
                </div>
                <span>
                  {firstName} {lastName}
                </span>
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    </div>
  )
}

export default LocationInmateList
