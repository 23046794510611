/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {AlertDetails} from '../../../alerts/AlertDetails'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import WatchOffIcon from '@mui/icons-material/WatchOff'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../../../state/store'
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone'
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone'
import {getType} from '../../../../../helpers/general'
import './AlertsList.scss'
import {_getAlertById, _getAlerts} from '../../../../../state/V2/alerts/alerts.actions'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  orientationType: string
  mHeight: string
}
const AlertList: React.FC<Props> = ({className, orientationType, mHeight}) => {
  const intl = useIntl()
  const [visible, setVisible] = useState<boolean>(false)
  const selectedAlert = useSelector((state: RootState) => state.alerts.selected.data)
  const [alertWorked] = useState<boolean>(false)
  const [shouldFlash, setShouldFlash] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const alerts = useSelector((state: RootState) => state.alerts.list.data?.results)
  const podAssignment = useSelector((state: RootState) => state.users.podAssignment.data)

  const getIcon = (type?: string | null) => {
    if (!type) return null
    type = type.toLowerCase()
    switch (type) {
      case 'low battery':
        return <BatteryAlertIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'low heart rate':
        return <MonitorHeartIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'band tamper':
        return <WatchOffIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'low_battery_level':
        return <BatteryAlertIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'low_heart_rate':
        return <MonitorHeartIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'band_tamper':
        return <WatchOffIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'band_offline':
        return <WatchOffIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'heart_rate_offline':
        return <WarningAmberIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'high_heart_rate':
        return <MonitorHeartIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />

      default:
        return null
    }
  }

  const getOutline = (accepted: boolean | null) => {
    if (accepted === true) {
      return 'rgba(0,0,0,.6)'
    } else {
      return 'rgba(216, 122, 22, .6)'
    }
  }

  useEffect(() => {
    dispatch(
      _getAlerts({
        limit: 200,
        page: 1,
        filters: [{field: 'workedById', operation: 'eq', value: null}],
      })
    )
  }, [dispatch, podAssignment])

  useEffect(() => {
    if (alertWorked === false) {
      setShouldFlash(true)
    }
  }, [alertWorked])

  // Flash unaccepted alerts every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => setShouldFlash(true), 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // Stop flashing after 4 seconds
  useEffect(() => {
    if (!shouldFlash) return
    const timeout = setTimeout(() => {
      setShouldFlash(false)
    }, 4500) // stop flashing after 4 seconds

    return () => {
      clearTimeout(timeout)
    }
  }, [shouldFlash])

  return (
    <>
      <div className='h-full w-full rounded bg-neutral-900 shadow-lg'>
        {/* begin::Header */}
        <div className='px-7 pt-7 pb-3'>
          <h3 className='text-base'>{intl.formatMessage({id: 'DASHBOARD.ALERTS'})}</h3>
        </div>

        <div className='overflow-auto px-5 pb-3' style={{minHeight: mHeight, maxHeight: mHeight}}>
          {alerts &&
            alerts.map((v) => {
              const participantName = [v.participant?.firstName, v.participant?.lastName]
                .filter(Boolean)
                .join(' ')

              const podCellInfo = [v.participantPod?.podName, v.participantCell?.cellNumber]
                .filter(Boolean)
                .join(' - ')

              const currentLocation = v.participantLocation?.locationName || 'Unknown Location'

              return (
                <div
                  className={`mb-5 flex items-center rounded px-5 py-4 capitalize ${
                    shouldFlash && !v.accepted ? 'flash' : ''
                  }`}
                  style={{
                    background: 'black',
                    cursor: 'pointer',
                    border: `solid ${getOutline(v.accepted)}`,
                  }}
                  key={v.id}
                  onClick={async () => {
                    setVisible(true)
                    await dispatch(_getAlertById(v.id))
                  }}
                >
                  {/* begin::Icon */}
                  <span className='svg-icon  me-5'>{getIcon(v.type)}</span>
                  {/* end::Icon */}
                  {/* begin::Title */}
                  <div className='flex-grow-1 me-2'>
                    <a className='text-md text-gray-800'>
                      {getType(v.type)}
                      <span className='text-gray-700'>
                        {participantName ? ` | ${participantName}` : ''}
                      </span>
                    </a>
                  </div>
                  <div className='flex-grow-1 me-2 flex flex-col text-end'>
                    <a className='text-md text-gray-800'>{podCellInfo}</a>
                    <a className='text-sm text-gray-700'>{currentLocation}</a>
                  </div>
                  {v.accepted ? (
                    <div style={{marginLeft: 10}}>
                      <TaskAltTwoToneIcon style={{color: '#d87a16'}} />
                    </div>
                  ) : (
                    <div style={{marginLeft: 10}}>
                      <RadioButtonUncheckedTwoToneIcon style={{color: '#515159'}} />
                    </div>
                  )}
                  {/* end::Title */}
                </div>
              )
            })}
        </div>
      </div>
      <AlertDetails
        alert={selectedAlert || null}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
    </>
  )
}

export {AlertList}
