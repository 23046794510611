/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetPaginatedDeviceHistoryResponse } from '../models/ApiResponseOfGetPaginatedDeviceHistoryResponse';
import type { GetPaginatedDeviceHistoryBody } from '../models/GetPaginatedDeviceHistoryBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DeviceHistoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Deprecated in favor of getPaginatedDeviceHistoryFiltered
     * @param page 
     * @param limit 
     * @param facilityId 
     * @param participantId 
     * @param deviceId 
     * @returns ApiResponseOfGetPaginatedDeviceHistoryResponse 
     * @throws ApiError
     */
    public getPaginatedDeviceHistory(
page?: number | null,
limit?: number | null,
facilityId?: number | null,
participantId?: number | null,
deviceId?: number | null,
): CancelablePromise<ApiResponseOfGetPaginatedDeviceHistoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/device-history/',
            query: {
                'page': page,
                'limit': limit,
                'facilityId': facilityId,
                'participantId': participantId,
                'deviceId': deviceId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetPaginatedDeviceHistoryResponse 
     * @throws ApiError
     */
    public getPaginatedDeviceHistoryFiltered(
requestBody: GetPaginatedDeviceHistoryBody,
): CancelablePromise<ApiResponseOfGetPaginatedDeviceHistoryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/device-history/paginated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
