import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from './i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from './layout/core'
import {MasterInit} from './layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AudioPlayer from './components/AudioPlayer'

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#d87a16',
    },
    mode: 'dark',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            webkitBoxShadow: '0 0 0 100px #616161 inset',
            webkitTextFillColor: '#fff',
          },
        },
      },
    },
  },
})

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <ToastContainer theme='dark' />
              <AudioPlayer />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </ThemeProvider>
  )
}

export {App}
