/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfGetAuthTokenResponse } from '../models/ApiResponseOfGetAuthTokenResponse';
import type { ApiResponseOfPasswordResetCodeCheckResponse } from '../models/ApiResponseOfPasswordResetCodeCheckResponse';
import type { ApiResponseOfPasswordResetResponse } from '../models/ApiResponseOfPasswordResetResponse';
import type { ApiResponseOfPasswordUpdateResponse } from '../models/ApiResponseOfPasswordUpdateResponse';
import type { GetAuthTokenBody } from '../models/GetAuthTokenBody';
import type { PasswordResetBody } from '../models/PasswordResetBody';
import type { PasswordResetCodeCheckBody } from '../models/PasswordResetCodeCheckBody';
import type { PasswordUpdateBody } from '../models/PasswordUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns ApiResponseOfGetAuthTokenResponse 
     * @throws ApiError
     */
    public getAuthToken(
requestBody: GetAuthTokenBody,
): CancelablePromise<ApiResponseOfGetAuthTokenResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfPasswordResetResponse 
     * @throws ApiError
     */
    public passwordReset(
requestBody: PasswordResetBody,
): CancelablePromise<ApiResponseOfPasswordResetResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/password-reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfPasswordResetCodeCheckResponse 
     * @throws ApiError
     */
    public passwordResetCodeCheck(
requestBody: PasswordResetCodeCheckBody,
): CancelablePromise<ApiResponseOfPasswordResetCodeCheckResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/auth/password-reset/check',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfPasswordUpdateResponse 
     * @throws ApiError
     */
    public updatePassword(
requestBody: PasswordUpdateBody,
): CancelablePromise<ApiResponseOfPasswordUpdateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/auth/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
