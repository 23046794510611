import {FC} from 'react'
import {useSelector} from 'react-redux'
import DonutGraph, {
  DonutGraphProps,
} from '../../../modules/profile/components/DonutGraph/DonutGraph'
import {RootState} from '../../../../state/store'
import {useIntl} from 'react-intl'
import {groupBy} from 'lodash'

interface IProps {
  setFilter: (filter: {location?: string; risk?: number}) => void
}

const RiskChart: FC<IProps> = ({setFilter}) => {
  const participants = useSelector((state: RootState) => state.participant.list.data.results || [])
  const intl = useIntl()

  if (!participants?.length) {
    return null
  }

  //Currently dependant on entire assigned participant list being pulled down at the start.
  // If we move away from this, the risk counts will need to be requested from an api call.
  const participantsByRisk = groupBy(
    participants ?? [],
    ({risk}) => risk?.toString() ?? 'undefined'
  )
  const segments: DonutGraphProps['segments'] = []

  const configs = {
    '3': {title: 'HIGH RISK', color: '#D32029'},
    '2': {title: 'MEDIUM RISK', color: '#DB7100'},
    '1': {title: 'LOW RISK', color: '#FCF200'},
  }

  for (const risk in configs) {
    const config = configs[risk as keyof typeof configs]
    const count = participantsByRisk[risk]?.length ?? 0
    const value = (count / participants.length) * 100

    if (count) {
      // Do not show categories without participants
      segments.push({risk: +risk, title: config.title, color: config.color, value, count})
    }
  }

  return (
    <div className='h-full w-full rounded bg-neutral-900 shadow-lg'>
      <div className='px-7 pb-3 pt-7'>
        <h3 className='text-base'>{intl.formatMessage({id: 'DASHBOARD.CHART'})}</h3>
      </div>
      <div className='flex-md-flex riskChartContainer flex flex-col flex-wrap  flex-wrap items-center justify-start p-7'>
        <DonutGraph segments={segments} setFilter={(risk) => setFilter({risk})} />
      </div>
    </div>
  )
}
export default RiskChart
