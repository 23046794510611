import {sumBy} from 'lodash'
import React from 'react'
import './DonutGraph.scss'

type SvgCoordinates = {
  centerX: number
  centerY: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
}
export type SegmentProps = {
  value: number
  title: string
  color: string
  count: number
  svgCoordinates: SvgCoordinates
}

const Segment = ({
  svgCoordinates: {centerX, centerY, innerRadius, outerRadius, startAngle, endAngle},
  ...segment
}: SegmentProps) => {
  // Convert angles to radians
  const startAngleRad = (startAngle * Math.PI) / 180
  const endAngleRad = (endAngle * Math.PI) / 180

  // Calculate path coordinates
  const innerStartX = centerX + innerRadius * Math.cos(startAngleRad)
  const innerStartY = centerY + innerRadius * Math.sin(startAngleRad)
  const innerEndX = centerX + innerRadius * Math.cos(endAngleRad)
  const innerEndY = centerY + innerRadius * Math.sin(endAngleRad)

  const outerStartX = centerX + outerRadius * Math.cos(startAngleRad)
  const outerStartY = centerY + outerRadius * Math.sin(startAngleRad)
  const outerEndX = centerX + outerRadius * Math.cos(endAngleRad)
  const outerEndY = centerY + outerRadius * Math.sin(endAngleRad)

  // Create the path element
  return (
    <path
      d={`
          M${innerStartX},${innerStartY}
          A${innerRadius},${innerRadius} 0 ${endAngle - startAngle > 180 ? 1 : 0},1 ${
            Math.floor(innerEndX * 100) / 100
          },${innerEndY}
          L${Math.floor(outerEndX * 100) / 100},${outerEndY}
          A${outerRadius},${outerRadius} 0 ${
            endAngle - startAngle > 180 ? 1 : 0
          },0 ${outerStartX},${outerStartY}
          Z`}
      fill={segment.color}
    >
      <title className='capitalize'>{segment.title}</title>
    </path>
  )
}

export type RingChartProps = {
  segments: Array<Omit<SegmentProps, 'svgCoordinates'>>
}

export function RingChart({segments}: RingChartProps) {
  const centerX = 100
  const centerY = 100
  let startAngle = -90 // Start angle at the top

  const rings: Array<React.ReactNode> = []

  for (const segment of segments) {
    // Update the start angle for the next segment
    const endAngle = startAngle + (segment.value / 100) * 360

    rings.push(
      <Segment
        key={segment.title}
        {...segment}
        svgCoordinates={{centerX, centerY, startAngle, endAngle, innerRadius: 65, outerRadius: 80}}
      />
    )

    startAngle = endAngle
  }

  const total = sumBy(segments, ({count}) => count)

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 200 200'
      className='donut'
      aria-labelledby='risk-title risk-desc'
      role='img'
    >
      {rings}
      <text x='50%' y='50%' textAnchor='middle' dy='0.35em' fontSize='3rem' fill='white'>
        {total}
      </text>
    </svg>
  )
}

export type DonutGraphProps = {
  segments: Array<Omit<SegmentProps, 'svgCoordinates'> & {risk?: number}>
  setFilter?: (risk?: number) => void
}

export default function DonutGraph({segments, setFilter}: DonutGraphProps) {
  return (
    <figure className='flex flex-wrap  items-center'>
      <div className='figure-content w-1/2'>
        <RingChart segments={segments} />
      </div>
      <figcaption className='col-6'>
        <ul
          className='figure-key-list'
          aria-hidden='true'
          role='presentation'
          style={{textAlign: 'center'}}
        >
          {segments.map(({risk, title, color, count}) => (
            <li
              key={risk ?? title}
              className='cursor-pointer	text-sm'
              onClick={() => setFilter?.(risk)}
            >
              <span className='shape-square' style={{backgroundColor: color}}></span>
              <span>{title}</span>
              <span>{count}</span>
            </li>
          ))}
        </ul>
      </figcaption>
    </figure>
  )
}
