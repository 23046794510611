/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponseOfFacilityContactGroupCreateResponse } from '../models/ApiResponseOfFacilityContactGroupCreateResponse';
import type { ApiResponseOfFacilityContactGroupUpdateResponse } from '../models/ApiResponseOfFacilityContactGroupUpdateResponse';
import type { ApiResponseOfGetFacilityContactGroupsResponse } from '../models/ApiResponseOfGetFacilityContactGroupsResponse';
import type { ApiResponseOfUnknownSchema } from '../models/ApiResponseOfUnknownSchema';
import type { FacilityContactGroupCreateBody } from '../models/FacilityContactGroupCreateBody';
import type { FacilityContactGroupUpdateBody } from '../models/FacilityContactGroupUpdateBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FacilityContactGroupService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ApiResponseOfGetFacilityContactGroupsResponse 
     * @throws ApiError
     */
    public getFacilityContactGroups(): CancelablePromise<ApiResponseOfGetFacilityContactGroupsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/facility-contact-group/',
        });
    }

    /**
     * @param requestBody 
     * @returns ApiResponseOfFacilityContactGroupCreateResponse 
     * @throws ApiError
     */
    public createFacilityContactGroups(
requestBody: FacilityContactGroupCreateBody,
): CancelablePromise<ApiResponseOfFacilityContactGroupCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/facility-contact-group/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param facilityContactGroupId 
     * @param requestBody 
     * @returns ApiResponseOfFacilityContactGroupUpdateResponse 
     * @throws ApiError
     */
    public updateFacilityContactGroups(
facilityContactGroupId: string,
requestBody: FacilityContactGroupUpdateBody,
): CancelablePromise<ApiResponseOfFacilityContactGroupUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/facility-contact-group/{facilityContactGroupId}',
            path: {
                'facilityContactGroupId': facilityContactGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param facilityContactGroupId 
     * @returns ApiResponseOfUnknownSchema 
     * @throws ApiError
     */
    public deleteFacilityContactGroups(
facilityContactGroupId: string,
): CancelablePromise<ApiResponseOfUnknownSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v2/facility-contact-group/{facilityContactGroupId}',
            path: {
                'facilityContactGroupId': facilityContactGroupId,
            },
        });
    }

}
