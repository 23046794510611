import {
  ActionReducerMapBuilder,
  AsyncThunk,
  Draft,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import {ApiRequestState, ApiStatus} from './api.types'
import {DataTableState} from '../../app/components/datatable/DataTable.types'
import {ColumnFilter} from '@tanstack/table-core'
import {PaginationRequestBodyType} from './types'
import {Api} from './openapi'

export const api = new Api({
  BASE: process.env.REACT_APP_API_URL,
  HEADERS: () =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem('tappToken')
      if (!token) {
        reject('Missing Auth Token.')
        return
      }
      const parsedToken: {api_token: string} = JSON.parse(token)
      resolve({
        Authorization: `Bearer ${parsedToken.api_token}`,
      })
    }),
})

export const publicApi = new Api({
  BASE: process.env.REACT_APP_API_URL,
})

export const updateAPIState = <T>(
  subState: ApiRequestState<T>,
  action: PayloadAction<any, string, {requestStatus: ApiStatus; status?: number; data?: T}>,
  onFulfilled: () => void
) => {
  if (isApiError(action)) {
    subState.error = action.error
    return
  }

  //otherwise set the status and pull data if fulfilled
  subState.status = action.meta.requestStatus
  switch (action.meta.requestStatus) {
    case 'fulfilled':
      subState.error = null
      onFulfilled()
      return
    case 'pending':
      subState.error = null
      return
    case 'rejected':
    default:
  }
}

/**
 * Sets the API state given based on the action received.
 */
export const setApiState = <T>(
  subState: ApiRequestState<T>,
  action: PayloadAction<any, string, {requestStatus: ApiStatus; status?: number; data?: T}>
) => {
  updateAPIState(subState, action, () => {
    subState.data = action.payload.data
  })
}

/**
 * Sets the API state given based on the action received.
 */
export const updateApiStateItem = <T extends Array<any>>(
  subState: ApiRequestState<T>,
  action: PayloadAction<any, string, {requestStatus: ApiStatus; status?: number; data?: T}>
) => {
  updateAPIState(subState, action, () => {
    if (!subState.data) {
      subState.data = [action.payload.data] as T
      return
    }
    subState.data = subState.data.map((item) => {
      if (item.id === action.payload.data.id) return action.payload.data
      return item
    }) as T
  })
}

/**
 * Sets the API state given based on the action received.
 */
export const createApiStateItem = <T extends Array<any>>(
  subState: ApiRequestState<T>,
  action: PayloadAction<any, string, {requestStatus: ApiStatus; status?: number; data?: T}>
) => {
  updateAPIState(subState, action, () => {
    if (!subState.data) subState.data = [action.payload.data] as T
    subState.data?.push(action.payload.data)
  })
}

/**
 * Sets the API state given based on the action received.
 */
export const deleteApiStateItem = <T extends Array<any>>(
  subState: ApiRequestState<T>,
  action: PayloadAction<
    any,
    string,
    {requestStatus: ApiStatus; status?: number; data?: T; arg: number}
  >
) => {
  updateAPIState(subState, action, () => {
    subState.data = (subState.data?.filter((item) => item.id !== action.meta.arg) as T) || null
  })
}

/**
 * Checks if the api action response is an error
 */
export const isApiError = (action: any): action is {error: SerializedError} => {
  return !!action.error
}

/**
 * adds the pending, fulfilled and rejected cases for the api data in a standard way. received data is put into the state object under the targetState location.
 */
export const addAPICases = <State, RequestBody, Response>(
  builder: ActionReducerMapBuilder<State>,
  asyncThunk: AsyncThunk<Response, RequestBody, any>,
  targetState: keyof State,
  triggers?: {
    onPending?: (
      state: Draft<State>,
      action: PayloadAction<
        undefined,
        string,
        {arg: RequestBody; requestId: string; requestStatus: 'pending'}
      >
    ) => void
    onFulfilled?: (
      state: Draft<State>,
      action: PayloadAction<
        Response,
        string,
        {arg: RequestBody; requestId: string; requestStatus: 'fulfilled'}
      >
    ) => void
    onRejected?: (
      state: Draft<State>,
      action: PayloadAction<
        unknown,
        string,
        {arg: RequestBody; requestId: string; requestStatus: 'rejected'}
      >
    ) => void
  }
) => {
  builder
    .addCase(asyncThunk.pending, (state, action) => {
      setApiState((state as any)[targetState], action)
      if (triggers?.onPending) triggers.onPending(state, action)
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      setApiState((state as any)[targetState], action)
      if (triggers?.onFulfilled) triggers.onFulfilled(state, action)
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      setApiState((state as any)[targetState], action)
      if (triggers?.onRejected) triggers.onRejected(state, action)
    })
}

/**
 * adds the pending, fulfilled and rejected cases for the api data in a standard way. received data is put into the state object under the targetState location.
 */
export const addUpdateCases = <
  State,
  RequestCreateBody,
  RequestUpdateBody,
  CreateResponse,
  UpdateResponse,
  DeleteResponse,
>(
  builder: ActionReducerMapBuilder<State>,
  asyncThunkCreate: AsyncThunk<CreateResponse, RequestCreateBody, any>,
  asyncThunkUpdate: AsyncThunk<UpdateResponse, RequestUpdateBody, any>,
  asyncThunkDelete: AsyncThunk<DeleteResponse, number, any>,
  targetState: keyof State
) => {
  builder
    .addCase(asyncThunkUpdate.pending, (state, action) => {
      updateApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkUpdate.fulfilled, (state, action) => {
      updateApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkUpdate.rejected, (state, action) => {
      updateApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkCreate.pending, (state, action) => {
      createApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkCreate.fulfilled, (state, action) => {
      createApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkCreate.rejected, (state, action) => {
      createApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkDelete.pending, (state, action) => {
      deleteApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkDelete.fulfilled, (state, action) => {
      deleteApiStateItem((state as any)[targetState], action)
    })
    .addCase(asyncThunkDelete.rejected, (state, action) => {
      deleteApiStateItem((state as any)[targetState], action)
    })
}

/**
 * converts dataTableState into Pagination request body for api pagination.
 */
export const dataTableStateToPaginationRequest = <T>(
  dataTableState: DataTableState,
  filterTypes?: {[key: string]: 'number' | 'string' | 'boolean' | 'date'}
) => {
  const {pagination, sorting, columnFilters} = dataTableState
  const sortingOptions = sorting[0]
  const data: PaginationRequestBodyType = {}

  //convert sorting data to api request sortBy and sortDir
  if (sortingOptions) {
    data.sortBy = sortingOptions.id
    data.sortDir = sortingOptions.desc ? 'desc' : 'asc'
  }

  // convert columnFilters data to api request filter object array
  if (columnFilters.length > 0) {
    data.filters = columnFilters.map((filter: ColumnFilter) => {
      const filterType = filterTypes?.[filter.id] ? filterTypes[filter.id] : 'string'
      const valueString = filter.value as string
      let value: string | number | boolean | Date
      let operation: 'eq' | 'contains' = 'eq'

      //use fitlerType to set the correct comparison and convert the value to the correct type
      switch (filterType) {
        case 'number':
          value = parseInt(valueString)
          break
        case 'boolean':
          value = filter.value as boolean
          break
        case 'date':
          value = new Date(valueString)
          break
        case 'string':
        default:
          operation = 'contains'
          value = valueString
      }

      return {
        field: filter.id,
        value,
        operation,
      }
    })
  }

  // convert the pagination data to api request pagination values
  if (pagination) {
    data.page = pagination.pageIndex + 1
    data.limit = pagination.pageSize
  }

  return data as T
}
