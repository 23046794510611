import {z} from 'zod'

/** Schema check for env variables. Can use zod to further validate or just check that the variable exists. */
const envSchema = {
  REACT_APP_API_URL: z.string().trim().min(1),
  REACT_APP_SOCKET_URL: z.string().trim().min(1),

  REACT_APP_GROWTHBOOK_ACTIVE: z.string().trim().min(1),
  REACT_APP_GROWTHBOOK_API_HOST: z.string().trim().min(1),
  REACT_APP_GROWTHBOOK_USE_STAGING: z.string().trim().min(1),
  REACT_APP_GROWTHBOOK_CLIENT_KEY: z.string().trim().min(1),
}

const envVariables: {[key: string]: string | number | undefined} = {}
Object.keys(envSchema).forEach((key) => {
  envVariables[key] = process.env[key]
})

if (!!process.env.SKIP_ENV_VALIDATION === false) {
  const parsed = /** @type {MergedSafeParseReturn} */ z.object(envSchema).safeParse(envVariables) // on client we can only validate the ones that are exposed

  if (parsed.success === false) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors)
    throw new Error('Invalid environment variables')
  }
}
