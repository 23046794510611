import React, {FC, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../state/store'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  count?: number
  showCount?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  count,
  showCount = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const initialCount = useSelector((state: RootState) => state.alerts.unread.data?.count)

  const [finalCount, setFinalCount] = React.useState(initialCount)

  useEffect(() => {
    setFinalCount(count || 0)
  }, [count])

  useEffect(() => {
    setFinalCount(initialCount)
  }, [initialCount])

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', {'active border-r-2 border-[#F76808]': isActive})}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title text-light-white'>{title}</span>
        {showCount && finalCount && finalCount > 0 && (
          <span
            style={{
              background: '#fa3e3e',
              borderRadius: '50%',
              color: 'white',
              minWidth: '23px',
              textAlign: 'center',
              fontSize: 12,
              padding: 3,
            }}
          >
            <span>{finalCount}</span>
          </span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
