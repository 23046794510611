/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import {_login} from '../../../../state/V2/authentication/authentication.actions'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../../../state/store'
import {_getFacilities, _getFacilityPods} from '../../../../state/V2/facility/facility.actions'
import {_getUserPods} from '../../../../state/V2/users/users.actions'
import {isApiError} from '../../../../state/V2/api.helpers'
import {z} from 'zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {toast} from 'react-toastify'
import {UserClaims} from '../core/_models'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import {useState} from 'react'

export function Login() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch<AppDispatch>()

  const loginSchema = z.object({
    email: z
      .string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .refine((value) => value.toLowerCase() === value, {
        message: 'Email should be in lowercase',
      }),
    password: z.string().min(8, 'Minimum 8 characters').max(50, 'Maximum 50 characters'),
  })
  type FormSchemaType = z.infer<typeof loginSchema>

  const {
    register,
    handleSubmit,
    formState: {errors, isValid, isSubmitting},
  } = useForm<FormSchemaType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(loginSchema),
    mode: 'all',
  })

  const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
    try {
      const login = await dispatch(_login(data))

      if (isApiError(login)) {
        saveAuth(undefined)
        throw new Error(login.error.message)
      }
      const claims = login.payload.data
      localStorage.setItem('currentUser', JSON.stringify(claims))
      saveAuth({api_token: claims.token})
      setCurrentUser(claims as UserClaims)
      dispatch(_getFacilities())
      dispatch(_getUserPods())
      dispatch(_getFacilityPods({facilityId: claims.facility?.id ?? 1}))
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
      toast.error('Incorrect email or password')
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <div className='mb-10 text-center'>
        <h1 className='mb-3 text-xl text-white'>Log in to your account</h1>
      </div>

      <Grid container direction='column' spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder='Email'
            autoComplete='off'
            variant='outlined'
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            placeholder='Password'
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password && errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleTogglePassword} edge='end'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Stack spacing={4} sx={{mt: 4}}>
        <Button
          type='submit'
          variant='contained'
          fullWidth
          size='large'
          // disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? (
            <Typography textTransform={'none'} align='center'>
              {' '}
              Please Wait... <CircularProgress size={15} color='inherit' />
            </Typography>
          ) : (
            'Login'
          )}
        </Button>
        <Link to='/auth/forgot-password' className='text-orange text-center '>
          Forgot Password ?
        </Link>
      </Stack>
    </form>
  )
}
