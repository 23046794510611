import {ReactZoomPanPinchRef, TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch'
import NavBar from './navbar'
import {InmateMarkerLayer} from './InmateMarkerLayer'
import InmateListLayer from './InmateListLayer'
import {createPortal} from 'react-dom'
import Sidebar from './sidebar'
import LiveTrackFooter from './footer/LiveTrackFooter'
import {PrecisionMode, PrecisionModeType} from '../../../../state/V2/system/system.types'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../state/store'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useAppDispatch} from '../../../../state/hooks'
import {
  _getParticipantById,
  _getParticipants,
} from '../../../../state/V2/participant/participant.actions'
import {_getLocations, _getPods} from '../../../../state/V2/facility/facility.actions'
import {useMap2DState} from './Map2DContext'
import {zoneSwitchSelect} from '../../../../helpers/live-track'
import {setToCamelCasing} from '../../../../helpers/strings'
import {useFeatureFlags} from '../../../../helpers/growthbook.utils'
import {ExternalSvg} from '../../../../helpers/svg'
import {_getBuildings, _getFloors} from '../../../../state/V2/facility-ops/facilityOps.actions'
import {api} from '../../../../state/V2/api.helpers'

const Map2DModule = () => {
  const dispatch = useAppDispatch()
  const [
    {selectedInmateId, zone, containerWidth, containerHeight, transformComponentRef},
    updateMap2DState,
  ] = useMap2DState()
  const pods = useSelector((state: RootState) => state.pods.list?.data?.results || [])
  const locations = useSelector((state: RootState) => state.locations.list?.data || [])

  //trigger initial data retrieval
  useEffect(() => {
    dispatch(_getParticipants({}))
    dispatch(_getLocations())
    dispatch(_getPods({}))
  }, [dispatch])

  //get data on selected participant
  useEffect(() => {
    if (!selectedInmateId || isNaN(selectedInmateId)) return
    dispatch(_getParticipantById(selectedInmateId))
  }, [dispatch, selectedInmateId])

  // Update showPods when pod list changes
  useEffect(() => {
    if (!pods) return
    const showPodIds = pods.map((pod) => pod.id)
    updateMap2DState((prev) => {
      if (JSON.stringify(showPodIds) === JSON.stringify(prev.showPodIds)) return {}
      return {showPodIds}
    })
  }, [pods, updateMap2DState])

  //trigger zoom in on selected location
  useEffect(() => {
    const locationNames = locations
      ? ['Reset', ...locations.map((locationObj: any) => locationObj.location)]
      : ['Reset']
    const locationsCamelCase = locationNames.map((location: any) => setToCamelCasing(location))
    zoneSwitchSelect(
      zone,
      transformComponentRef,
      {width: containerWidth, height: containerHeight},
      locationNames,
      locationsCamelCase
    )
  }, [containerHeight, containerWidth, locations, transformComponentRef, zone])

  const mainMenuRight = document.querySelector('#main-menu-right')
  const footer = document.querySelector('#footer-main')

  return (
    <div className='flex h-full flex-col'>
      <NavBar />
      <div className='relative h-full w-full'>
        <Map2D />
      </div>

      {mainMenuRight && createPortal(<Sidebar />, mainMenuRight)}
      {footer && createPortal(<LiveTrackFooter />, footer)}
    </div>
  )
}

export const Map2D = () => {
  const [
    {
      scaleUp,
      svgWidth,
      svgHeight,
      zoomFactor,
      containerWidth,
      containerHeight,
      svgScale,
      darkMode,
      precisionMode,
      buildingId,
      floorId,
    },
    updateMap2DState,
  ] = useMap2DState()
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const {facilitySetting} = useFeatureFlags()
  const facilityId = useSelector((state: RootState) => state.facility.current.data?.id)
  const facilityIdRef = useRef(facilityId)
  const buildings = useSelector((state: RootState) => state.facilityOps.buildings.data?.results)
  const floors = useSelector((state: RootState) => state.facilityOps.floors.data?.results)
  const dispatch = useAppDispatch()
  const floorsBuildingIdRef = useRef<number>()

  //Get the buildings for the active facility on component load
  useEffect(() => {
    if (!buildings || facilityIdRef.current !== facilityId) {
      facilityIdRef.current = facilityId
      dispatch(_getBuildings({}))
      return
    }

    //set first building as the buildingId for the map
    const firstBuilding = buildings[0]
    if (firstBuilding) updateMap2DState({buildingId: firstBuilding.id})
  }, [buildings, dispatch, updateMap2DState, facilityId])

  //Get the floors for the active buildingId
  useEffect(() => {
    if (!buildingId) return
    if (!floors || floorsBuildingIdRef.current !== buildingId) {
      floorsBuildingIdRef.current = buildingId
      dispatch(_getFloors({buildingId}))
      return
    }

    // set the first floor as the floorId from the floors list
    const firstFloor = floors[0]
    if (firstFloor) updateMap2DState({floorId: firstFloor.id, floorLabel: firstFloor.fullLabel})
    else updateMap2DState({floorId: undefined, floorLabel: undefined})
  }, [buildingId, dispatch, floors, updateMap2DState])

  //set transformComponent
  useEffect(() => {
    updateMap2DState({transformComponentRef})
  }, [updateMap2DState])

  //set precision mode
  useEffect(() => {
    updateMap2DState({precisionMode: facilitySetting('precisionMode') as PrecisionModeType})
  }, [facilitySetting, updateMap2DState])

  // handle resizing of the container element
  useEffect(() => {
    const getSvgScale = () => {
      if (containerWidth === 0 || containerHeight === 0 || svgWidth === 0 || svgHeight === 0)
        return 0

      const scale = Math.min(containerWidth / svgWidth, containerHeight / svgHeight)
      return scaleUp ? scale : Math.max(scale, 1)
    }

    const handleResize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        updateMap2DState({
          containerWidth: rect.width,
          containerHeight: rect.height,
          svgScale: getSvgScale(),
        })
      } else {
        updateMap2DState({
          containerWidth: 0,
          containerHeight: 0,
          svgScale: 0,
        })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [containerHeight, containerWidth, scaleUp, svgHeight, svgWidth, updateMap2DState])

  useEffect(() => {
    const transformComp = transformComponentRef.current
    if (!transformComp) return
    transformComp.centerView(svgScale)
  }, [svgScale])

  return (
    <div className='absolute bottom-0 left-0 right-0 top-0 overflow-hidden' ref={containerRef}>
      <TransformWrapper
        key={`${buildingId}-${floorId}`}
        initialScale={svgScale}
        minScale={svgScale}
        maxScale={svgScale * zoomFactor}
        centerOnInit
        ref={transformComponentRef}
        wheel={{step: 0.8}}
      >
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            height: '100%',
            backgroundColor: darkMode ? '#141414' : 'white',
          }}
        >
          <div style={{width: svgWidth, height: svgHeight}}>
            <SvgFloorMap />
            {/* floor Id check temporary to hide inmates on second floor until floor filtering is implemented */}
            {floorId !== 10 && precisionMode === PrecisionMode.TRIANGULATION && (
              <InmateMarkerLayer />
            )}
          </div>
        </TransformComponent>
      </TransformWrapper>
      {/* floor Id check temporary to hide inmates on second floor until floor filtering is implemented */}
      {floorId !== 10 && precisionMode === PrecisionMode.PROXIMITY && <InmateListLayer />}
    </div>
  )
}

export const SvgFloorMap = () => {
  const [
    {darkMode, precisionMode, containerHeight, containerWidth, scaleUp, floorLabel},
    updateMap2DState,
  ] = useMap2DState()
  const facilitySlug = useSelector((state: RootState) => state.facility.current.data?.slug)
  const [url, setUrl] = useState<string>()
  const svgRef = useRef<HTMLDivElement>(null)

  // Center the map view on the svg once it is loaded.
  const onSvgLoaded = useCallback(() => {
    const svg = svgRef.current?.querySelector('svg')
    if (!svg) return

    const viewScale = svg.getAttribute('viewScale')
    if (viewScale) {
      updateMap2DState({conversionScale: Number(viewScale)})
    }

    const viewBox = svg.getAttribute('viewBox')
    if (!viewBox) return

    const [, , svgWidth, svgHeight] = viewBox.split(' ').map(Number)
    if (svgWidth === 0 || svgHeight === 0) return

    const getSvgScale = () => {
      if (containerWidth === 0 || containerHeight === 0) return 0
      const scale = Math.min(containerWidth / svgWidth, containerHeight / svgHeight)
      return scaleUp ? scale : Math.max(scale, 1)
    }

    updateMap2DState({
      svgWidth,
      svgHeight,
      svgScale: getSvgScale(),
      svgViewport: viewBox,
    })
  }, [containerHeight, containerWidth, scaleUp, updateMap2DState])

  // get the url to the svg file from the api
  useEffect(() => {
    if (!facilitySlug || !floorLabel) return

    const mapFileName = `${facilitySlug}_${floorLabel}.svg`

    setUrl(undefined)
    const getUrl = async () => {
      const response = await api.fileManager.getFile('maps', mapFileName)
      if (response?.data.url) setUrl(response.data.url)
    }
    getUrl()
  }, [facilitySlug, floorLabel])

  if (!url) return null

  return (
    <ExternalSvg
      url={url}
      ref={svgRef}
      hideElementIds={precisionMode === PrecisionMode.PROXIMITY ? ['labels'] : []}
      style={{filter: darkMode ? 'invert(1)' : ''}}
      onLoaded={onSvgLoaded}
    />
  )
}

export default Map2DModule
