import React, {useState, MouseEvent} from 'react'
import {Button, Menu, MenuItem, FormGroup, FormControlLabel, Switch} from '@mui/material'
import {useIntl} from 'react-intl'

type SwitchConfig = {
  checked: boolean
  onChange: any
  intlString: string
}

type SwitchProps = {
  switchConfigs: SwitchConfig[]
  style?: React.CSSProperties
}

const SwitchDropdown: React.FC<SwitchProps> = ({switchConfigs, style}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [svgBackground, setSvgBackground] = useState<boolean>(false)
  const intl = useIntl()

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={style}>
      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        Settings
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {switchConfigs.map((config, index) => (
          <MenuItem onClick={handleClose} key={index}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={config.checked}
                    onChange={config.onChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                }
                label={intl.formatMessage({
                  id: config.intlString,
                })}
              />
            </FormGroup>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default SwitchDropdown
