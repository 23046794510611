import {createAsyncThunk} from '@reduxjs/toolkit'
import {api} from '../api.helpers'
import {
  GetPaginatedParticipantHeartratesBody,
  GetPaginatedParticipantsBody,
  LocationTimeCreateBody,
  ParticipantCreateBody,
  ParticipantUpdateBody,
} from '../openapi'

//get participant by ID

export const _getParticipantById = createAsyncThunk('participant/_id', async (id: number) => {
  return api.participant.getParticipantById(id.toString())
})

export const _getParticipants = createAsyncThunk(
  'participant/_list',
  async (params: GetPaginatedParticipantsBody) => {
    return api.participant.getPaginatedParticipantsFiltered({page: 1, limit: 1000, ...params})
  }
)

export const _createParticipant = createAsyncThunk(
  'participant/_create',
  async (payload: ParticipantCreateBody) => {
    return api.participant.createParticipant(payload)
  }
)

export const _editParticipant = createAsyncThunk(
  'participant/_edit',
  async ({id, data}: {id: number; data: ParticipantUpdateBody}) => {
    return api.participant.updateParticipant(id.toString(), data)
  }
)

export const _deleteParticipant = createAsyncThunk('participant/_delete', async (id: number) => {
  try {
    return await api.participant.deleteParticipant(id.toString())
  } catch (error: any) {
    error.message = error.body?.error || error.message
    throw error
  }
})

export const _transferParticipant = createAsyncThunk('participant/_id/transfer/', async (id: number) => {
  try {
    return await api.participant.transferParticipant(id.toString())
  } catch (error: any) {
    error.message = error.body?.error || error.message
    throw error
  }})

/**** Cell Time Api's *****/

export const _createParticipantLocation = createAsyncThunk(
  'participant/_saveCellTracker',
  async (data: LocationTimeCreateBody) => {
    return api.participantLocation.createLocationTime(data)
  }
)

export const _getActualCellDurations = createAsyncThunk(
  'participant/_getCellDurations',
  async ({
    participantId,
    startTime,
    endTime,
  }: {
    participantId: number
    startTime: string | null
    endTime: string | null
  }) => {
    // let id = params.participantId
    return api.participantLocation.getParticipantLocationDuration(
      participantId.toString(),
      startTime,
      endTime
    )
  }
)

export const _getAllocatedCellOutDuration = createAsyncThunk(
  'participant/_getAllocatedCellOutDurations',
  async ({
    cellId,
    startTime,
    endTime,
  }: {
    cellId: number
    startTime: string | null
    endTime: string | null
  }) => {
    return api.cellHistory.getCellHistoryDuration(cellId.toString(), startTime, endTime)
  }
)

//TODO: check if this call is still needed
export const _getCellHistoryDuration = createAsyncThunk(
  'participant/_getCellHistoryDuration',
  async ({
    cellId,
    startTime,
    endTime,
    participantId,
  }: {
    cellId: number
    startTime?: Date
    endTime?: Date
    participantId?: number
  }) => {
    return api.cellHistory.getCellHistoryDuration(
      cellId.toString(),
      startTime?.toISOString(),
      endTime?.toISOString(),
      participantId
    )
  }
)

/************ Heartrate  ****************/

export const _getHeartRate = createAsyncThunk(
  'participant/_heartrate',
  async ({id, data}: {id: number; data: GetPaginatedParticipantHeartratesBody}) => {
    return api.participantAlert.getPaginatedParticipantHeartrates(id.toString(), data)
  }
)

/************ Location  ****************/

export const _getCellDoorHistory = createAsyncThunk(
  'participant/_getCellDoorHistory',
  async ({
    participantId,
    startTime,
    endTime,
  }: {
    participantId: number
    startTime: string | null
    endTime: string | null
  }) => {
    return api.participantLocation.getParticipantLocationsDoorHistory(
      participantId.toString(),
      startTime,
      endTime
    )
  }
)

export const _saveLocationTime = createAsyncThunk(
  'participant/_saveLocationTime',
  async (data: LocationTimeCreateBody) => {
    return api.participantLocation.createLocationTime(data)
  }
)
